import React from 'react'
import { Form, Select } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'

const { Option } = Select

function PreferredRateItem() {
  return (
    <Form.Item
      name="preferredRate"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      label="Желаемая ставка"
    >
      <Select size="middle">
        {appConfig.preferences.rates.map(rate => (
          <Option key={rate} value={rate}>
            {rate}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default PreferredRateItem
