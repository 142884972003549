import { useContext, useMemo, useCallback } from 'react'

import { ConfigContext } from '../contexts/configContext'
import { appConfig, langCases } from '../constants/appConfig'
import { getDepartmentSingularList } from '../helpers/department'
import { arrayUniqValues } from '../helpers'

export const useConfigContextData = () => {
  const { settings: { data: settingsData } = {}, workflows: { data: workflowsData } = {} } =
    useContext(ConfigContext)

  const departmentSingularList = useMemo(
    () =>
      getDepartmentSingularList(
        JSON.parse(settingsData[appConfig.settings.departmentTypes] || '[]')
      ),
    [settingsData]
  )
  const departmentLangData = useMemo(
    () =>
      JSON.parse(settingsData[appConfig.settings.departmentTypes] || '[]')?.find(
        item =>
          item.singular[langCases.nominative] === settingsData[appConfig.settings.departmentType]
      ),
    [settingsData]
  )
  const getDepartmentPluralName = useCallback(
    (langCase = langCases.nominative) => departmentLangData?.plural[langCase],
    [departmentLangData]
  )
  const getDepartmentSingularName = useCallback(
    (langCase = langCases.nominative) => departmentLangData?.singular[langCase],
    [departmentLangData]
  )

  // Получить Workflow по ее имени
  const getWorkflowByName = useCallback(
    name => workflowsData?.find(w => w.name === name),
    [workflowsData]
  )
  // Получить стэйты Workflow по имени воркфлы
  const getWorkflowStatesWithLabelsByName = useCallback(
    name => {
      const objList = name
        ? getWorkflowByName(name)?.states
        : {
            ...getWorkflowByName(appConfig.workflows.amp.name)?.states,
            ...getWorkflowByName(appConfig.workflows.candidate.name)?.states
          }
      return Object.entries(objList || {}).map(([key, value]) => ({
        name: key, // если поле "name" есть в ответе сервера оно будет перезаписано
        ...value,
        key,
        label: value.displayName
      }))
    },
    [getWorkflowByName]
  )
  // Получить ключи стэйтов Workflow по имени воркфлы
  const getWorkflowStatesValuesByName = useCallback(
    name =>
      Object.keys(getWorkflowByName(name)?.states || {}).map(key => ({
        [key]: key
      })),
    [getWorkflowByName]
  )
  // Получить конкретный стэйт по ключу из Workflow с именем "candidate" или "amp"
  const getCandidateWorkflowStateWithLabelByName = useCallback(
    key =>
      getWorkflowStatesWithLabelsByName(appConfig.workflows.candidate.name).find(
        s => s.key === key
      ) || getWorkflowStatesWithLabelsByName(appConfig.workflows.amp.name).find(s => s.key === key),
    [getWorkflowStatesWithLabelsByName]
  )
  // Получить имя стэйта по ключу из всех Workflow
  const getWorkflowStateLabelsByName = useCallback(
    key =>
      arrayUniqValues(
        [
          getWorkflowStatesWithLabelsByName(appConfig.workflows.candidate.name).find(
            s => s.key === key
          )?.label,
          getWorkflowStatesWithLabelsByName(appConfig.workflows.amp.name).find(s => s.key === key)
            ?.label
        ].filter(Boolean)
      ).join(' / '),
    [getWorkflowStatesWithLabelsByName]
  )
  /**
   * Получить конкретный стэйт по "stateKey" из конкретной Workflow по "workFlowName"
   * @param {Object} object
   * @param {String} object.workFlowName - имя воркфлы
   * @param {String} object.stateKey - ключ стеэта
   */
  const getStateWithLabelByWorkflowAndName = useCallback(
    ({ workFlowName, stateKey }) =>
      getWorkflowStatesWithLabelsByName(workFlowName).find(s => s.key === stateKey) ||
      getWorkflowStatesWithLabelsByName(appConfig.workflows.candidate.name).find(
        s => s.key === stateKey
      ),
    [getWorkflowStatesWithLabelsByName]
  )

  return {
    departmentSingularList,
    getDepartmentPluralName,
    getDepartmentSingularName,
    getWorkflowByName,
    getWorkflowStatesWithLabelsByName,
    getWorkflowStatesValuesByName,
    getCandidateWorkflowStateWithLabelByName,
    getStateWithLabelByWorkflowAndName,
    getWorkflowStateLabelsByName,
    lowerCaseDepartmentType: settingsData[appConfig.settings.departmentType]?.toLowerCase(),
    departmentType: settingsData[appConfig.settings.departmentType]
  }
}
