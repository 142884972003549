import { useContext, useEffect, useState } from 'react'

import { default as massRecruitmentRoutes } from '../modules/massRecruitment/routes'
import { areArraysIntersect } from '../helpers'
import { ConfigContext } from '../contexts/configContext'
import { useConfigContextData } from './useConfigContextData'

export const useRoutes = (user, module) => {
  const [routes, setRoutes] = useState(null)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const { getDepartmentPluralName } = useConfigContextData()

  useEffect(() => {
    const routes = [...massRecruitmentRoutes({ departmentName: getDepartmentPluralName() })]
    if (!Object.keys(user).length || !module) return setRoutes(null)
    const isValidRoute = roles => areArraysIntersect(roles, user.roles)
    const data = routes
      .filter(route => route.withoutRoles || isValidRoute(route.roles))
      .filter(route => !route.featureToggle || features?.includes(route.featureToggle))
      .filter(route => !route.withoutAgency || !user.agency)
    setRoutes(data)
  }, [getDepartmentPluralName, user, module, features])

  return {
    routes
  }
}
