import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { Button, Input, Row, Col, Typography, message } from 'antd'
import { PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { simpleReducer } from '../../../../../helpers'
import { useMutateAddOrgStructure } from '../../../../../api/orgStructure'
const { Text } = Typography
const initialState = {
  isEdit: false,
  unit: ''
}

function AddUnitItem({ layerIndex, selectedUnits }) {
  const orgStructure = useContext(OrgStructureContext)
  const inputRef = useRef(null)

  const [state, setState] = useReducer(simpleReducer, initialState)
  const handleClick = () => {
    if (!state.isEdit) {
      inputRef?.current?.focus()
      setState({ isEdit: true })
    }
  }

  useEffect(() => {
    if (state.isEdit) {
      inputRef?.current?.focus()
    }
  }, [state.isEdit])
  const handleCancel = () => setState(initialState)
  const handleChangeUnit = ({ target }) => setState({ unit: target.value })

  const {
    isSuccess: isSuccessAddOrgStructure,
    isError: isErrorAddOrgStructure,
    mutate: mutateAddOrgStructure,
    isLoading: isLoadingAddOrgStructure
  } = useMutateAddOrgStructure()

  useEffect(() => {
    if (isSuccessAddOrgStructure) {
      message.success('Орг единица успешно добавлена')
      setState(initialState)
    } else if (isErrorAddOrgStructure) {
      message.error('Ошибка добавления орг единицы')
      setState(initialState)
    }
  }, [isSuccessAddOrgStructure, isErrorAddOrgStructure])

  const setUnit = (data, index = 0) => {
    if (index === layerIndex) {
      const unit = {
        type: orgStructure.layers[layerIndex],
        name: state.unit
      }
      data.children = data.children || []
      data.children.push(unit)
      return null
    }

    const unit = data?.children?.find(item => item._id === selectedUnits[index]._id)
    setUnit(unit, index + 1)
  }
  const handleSave = () => {
    if (state.unit) {
      const units = { children: [...orgStructure.units] }
      setUnit(units)
      const data = {
        layers: orgStructure.layers,
        units: units.children
      }
      mutateAddOrgStructure(data)
    } else setState(initialState)
  }

  return (
    <div className="unit" onClick={handleClick}>
      {state.isEdit ? (
        <Row gutter={[8, 8]}>
          <Col className="add-unit-input">
            <Input
              ref={inputRef}
              placeholder="Введите название"
              value={state.unit}
              onChange={handleChangeUnit}
              disabled={isLoadingAddOrgStructure}
              onPressEnter={handleSave}
            />
          </Col>
          <Col className="add-unit-buttons">
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={handleSave}
              loading={isLoadingAddOrgStructure}
            />
            <Button
              icon={<CloseOutlined />}
              onClick={handleCancel}
              disabled={isLoadingAddOrgStructure}
            />
          </Col>
        </Row>
      ) : (
        <>
          <PlusOutlined />
          <Text className="add-unit-default-text">Добавить</Text>
        </>
      )}
    </div>
  )
}

export default AddUnitItem
