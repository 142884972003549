import { filterPeriods } from '../../../constants/filterPeriods'

export const parseFilterLocalStorage = name => {
  let parsedLocalStorage = { ...JSON.parse(localStorage.getItem(name) || '{}') }
  /**
   * Только даты кастомного периода есть смысл брать из localStorage
   * Для всех остальных нужно брать свежие даты
   */
  if (parsedLocalStorage.period === filterPeriods.custom.id) {
    if (parsedLocalStorage.start) {
      parsedLocalStorage.start = new Date(parsedLocalStorage.start)
    }
    if (parsedLocalStorage.end) {
      parsedLocalStorage.end = new Date(parsedLocalStorage.end)
    }
  } else if (parsedLocalStorage.period) {
    parsedLocalStorage.start = new Date(filterPeriods[parsedLocalStorage.period].value.start)
    parsedLocalStorage.end = new Date(filterPeriods[parsedLocalStorage.period].value.end)
  }
  // может быть сохранен в localStorage, а мы считаем количество фильтров на бэйдже
  if (parsedLocalStorage.customPeriod) {
    delete parsedLocalStorage.customPeriod
  }
  return parsedLocalStorage
}

export const setFilterLocalStorage = (name, data) => {
  const localData = { ...data }
  /**
   * В localStorage есть смысл сохранять только даты кастомного периода для фильтрации запроса
   * Если сохранить даты периода "последняя неделя", то на следующий день start, end в localStorage уже будут неверные
   * Тоже самое для других периодов (последний месяц, квартал), кроме кастомного
   */
  if (data.period !== filterPeriods.custom.id) {
    delete localData.start
    delete localData.end
  }
  localStorage.setItem(name, JSON.stringify(localData))
}
