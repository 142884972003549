import { createContext } from 'react'
import { appConfig } from '../constants/appConfig'

export const initReferences = {
  citizenships: [],
  utms: [], // utm метки полученные по определенному значению поля requestType
  requestTypes: [],
  sources: [],
  mediums: [],
  inboxStates: Object.values(appConfig.statuses.values),
  cities: []
}
export const ConfigContext = createContext({
  references: {
    data: {},
    setData: null
  },
  settings: {
    data: {},
    setData: null
  },
  features: {
    data: null,
    setData: null,
    isLoaded: false
  }
})
