import React, { useReducer, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, AutoComplete, message, Spin } from 'antd'

import { useDadataMetro } from '../../../../../api/dadata'
import { debounce, simpleReducer } from '../../../../../helpers'

const DadataMetroItem = ({ onChange, metro = '' }) => {
  const [state, setState] = useReducer(simpleReducer, {
    metro,
    suggestions: []
  })
  const handleChange = value => {
    const data = state.suggestions?.find(s => s.value === value)?.data
    onChange(data)
  }

  const {
    status: statusDadataMetro,
    data: dadataMetro,
    isLoading
  } = useDadataMetro(state.metro, {
    enabled: !!state.metro
  })

  useEffect(() => {
    if (statusDadataMetro === 'success') {
      setState({ suggestions: dadataMetro.data?.suggestions })
    } else if (statusDadataMetro === 'error') {
      message.error('Ошибка получения подсказок Dadata')
    }
  }, [dadataMetro, statusDadataMetro])

  const getSuggestions = useMemo(
    () =>
      debounce(query => {
        setState({ metro: query })
      }, 800),
    []
  )

  return (
    <Form.Item
      label="Станция метро"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name="metro"
      value={state.metro}
    >
      <AutoComplete
        options={state.suggestions}
        onSelect={handleChange}
        onSearch={getSuggestions}
        placeholder="Выберите станцию метро"
        popupMatchSelectWidth={false}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
      />
    </Form.Item>
  )
}

export default DadataMetroItem

DadataMetroItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  metro: PropTypes.string
}
