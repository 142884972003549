import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography } from 'antd'

const { Title } = Typography

function EditingTitle({
  level = 4,
  onEdit,
  isEditing = false,
  isNew = false,
  wrapperClassName,
  displayName = '',
  isResetEditing = false
}) {
  const [editing, setEditing] = useState(isEditing)
  const [isHover, setIsHover] = useState(false)
  const [name, setName] = useState(displayName)
  const ref = useRef(null)
  const handleChange = useCallback(
    value => {
      if (value === displayName) setEditing(false)
      onEdit(value)
      setName(value)
    },
    [onEdit, displayName]
  )
  const handleHover = useCallback(() => {
    setIsHover(true)
    setEditing(true)
  }, [])
  const handleBlur = useCallback(() => {
    setIsHover(false)
    const value = ref?.current?.textContent
    if (!value || value === displayName) setEditing(false)
    if (value) {
      onEdit(value)
      setName(value)
    }
  }, [displayName, onEdit])

  const handleStart = useCallback(() => {
    setEditing(true)
  }, [])

  useEffect(() => {
    if (isResetEditing) setEditing(false)
  }, [isResetEditing])

  useEffect(() => {
    setName(displayName)
  }, [displayName])

  useEffect(() => {
    if (ref?.current) {
      ref.current.onmouseover = handleHover
      ref.current.onmouseout = handleBlur
      ref.current.onclick = handleStart
    }
  }, [handleBlur, handleHover, handleStart])

  return (
    <div className={wrapperClassName} ref={ref}>
      <Title
        level={level}
        editable={{
          tooltip: 'Редактировать',
          onChange: handleChange,
          editing: editing || isHover,
          // icon: <div className="triger"></div>,
          enterIcon: null,
          onStart: handleStart,
          ...(isNew ? { text: '' } : {})
        }}
      >
        {name}
      </Title>
    </div>
  )
}

export default EditingTitle
