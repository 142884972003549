import React from 'react'

import Comments from '../../../components/Common/Comments/Comments'
import {
  useMutateCreateCandidateComment,
  useMutateUpdateCandidateComment,
  useMutateDeleteCandidateComment
} from '../../../api/comments/candidateComment'

function CandidateComments({ candidate, avatarColSpan = 3 }) {
  return (
    <Comments
      avatarColSpan={avatarColSpan}
      entity={candidate}
      onDelete={useMutateDeleteCandidateComment}
      onCreate={useMutateCreateCandidateComment}
      onUpdate={useMutateUpdateCandidateComment}
    />
  )
}

export default CandidateComments
