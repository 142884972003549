import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select, Button, Typography } from 'antd'
import { add } from 'date-fns'
import { simpleReducer } from '../../../../helpers'

const { Option } = Select
const { Title, Text } = Typography

function RecallForm({ onAction, action }) {
  const [state, setState] = useReducer(simpleReducer, {
    days: '0',
    hours: '0',
    minutes: '0'
  })

  const handleChange = (key, value) => {
    setState({ [key]: value })
  }

  const handleUpdate = () => {
    const nextCall = add(new Date(), {
      days: state.days,
      hours: state.hours,
      minutes: state.minutes
    })
    onAction(action, { nextCall })
  }

  const getNumList = (length, step = 1) => {
    return Array.from({ length }, (_, i) => i * step)
  }

  const isValid = () => state.days !== '0' || state.hours !== '0' || state.minutes !== '0'

  const days = getNumList(6)
  const hours = getNumList(24)
  const minutes = getNumList(12, 5)

  return (
    <>
      <Title level={4} className="title">
        {action.message}
      </Title>
      <Row className="mb-3" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={8}>
          <Text>ДНЕЙ</Text>
          <Select className="d-flex" value={state.days} onChange={e => handleChange('days', e)}>
            {days.map(v => (
              <Option key={v} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        </Col>
        <Col className="gutter-row" span={8}>
          <Text>ЧАСОВ</Text>
          <Select className="d-flex" value={state.hours} onChange={e => handleChange('hours', e)}>
            {hours.map(v => (
              <Option key={v} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        </Col>
        <Col className="gutter-row" span={8}>
          <Text>МИНУТ</Text>
          <Select
            className="d-flex"
            value={state.minutes}
            onChange={e => handleChange('minutes', e)}
          >
            {minutes.map(v => (
              <Option key={v} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <div className="btn-wrap">
        <Button className="green" onClick={handleUpdate} disabled={!isValid()}>
          Сохранить
        </Button>
        <Button className="red" onClick={() => onAction(null, null)}>
          Отмена
        </Button>
      </div>
    </>
  )
}
export default RecallForm

RecallForm.propTypes = {
  action: PropTypes.shape({
    message: PropTypes.string
  }).isRequired,
  onAction: PropTypes.func.isRequired
}
