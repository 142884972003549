import React, { useCallback, useMemo, useState } from 'react'
import { Button, Col, Divider, Row, Typography, Input } from 'antd'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import removeUserImg from '../../../../../img/orgStructure/remove-red.svg'
import OrgUnitTree from './OrgUnitTree'
import { useConfigContextData } from '../../../../../hooks/useConfigContextData'
import { langCases } from '../../../../../constants/appConfig'

const { Text } = Typography
const { Search } = Input

function EditOrgUser({
  tree,
  onChangeAddress,
  onDeleteUser,
  onClose,
  // onSave,
  isLoadingUpdateUser,
  isHeaderWithDeleteBtn,
  lastSelectedUnitChildrenIds,
  showSecondaryBlock,
  settingUser,
  onChangeUserScope
}) {
  const [search, setSearch] = useState('')
  const { getDepartmentSingularName } = useConfigContextData()
  const handleSearchChange = useCallback(
    event => {
      onChangeAddress(event)
      setSearch(event?.target?.value)
    },
    [onChangeAddress]
  )

  const handleChecked = useCallback(
    ids => {
      onChangeUserScope({
        ...settingUser,
        scope: {
          ...settingUser.scope,
          newDepartments: ids
        }
      })
    },
    [settingUser, onChangeUserScope]
  )
  const treeData = useMemo(() => {
    const loop = data =>
      data?.map(item => {
        const strTitle = item?.name
        if (!strTitle) return {}
        const index = strTitle.toLowerCase().indexOf(search.toLowerCase())
        const beforeStr = strTitle.substring(0, index)
        const afterStr = strTitle.slice(index + search.length)
        const name =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="tree-search-value">
                {strTitle.substring(index, index + search.length)}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          )
        if (item.children) {
          return {
            ...item,
            name,
            children: loop(item.children)
          }
        }
        return {
          ...item,
          name
        }
      })

    return search ? loop(tree) : tree
  }, [tree, search])

  return (
    <div className="drawer__wrap">
      <div className="drawer-content">
        <div>
          <Row justify="space-between" align="large">
            {isHeaderWithDeleteBtn ? (
              <Row onClick={onClose} align="middle" gutter={[16, 8]} className="cursor-pointer">
                <Col>
                  <LeftOutlined />
                </Col>
                <Col className="block-title filters no-gutters">{settingUser?.name}</Col>
              </Row>
            ) : (
              <div className="block-title filters no-gutters">{settingUser?.name}</div>
            )}
            <Col>
              {settingUser ? (
                isHeaderWithDeleteBtn ? (
                  <Button
                    className="header-button with-img"
                    type="link"
                    size="small"
                    onClick={onDeleteUser}
                  >
                    <img src={removeUserImg} alt="button layers" />
                    <Text type="danger">Удалить</Text>
                  </Button>
                ) : (
                  <>
                    <Button
                      type="link"
                      size="medium"
                      onClick={onClose}
                      disabled={isLoadingUpdateUser}
                    >
                      <LeftOutlined /> назад
                    </Button>
                  </>
                )
              ) : (
                <Button type="link" size="medium" onClick={onClose}>
                  Закрыть
                </Button>
              )}
            </Col>
          </Row>
          <Divider className="small" />
          <Row>
            <Search
              className="without-addon org-search mb-3 custom-search"
              placeholder={`Адрес ${getDepartmentSingularName(langCases.genitive)?.toLowerCase()}`}
              onChange={handleSearchChange}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Row>
          <OrgUnitTree
            isVisible={showSecondaryBlock}
            tree={treeData}
            initChecked={settingUser?.scope?.newDepartments}
            onChangeChecked={handleChecked}
            expanded={lastSelectedUnitChildrenIds}
          />
        </div>
      </div>
    </div>
  )
}
export default EditOrgUser
