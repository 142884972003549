import React from 'react'
import { Navigate, useOutlet } from 'react-router-dom'
import { useProfile } from '../hooks/useProfile'
import { Layout } from 'antd'

export const ProtectedLayout = () => {
  const { user } = useProfile()
  const outlet = useOutlet()

  if (!Object.keys(user).length) {
    return <Navigate to="/" />
  }

  return <Layout hasSider>{outlet}</Layout>
}
