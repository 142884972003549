import { externalName } from './'

export const CANDIDATES_PAGE = '/candidates'
export const CANDIDATE_PAGE = '/candidates/:id'
export const NEW_CANDIDATE_PAGE = '/newCandidate'
export const FIND_CANDIDATES_PAGE = '/findCandidates'
export const FIND_CANDIDATE_PAGE = '/findCandidates/:id'
export const PLANNING_PAGE = '/planning'
export const PLAN_PAGE = '/planning/:id'
export const PLANNING_DEPARTMENTS_PAGE = '/planningDepartments'
export const PLANNING_DEPARTMENT_PAGE = '/planningDepartments/:id'
export const PLANNING_PERIOD_PAGE = '/planningPeriod'
export const SELECTED_PLANNING_PERIOD_PAGE = '/planningPeriod/:year/:month'
export const REPORT_PAGE = '/report'
export const ANALYTICS_PAGE = '/analytics'
export const PROFILE_PAGE = '/profile'
export const SETTINGS_PAGE = '/settings'
export const USERS_PAGE = '/users'
export const DEPARTMENTS_PAGE = '/departments'
export const DEPARTMENT_PAGE = '/departments/:id'
export const PLANNING_EMPTY_PAGE = '/emptyPlanning'
export const SELECTED_PLANNING_EMPTY_PAGE = '/emptyPlanning/:year/:month/:departmentId'
export const CALENDAR_PAGE = '/calendar'
export const ORG_STRUCTURE_PAGE = '/orgStructure'
export const AGENCIES_PAGE = '/agencies'
export const AGENCY_PAGE = '/agencies/:id'
export const DEDUPLICATION_PAGE = '/deduplication'
export const DEDUPLICATION_USER_PAGE = '/deduplication/:id'
export const INTEGRATIONS_PAGE = '/integrations'
export const SOURCES_PAGE = '/sources'
export const WORKFLOWS_PAGE = '/workflows'
export const WORKFLOW_PAGE = '/workflows/:workflowName'
export const WORKFLOW_STATE_PAGE = '/workflows/:workflowName/:stateName'
export const VACANCIES_PAGE = '/vacancies'
export const VACANCY_PAGE = '/vacancies/:id'

export const PASSWORD_RECOVERY = '/passwordRecovery'
export const FORGOT_PASSWORD = '/forgotPassword'

export const EXTERNAL_BUTTON = '/' + externalName
export const EXTERNAL_BUTTON_CANDIDATE_PAGE = '/' + externalName + '/:id'

export const NO_AUTH_CANDIDATE_PAGE = '/noAuth/candidate/:id'
