import React from 'react'
import { Button } from 'antd'

function ActionButton({ onAction, disabled = false, name, appearance, message }) {
  return (
    <Button
      key={name}
      type={appearance?.fill && appearance?.color !== 'transparent' ? 'primary' : 'default'}
      className={appearance?.color}
      style={{
        border: `1px solid ${appearance?.color || 'black'}`,
        minWidth: appearance?.width || 'auto',
        color:
          appearance?.fill && appearance?.color !== 'transparent'
            ? 'white'
            : (appearance?.color !== 'transparent' && appearance?.color) || 'black',
        background:
          appearance?.fill && appearance?.color !== 'transparent' ? appearance?.color : 'white'
      }}
      onClick={() => onAction(name)}
      disabled={disabled}
    >
      {message}
    </Button>
  )
}
export default ActionButton
