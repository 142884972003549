import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { ConfigContext } from '../../../contexts/configContext'
import { concatUtmValuesByKey } from '../../../helpers'
import { colSettings } from '../../../constants'

const { Option } = Select

const UtmSourceItem = ({
  utmMedium = null,
  disabled = false,
  onChange = () => {},
  required = false
}) => {
  const { references } = useContext(ConfigContext)

  const list = useMemo(
    () => concatUtmValuesByKey(references.data.utms, 'utmSources', utmMedium),
    [references.data.utms, utmMedium]
  )

  return (
    <Form.Item
      label="Источник"
      labelCol={colSettings.full}
      name="utm_source"
      rules={[{ required, message: 'Укажите источник' }]}
    >
      <Select
        size="middle"
        placeholder="Выберите значение"
        notFoundContent="Нет вариантов"
        onChange={onChange}
        disabled={disabled}
        getPopupContainer={trigger => trigger.parentNode}
      >
        {list.map(c => (
          <Option key={c} value={c}>
            {c}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default UtmSourceItem

UtmSourceItem.propTypes = {
  utmMedium: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool
}
