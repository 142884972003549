import React from 'react'
import {
  PreferredDepartmentItem,
  PreferredRateItem,
  PreferredTimeItem,
  PreferredPositionItem
} from '../../FormItems/CandidateProfileItems'

const CandidatePreferencesEdit = () => {
  return (
    <>
      <PreferredPositionItem />
      <PreferredRateItem />
      <PreferredDepartmentItem />
      <PreferredTimeItem />
    </>
  )
}

export default CandidatePreferencesEdit
