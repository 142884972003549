import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { Button, Form, Row, Col, Spin, Select, Typography, Divider } from 'antd'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'

import { appConfig } from '../../../../../constants/appConfig'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { UserContext } from '../../../../../contexts/userContext'
import './CandidateProfileEdit.less'
import { useMutateUpdateCandidate } from '../../../../../api/candidate'
import {
  FioItem,
  PhoneItem,
  CitizenshipItem,
  BirthAndAgeItem,
  EmailItem,
  ProfileUrlItem,
  RequestTypeItem,
  UtmMediumItem,
  UtmSourceItem,
  UtmCampaignItem,
  UtmTermItem,
  DadataAddressItem,
  VacanciesItem,
  CommentItem,
  CurrentPositionItem,
  CurrentOrganizationItem,
  DadataMetroItem
} from '../../FormItems/CandidateProfileItems'
import { useReferences } from '../../../../../hooks/useReferences'
import { simpleReducer } from '../../../../../helpers'
import { formatPhoneNumberForApi } from '../../../../../helpers/phone'
import { ConfigContext } from '../../../../../contexts/configContext'
import { modules } from '../../../../../constants'
import { useVacancies } from '../../../../../hooks/useVacancies'
import { CandidatePreferencesEdit, UserFieldsFormItem } from '../../'
import { customPropertiesSettingsAtom } from '../../../../../recoil/atoms'

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}
const { Option } = Select
const { Text } = Typography

const CandidateProfileEdit = props => {
  const [form] = Form.useForm()
  const { candidate } = useContext(CandidatePanelContext)
  const { user, isHiringManager, isManager } = useContext(UserContext)
  const { references } = useContext(ConfigContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  const initialState = {
    dadataAddress: null,
    dadataMetro: null,
    utms: {
      requestType: candidate.requestType || null,
      utm_medium: candidate.utm_medium || null
    },
    isRequestTypeIsCareerSite: false
  }
  const [state, dispatch] = useReducer(simpleReducer, initialState)

  useReferences({
    citizenships: true,
    regions: moduleName === modules.bk,
    divisions: moduleName === modules.bk,
    requestTypes: true,
    utms: true,
    utmsQuery: state.utms.requestType
  })

  const { isLoadingVacancies, vacancies: vacancyList } = useVacancies({
    showInLists: true
  })

  /**
   * Mutate Candidate and update queryById if update isSuccess
   */
  const { mutate, status: statusEdit, isLoading } = useMutateUpdateCandidate()

  const filterFields = useCallback(
    (name, value) => {
      if (name === 'phone') {
        return value.substring(1) !== candidate[name]
      }
      if (name === 'vacancy') {
        return value !== candidate.vacancy?._id
      }
      return value !== candidate[name]
    },
    [candidate]
  )
  const handleEditUser = useCallback(async () => {
    const changedValues = Object.fromEntries(
      Object.entries(form.getFieldsValue(null, meta => meta.touched) || {}).filter(([key, value]) =>
        filterFields(key, value)
      )
    )
    if (changedValues.birthday) {
      const birthday = changedValues?.birthday?.format(appConfig.formats.shortDateApi)
      if (birthday !== candidate?.birthday) {
        changedValues.birthday = birthday
      } else {
        delete changedValues.birthday
      }
    }
    if (
      (state.dadataAddress?.city || state.dadataAddress?.settlement || state.dadataMetro?.city) !==
      candidate?.city
    ) {
      changedValues.city =
        state.dadataAddress?.city || state.dadataAddress?.settlement || state.dadataMetro?.city
    }
    if (changedValues.userFields) {
      changedValues.userFields = Object.entries(form.getFieldValue('userFields')).map(
        ([field, value]) => ({
          field,
          value
        })
      )
    }

    await mutate({ id: candidate._id, data: changedValues })
  }, [mutate, state.dadataAddress, state.dadataMetro, candidate, form, filterFields])

  useEffect(() => {
    if (statusEdit === 'success') {
      props.onSave?.()
    }
  }, [statusEdit, props])

  useEffect(() => {
    form.setFieldsValue({
      ...candidate,
      address: candidate.address || candidate.city,
      birthday:
        candidate.birthday && dayjs(candidate.birthday).isValid() ? dayjs(candidate.birthday) : '',
      phone: candidate.phone ? formatPhoneNumberForApi(candidate.phone) : '',
      vacancy: vacancyList?.find(vacancy => vacancy?._id === candidate.vacancy?._id)?._id,
      ...(candidate.userFields && {
        userFields: Object.fromEntries(
          candidate.userFields?.map(item => [item.field?._id, item.value])
        )
      })
    })
  }, [candidate, form, user?.department?.city, vacancyList])

  useEffect(() => {
    const isRequestTypeIsCareerSite =
      candidate.requestType?.trim().toLowerCase() ===
      appConfig.requestTypeCareerSite.trim().toLowerCase()
    dispatch({ isRequestTypeIsCareerSite })
  }, [candidate])

  const handleFillAddress = useCallback(data => dispatch({ dadataAddress: data }), [])
  const handleFillMetro = useCallback(data => dispatch({ dadataMetro: data }), [])

  const handleSetRequestType = value => {
    dispatch({
      utms: {
        requestType: value,
        utm_medium: null
      }
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    dispatch({
      utms: {
        ...state.utms,
        utm_medium: value
      }
    })
    form.setFieldsValue({
      utm_source: null
    })
  }
  const isUtmEditDisabled = useMemo(
    () =>
      isHiringManager
        ? candidate?.application
          ? !(
              candidate?.application?.department?._id === user.department?._id &&
              candidate?.origin === 'department'
            )
          : true
        : moduleName === modules.bk && isManager,
    [isHiringManager, user, candidate, moduleName, isManager]
  )

  return (
    <Spin spinning={isLoadingVacancies}>
      <Form
        form={form}
        onFinish={handleEditUser}
        className="candidate-profile-form"
        scrollToFirstError
      >
        <Divider className="small" />
        <Row gutter={16}>
          <Col span={12}>
            <VacanciesItem vacancyList={vacancyList} />
            <FioItem required />
            <CitizenshipItem />
            <PhoneItem required />
            <EmailItem />
            <BirthAndAgeItem form={form} required={false} />

            {moduleName === modules.bk && (
              <>
                <Text type="secondary">
                  Выберите ближайший адрес, указав станцию метро или адрес
                </Text>
                <DadataMetroItem onChange={handleFillMetro} metro={candidate.metro} />
              </>
            )}
            <DadataAddressItem
              onChange={handleFillAddress}
              address={candidate.address || candidate.city}
            />
            {moduleName === modules.bk && (
              <>
                <Form.Item label="Регион" {...formItemLayout} name="region">
                  <Select size="middle" placeholder="Выберите регион">
                    {references.data.regions?.map(c => (
                      <Option key={c} value={c}>
                        {c}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Дивизион" {...formItemLayout} name="division">
                  <Select size="middle" placeholder="Выберите дивизион">
                    {references.data.divisions?.map(c => (
                      <Option key={c} value={c}>
                        {c}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <RequestTypeItem
              value={state.utms.requestType}
              onChange={handleSetRequestType}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEdit || isUtmEditDisabled}
            />
            <UtmMediumItem
              value={state.utms.utm_medium}
              onChange={handleSetUtmMedium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEdit || isUtmEditDisabled}
            />
            <UtmSourceItem
              utmMedium={state.utms.utm_medium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEdit || isUtmEditDisabled}
            />
            <UtmCampaignItem
              utmMedium={state.utms.utm_medium}
              disabled={state.isRequestTypeIsCareerSite || !candidate.canEdit || isUtmEditDisabled}
            />
            <UtmTermItem disabled={!candidate.canEdit || isUtmEditDisabled} />
            <ProfileUrlItem disabled={isUtmEditDisabled} />
          </Col>

          <Col span={12}>
            <CurrentPositionItem />
            <CurrentOrganizationItem />

            <CandidatePreferencesEdit />

            <CommentItem />
            <UserFieldsFormItem />
          </Col>
        </Row>

        <div className="drawer-footer">
          <Divider className="small" />
          <Row className="buttons">
            <Col>
              <Button type="link" className="mr-3" disabled={isLoading} onClick={props.onClose}>
                Отмена
              </Button>
              <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  )
}

export default CandidateProfileEdit
