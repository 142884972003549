import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'

export const PARTNERS_KEY = 'partners'

const fetchGetPartners = () => {
  return api.get({ path: PARTNERS_KEY })
}
export function useGetPartners(options) {
  return useQuery([PARTNERS_KEY], fetchGetPartners, options)
}

const fetchUpdatePartnerById = ({ id, data }) => {
  return api.patch({ path: `${PARTNERS_KEY}/${id}`, data })
}
export function useMutateUpdatePartnerById() {
  return useMutation(fetchUpdatePartnerById, {
    onSuccess: () => queryClient.refetchQueries(PARTNERS_KEY)
  })
}
