import React, { useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useRecoilValue } from 'recoil'

import { useRoutes } from '../../../../hooks/useRoutes'
import {
  PASSWORD_RECOVERY,
  FORGOT_PASSWORD,
  EXTERNAL_BUTTON,
  PLANNING_PERIOD_PAGE,
  PLANNING_DEPARTMENTS_PAGE
} from '../../../../constants/routes'
import { modules } from '../../../../constants'
import LinkWrapper from '../../../../components/LinkWrapper'
import { UserContext } from '../../../../contexts/userContext'
import { ConfigContext } from '../../../../contexts/configContext'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'

const { Sider } = Layout

const Sidebar = ({ collapsed }) => {
  // Don't show on Auth page
  const { pathname } = useLocation()
  const { user } = useContext(UserContext)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const { menuTheme } = useRecoilValue(customPropertiesSettingsAtom)

  const { routes } = useRoutes(user, modules.massRecruitment)

  const selectedKeys = useMemo(() => {
    let name = `/${pathname.split('/')?.[1]}`
    if (name === PLANNING_PERIOD_PAGE) {
      name = PLANNING_DEPARTMENTS_PAGE
    }
    return name
  }, [pathname])

  const filteredRoutes = useMemo(
    () =>
      routes
        ?.filter(route => route.name)
        ?.filter(route =>
          route.featureToggle ? features?.some(feature => route.featureToggle === feature) : true
        ),
    [features, routes]
  )

  const menuItems = useMemo(
    () =>
      filteredRoutes?.map(route => {
        const linkProps = route.useHtmlLink ? { href: route.path } : { to: route.path }
        return {
          label: <LinkWrapper {...linkProps}>{route.name}</LinkWrapper>,
          key: route.path
        }
      }),
    [filteredRoutes]
  )

  if (
    pathname === '/' ||
    pathname === PASSWORD_RECOVERY ||
    pathname === FORGOT_PASSWORD ||
    pathname === EXTERNAL_BUTTON
  )
    return null

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      width={250}
      theme={menuTheme}
    >
      <div className="sidebar-logo">
        <div className="logo" />
      </div>
      <Menu mode="inline" selectedKeys={selectedKeys} theme={menuTheme} items={menuItems} />
    </Sider>
  )
}
export default Sidebar

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired
}
