import PropTypes from 'prop-types'
import { appConfig } from './appConfig'

export const commentShape = PropTypes.shape({
  _id: PropTypes.string,
  text: PropTypes.string,
  author: PropTypes.string,
  authorName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
})

// export const checkupShape = PropTypes.shape({
//   examinationTypeId: PropTypes.number,
//   firstName: PropTypes.string,
//   lastName: PropTypes.string,
//   patronymicName: PropTypes.string,
//   sex: PropTypes.string, // должно быть PropTypes.oneOf(appConfig.....) "Male"
//   birthDate: PropTypes.string,
//   phoneNumber: PropTypes.string,
//   citizenship: PropTypes.string,
//   position: PropTypes.string,
//   allDocuments: PropTypes.bool,
//   passportNo: PropTypes.string,
//   passportSerial: PropTypes.string,
//   passportAuthority: PropTypes.string,
//   schedule: PropTypes.string, // "Partial"
//   hourlyRate: PropTypes.number,
//   restaurantNo: PropTypes.string,
//   deliveryAddress: PropTypes.string,
//   services: PropTypes.string,
//   smo: PropTypes.string,
//   admitted: PropTypes.bool,
//   correlationId: PropTypes.string
// })

const planRateShape = PropTypes.shape({
  rate: PropTypes.oneOf([0.2, 0.5, 0.75, 1, 1.5]),
  employees: PropTypes.number, // действующие - заполняется на фронте
  dismiss: PropTypes.number, // увольнения - заполняется на фронте
  training: PropTypes.number, // на развитие - заполняется на фронте
  formalize: PropTypes.number, // офомлено (по системе) - заполняются из Аксапты
  working: PropTypes.number // работающие (по системе) - заполняются из Аксапты
})

export const crewmanShape = PropTypes.shape({
  name: PropTypes.string,
  partTime: PropTypes.number,
  fullTime: PropTypes.number,
  forAdultsOnly: PropTypes.bool,
  time: PropTypes.shape({
    morning: PropTypes.bool,
    noon: PropTypes.bool,
    evening: PropTypes.bool,
    night: PropTypes.bool,
    weekend: PropTypes.bool
  }),
  citizenship: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
  gender: PropTypes.shape({
    male: PropTypes.bool,
    female: PropTypes.bool
  })
})

const vacancyWorkTimeShape = PropTypes.shape({
  name: PropTypes.string,
  count: PropTypes.number,
  time: PropTypes.shape({
    morning: PropTypes.bool,
    noon: PropTypes.bool,
    evening: PropTypes.bool,
    weekend: PropTypes.bool
  })
})

export const departmentShape = PropTypes.shape({
  _id: PropTypes.string,
  location: PropTypes.shape({
    coordinates: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.string // 'Point'
  }),
  externalId: PropTypes.string,
  restaurantId: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.string, // 'opened'
  city: PropTypes.string,
  address: PropTypes.string,
  timeZone: PropTypes.string, // '(GMT+04:00) Москва, Санкт-Петербург, Волгоград',
  timeOffset: PropTypes.number, // 180,
  email: PropTypes.string,
  director: PropTypes.string,
  TU: PropTypes.string,
  executiveDirector: PropTypes.string,
  divisonalOperationDirector: PropTypes.string,
  managerEmail: PropTypes.string,
  executiveDirectorEmail: PropTypes.string,
  createdAt: PropTypes.date,
  updatedAt: PropTypes.date,
  settings: PropTypes.shape({
    interviewHours: PropTypes.arrayOf(
      PropTypes.shape({
        // 7 обьектов по кол-ву дней недели
        start: PropTypes.string, // '13:00' или '0' - если день выходной
        end: PropTypes.string // '22:00' или '0' - если день выходной
      })
    )
  }),
  externalIdShort: PropTypes.number
})

export const userShape = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  login: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  department: departmentShape,
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(appConfig.roles)))
})

export const planShape = PropTypes.shape({
  _id: PropTypes.string,
  department: departmentShape,
  interviewerDepartment: departmentShape,
  year: PropTypes.number,
  month: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  averageOutput: PropTypes.shape({
    fullTime: PropTypes.number,
    partTime: PropTypes.number
  }),
  tradeTurnover: PropTypes.number, // плановый товарооборот - заполняются из Аксапты
  spmh: PropTypes.number, // плановая производительность (SPMH) - заполняются из Аксапты
  workers: PropTypes.shape({
    fullTime: PropTypes.number,
    partTime: PropTypes.number
  }),
  estimate: PropTypes.shape({
    planWorkingHours: PropTypes.number, // плановое количество часов линейки - считается на фронте, не редактируемое поле
    factWorkingHours: PropTypes.number, // фактическое количество часов - считается на фронте, не редактируемое поле
    workingHoursPercent: PropTypes.number, // % выработки часов - считается на фронте, не редактируемое поле
    planFullTime: PropTypes.number, // План по ФТ - считается на фронте, не редактируемое поле
    planPartTime: PropTypes.number, // План по ПТ - заполняется на фронте
    staffFullnessPercent: PropTypes.number, // % заполненности штата - считается на фронте, не редактируемое поле
    demandFullTime: PropTypes.number, // потребность ФТ - считается на фронте, не редактируемое поле
    demandPartTime: PropTypes.number // потребность ПТ - считается на фронте, не редактируемое поле
  }),
  rates: PropTypes.arrayOf(planRateShape),
  stopSelection: PropTypes.bool,
  priority: PropTypes.bool,
  current: PropTypes.shape({
    crewmen: PropTypes.arrayOf(crewmanShape),
    vacancies: PropTypes.shape({
      fullTime: PropTypes.arrayOf(vacancyWorkTimeShape),
      partTime: PropTypes.arrayOf(vacancyWorkTimeShape),
      additional: PropTypes.arrayOf(vacancyWorkTimeShape)
    }),
    forAdultsOnly: false,
    comment: PropTypes.string
  }),
  approved: PropTypes.shape({
    crewmen: PropTypes.arrayOf(crewmanShape),
    vacancies: PropTypes.shape({
      fullTime: PropTypes.arrayOf(vacancyWorkTimeShape),
      partTime: PropTypes.arrayOf(vacancyWorkTimeShape),
      additional: PropTypes.arrayOf(vacancyWorkTimeShape)
    }),
    forAdultsOnly: false,
    comment: PropTypes.string
  }),
  comments: PropTypes.arrayOf(commentShape)
})

export const appointmentShape = PropTypes.shape({
  _id: PropTypes.string,
  candidate: PropTypes.string,
  department: PropTypes.string,
  state: PropTypes.string,
  workflow: PropTypes.string,
  interview: PropTypes.shape({
    start: PropTypes.date,
    end: PropTypes.date
  })
})

export const referencesShape = PropTypes.shape({
  citizenships: PropTypes.arrayOf(PropTypes.string),
  utms: PropTypes.arrayOf(PropTypes.string), // utm метки полученные по определенному значению поля requestType
  requestTypes: PropTypes.arrayOf(PropTypes.string),
  sources: PropTypes.arrayOf(PropTypes.string),
  mediums: PropTypes.arrayOf(PropTypes.string),
  inboxStates: PropTypes.arrayOf(PropTypes.string),
  cities: PropTypes.arrayOf(PropTypes.string),
  positions: PropTypes.arrayOf(PropTypes.string) // позиции поддерживаемые сайтом для редактирования анкеты
})

export const settingsShape = PropTypes.shape({
  [appConfig.settings.sms]: PropTypes.string
})

export const periodShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  isCurrent: PropTypes.bool,
  isPast: PropTypes.bool
})

export const excelColumnsShape = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.number,
  render: PropTypes.func,
  excelData: PropTypes.shape({
    title: PropTypes.string,
    render: PropTypes.func,
    width: PropTypes.number
  })
})

export const vacancyShape = PropTypes.shape({
  _id: PropTypes.string,
  displayName: PropTypes.string
  // position: PropTypes.string, // Должность по штатному расписанию
  // department: PropTypes.string, // Подразделение
  // direction: PropTypes.string, // Управление
  // managerName: PropTypes.string, // ФИО Руководителя
  // managerEmail: PropTypes.string, // Email Руководителя
  // topManagerEmail: PropTypes.string, // Email Вышестоящего Руководителя
  // benefits: PropTypes.arrayOf(PropTypes.string),
  // salary: PropTypes.string,
  // responsibilities: PropTypes.string,
  // requirements: PropTypes.string,
  // needToHire: PropTypes.number, // Сколько человек надо нанять
  // hired: PropTypes.number, // Сколько нанято
  // rejected: PropTypes.number, // Сколько отказов
  // candidates: PropTypes.number, // Сколько кандидатов
  // interviewScheduled: PropTypes.number, // Сколько кандидатов в статусе собеседование назначено
  // deadline: PropTypes.string, // срок для найма
  // visibility: PropTypes.oneOf(appConfig.vacancyVisibilityVariants.map(variant => variant.value)), // Видимость
  // responsible: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string,
  //     login: PropTypes.string
  //   })
  // ), // Кто работает над вакансией
  // priority: PropTypes.oneOf(appConfig.vacancyPriorityVariants.map(variant => variant.value)), // Приоритет
  // workflow: PropTypes.shape({
  //   _id: PropTypes.string,
  //   name: PropTypes.string, // дефолтное значение 'crewman' === candidate,
  //   displayName: PropTypes.string
  // }),
  // state: PropTypes.oneOf(appConfig.vacancyStateVariants),
  // description: PropTypes.string, // Описание вакансии,
  // createdAt: PropTypes.string,
  // updatedAt: PropTypes.string
})

export const candidateExperienceShape = PropTypes.shape({
  company: PropTypes.string, // "ООО Спектр Групп"
  description: PropTypes.string, // "Описание рабочих моментов",
  from: PropTypes.string, // "2008-03-01",
  position: PropTypes.string, // "Региональный менеджер",
  to: PropTypes.string, // "2013-10-01",
  _id: PropTypes.string // "605202bf3072823a74036649"
})

export const candidateEducationShape = PropTypes.shape({
  name: PropTypes.string, // "СПб НИУ ВШЭ"
  description: PropTypes.string, // "Маркетинг-менедмент",
  to: PropTypes.string, // "2013",
  _id: PropTypes.string // "605202bf3072823a74036649"
})

export const candidateShape = PropTypes.shape({
  _id: PropTypes.string,
  state: PropTypes.shape({ name: PropTypes.oneOf(Object.values(appConfig.statuses.values)) }),
  name: PropTypes.string, // ФИО
  city: PropTypes.string, // Город
  phone: PropTypes.string, // Телефон
  email: PropTypes.string, // email
  citizenship: PropTypes.string, // Гражданство
  birthday: PropTypes.string, // дата рождения
  age: PropTypes.number, // Возраст
  utm_source: PropTypes.string, // Utm Source
  utm_campaign: PropTypes.string, // Utm Campaign
  utm_medium: PropTypes.string, // Utm Medium
  utm_term: PropTypes.string, // Utm Term
  profileUrl: PropTypes.string, // ссылка на резюме
  requestType: PropTypes.string, // Тип обращения
  source: PropTypes.string,
  createdAt: PropTypes.string, // Дата получения CV
  possibleActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.values(appConfig.workflows.candidate.actions)),
      roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(appConfig.roles)))
    })
  ),
  currentPosition: PropTypes.string, // Текущая должность "Software developer"
  currentOrganization: PropTypes.string, // Текущее место работы "Improvity"
  // experience: PropTypes.string, // Опыт работы 20
  experience: PropTypes.arrayOf(candidateExperienceShape),
  education: PropTypes.arrayOf(candidateEducationShape),
  coveringLetter: PropTypes.string, // Сопроводительное письмо
  resumeContent: PropTypes.string, // Полный контент резюме
  preferredRate: PropTypes.oneOf(appConfig.preferences.rates), // Желаемая ставка
  preferredTime: PropTypes.arrayOf(PropTypes.oneOf(appConfig.preferences.times)), // Желаемое время работы []
  preferredDepartment: PropTypes.string, // Желаемое место работы (адрес ресторана)
  comment: PropTypes.string, // комментарий
  calls: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        state: 'missed',
        createdAt: PropTypes.date
      }),
      PropTypes.shape({
        state: 'callLater',
        nextCall: PropTypes.date,
        createdAt: PropTypes.date
      })
    ])
  ),
  vacancy: vacancyShape,
  comments: PropTypes.arrayOf(commentShape),
  unreadMessagesCount: PropTypes.number
})
