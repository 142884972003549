import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  Children,
  cloneElement,
  useContext
} from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Table, Spin, ConfigProvider, Typography, message, Tooltip } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { HolderOutlined, PushpinOutlined } from '@ant-design/icons'

import { VACANCY_PAGE } from '../../../../constants/routes'
import { getItemStyle, getListStyle, reorder } from '../../Helpers/dragndrop'

import {
  customPropertiesSettingsAtom,
  vacancyFiltersAtom,
  vacancySettingsAtom,
  vacancySorterAtom
} from '../../../../recoil/atoms'
import { useMutateUpdateVacancy } from '../../../../api/vacancy'
import { isActiveVacancyText } from '../../../../helpers/vacancy'
import { useGetLinkedUsersJobSite } from '../../../../api/integrations'
import { appConfig } from '../../../../constants/appConfig'
import { modules } from '../../../../constants'
import { ConfigContext } from '../../../../contexts/configContext'

const { Text } = Typography

function VacancyList({ vacancies, pagination, onChangePagination }) {
  const navigate = useNavigate()
  const tableRef = useRef(null)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const {
    features: { data: features }
  } = useContext(ConfigContext)

  const setVacancySettings = useSetRecoilState(vacancySettingsAtom)
  // const vacancySettings = useRecoilValue(vacancySettingsSelector)
  const setVacancyFilters = useSetRecoilState(vacancyFiltersAtom)
  const setVacancySorter = useSetRecoilState(vacancySorterAtom)

  const {
    mutate: mutateUpdateVacancy,
    isLoading: isLoadingUpdateVacancy,
    isSuccess: isSuccessUpdateVacancy,
    isError: isErrorUpdateVacancy
  } = useMutateUpdateVacancy()

  useEffect(() => {
    if (isSuccessUpdateVacancy) {
      message.success('Процесс успешно обновлен!')
    }
    if (isErrorUpdateVacancy) {
      message.error('Ошибка обновления данных процесса')
    }
  }, [isSuccessUpdateVacancy, isErrorUpdateVacancy])

  // Скрыто - нужно для фильтрации и сортировки
  // const roles = useMemo(() => {
  //   const commonRoles = [appConfig.roles.hiringManager, appConfig.roles.recruiter]
  //   const bkRoles = [appConfig.roles.partner, appConfig.roles.TU, appConfig.roles.operationDirector]
  //   return moduleName !== modules.bk ? commonRoles : [...commonRoles, ...bkRoles]
  // }, [moduleName])

  const {
    data: dataGetUsersJobSite,
    isError: isErrorGetUsersJobSite
    // isLoading: isLoadingGetUsersJobSite
  } = useGetLinkedUsersJobSite(appConfig.integrationSites.hrm, {
    enabled: moduleName !== modules.bk && features?.includes(appConfig.features.hrm)
  })

  useEffect(() => {
    if (isErrorGetUsersJobSite) {
      message.error('Ошибка получения списка ботов')
    }
  }, [isErrorGetUsersJobSite, dataGetUsersJobSite])

  const jobSiteDataBots = useMemo(() => dataGetUsersJobSite?.data, [dataGetUsersJobSite])

  const onDragEnd = useCallback(
    async result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      const items = reorder(vacancies, result.source.index, result.destination.index).map(
        (item, index) => {
          item.sortOrder = index + 1
          return item
        }
      )

      items.forEach(item => mutateUpdateVacancy(item))
    },
    [vacancies, mutateUpdateVacancy]
  )

  const vacanciesIdForResponses = useMemo(
    () => vacancies?.filter(v => v.forResponses)?.map(v => v._id),
    [vacancies]
  )

  const DraggableBodyRow = useCallback(
    ({ index, className, style, ...restProps }) => (
      <Draggable key={restProps._id} draggableId={restProps._id} index={index}>
        {(provided, snapshot) => {
          if (snapshot?.isDragging) {
            // библиотека "react-beautiful-dnd" добавляет к перетаскиваемому элементу "position: fixed"
            // для сохранения размеров ячеек перетаскиваемой строки таблицы необходимо задать каждой ячейке "width"
            const headerWidth = [
              ...(tableRef?.current?.querySelectorAll('thead .ant-table-cell') || [])
            ].map(e => e?.clientWidth)

            return (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`${className} draggable-item`}
                style={{
                  ...style,
                  ...getItemStyle(snapshot?.isDragging, provided.draggableProps.style),
                  padding: 0,
                  margin: 0
                }}
              >
                {Children.map(restProps.children, (child, index) => (
                  <td width={headerWidth[index]}>{cloneElement(child)}</td>
                ))}
              </tr>
            )
          }

          return (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`${className} draggable-item`}
              style={{
                ...style,
                ...getItemStyle(snapshot?.isDragging, provided.draggableProps.style),
                ...(vacanciesIdForResponses.includes(restProps?._id)
                  ? { backgroundColor: 'rgba(132, 205, 135, .3)' }
                  : {})
              }}
              {...restProps}
            />
          )
        }}
      </Draggable>
    ),
    [vacanciesIdForResponses]
  )

  const DraggableBodyWrapper = useCallback(
    ({ className, ...restProps }) => (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot?.isDraggingOver)}
              className={`${className} droppable-list`}
            >
              {restProps.children}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    ),
    [onDragEnd]
  )

  const components = useMemo(
    () => ({
      body: {
        wrapper: DraggableBodyWrapper,
        row: DraggableBodyRow
      }
    }),
    [DraggableBodyRow, DraggableBodyWrapper]
  )

  const columns = [
    {
      title: '',
      dataIndex: 'dnd',
      width: 20,
      render: () => <HolderOutlined />
    },
    {
      title: 'Название вакансии',
      dataIndex: 'displayName',
      width: 250,
      render: (text, item) => <Text type={!item.active && 'danger'}>{text}</Text>
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      width: 200,
      render: isActiveVacancyText
    },
    {
      title: 'Процесс найма',
      dataIndex: ['workflow', 'displayName'],
      width: 150
    },
    {
      title: '',
      dataIndex: 'pinned',
      width: 50,
      render: useCallback(
        (value, item) => {
          const botName = jobSiteDataBots?.find(bot => bot?.internal?.vacancy === item?._id)
            ?.external?.name

          return botName ? (
            <Tooltip title={`Вакансия прикреплена к активному боту "${botName}".`}>
              <div className="text-center">
                <PushpinOutlined />
              </div>
            </Tooltip>
          ) : null
        },
        [jobSiteDataBots]
      )
    }
  ]

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { field, order } = sorter
      onChangePagination(pagination)
      setVacancySettings(oldValues => ({
        ...(oldValues || {}),
        current: pagination.current,
        pageSize: pagination.pageSize
      }))
      setVacancyFilters(filters)
      setVacancySorter({ field, order })
    },
    [setVacancySettings, setVacancyFilters, setVacancySorter, onChangePagination]
  )

  const handleSelectRow = useCallback(
    (record, index) => ({
      index,
      _id: record._id,
      onClick: () => {
        navigate(generatePath(VACANCY_PAGE, { id: record._id }))
        setVacancySettings(oldValues => ({
          ...(oldValues || {}),
          activeTab: record._id
        }))
      }
    }),
    [setVacancySettings, navigate]
  )

  return (
    <div className="VacancyList">
      <Spin spinning={isLoadingUpdateVacancy} size="large">
        {vacancies?.length > 0 && (
          <ConfigProvider locale={ruRu}>
            <Table
              className="clickable-table"
              size="middle"
              ref={tableRef}
              // scroll={tableScroll}
              columns={columns}
              components={components}
              pagination={pagination}
              rowKey="_id"
              dataSource={vacancies}
              onChange={handleTableChange}
              onRow={handleSelectRow}
              // rowClassName={getRowClassName}
            />
          </ConfigProvider>
        )}
      </Spin>
    </div>
  )
}

export default VacancyList
