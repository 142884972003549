export const PHONE_VALIDATION_MESSAGE = 'Укажите телефон в формате +7 (000) 123 45 67'

export const validatePhone = (rule, value, callback) => {
  !(value && value.replace(/\D/g, '').length === 11)
    ? callback(new Error(PHONE_VALIDATION_MESSAGE))
    : callback()
}

export const formatPhoneNumberForApi = phone => '+' + ('' + phone).replace(/\D/g, '')

export const formatPhoneNumber = str => {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  // Check if the input is of correct
  const match = cleaned.match(/^([78]|)?(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+7 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], ' ', match[4], ' ', match[5]].join('')
  }
  return str
}

export const formatPrefix = (str, prefix = '+7') => {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  // Check if the input is of correct
  const match = cleaned.match(/^([78]|)?(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? prefix : ''
    return [intlCode, match[2], match[3], match[4], match[5]].join('')
  }
  return str
}
