import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Drawer, message } from 'antd'
import { CandidatePanel } from '../index'
import { useMutateUpdateCandidate } from '../../../../api/candidate'

function CandidateDrawer({
  onClose,
  isVisible = false,
  candidateId = null,
  candidateInfo,
  drawerClass = 'candidate-drawer',
  onlyView = false,
  withOris
}) {
  const [isClosableDrawer, setIsClosableDrawer] = useState(false)
  /**
   * Mutate Candidate and update queryById if update isSuccess
   */
  const { mutate, status: statusEdit } = useMutateUpdateCandidate()

  useEffect(() => {
    if (statusEdit === 'success') {
      message.success('Кандидат успешно обновлен!')
    }
    if (statusEdit === 'error') {
      message.error('Ошибка обновления данных кандидата')
    }
  }, [statusEdit])

  const handleEditPreferences = useCallback(
    e => mutate({ id: candidateId, data: e }),
    [candidateId, mutate]
  )

  const candidateInfoData = useMemo(
    () => ({
      ...(candidateInfo || {}),
      onEdit: candidateInfo?.isEditPreferences ? handleEditPreferences : null
    }),
    [candidateInfo, handleEditPreferences]
  )

  return (
    <Drawer
      width={888}
      placement="right"
      closable={false}
      onClose={() => {
        !isClosableDrawer && onClose?.()
      }}
      open={isVisible}
      rootClassName={drawerClass}
    >
      {candidateId && (
        <CandidatePanel
          candidateId={candidateId}
          onlyView={onlyView}
          withOris={withOris}
          candidateInfo={candidateInfoData}
          setIsClosableDrawer={setIsClosableDrawer}
          onClose={onClose}
        />
      )}
    </Drawer>
  )
}
export default CandidateDrawer
