import React from 'react'
import { Typography } from 'antd'
const { Text } = Typography

export const getVacancyFullName = (vacancy, delimiter = ' / ') =>
  [vacancy.department, vacancy.direction, vacancy.position].filter(Boolean).join(delimiter)

export const isNewVacancy = vacancy => !vacancy.candidates && !isClosedVacancy(vacancy)
export const isClosedVacancy = vacancy => vacancy.state === 'Closed'
export const isSuspendedVacancy = vacancy => vacancy.state === 'Suspended'
export const isInProgressVacancy = vacancy => !isNewVacancy(vacancy) && !isClosedVacancy(vacancy)

export const getVacancyState = vacancy => {
  if (isClosedVacancy(vacancy)) {
    return 'Закрытая'
  } else if (isNewVacancy(vacancy)) {
    return 'Новая'
  }
  return 'В работе'
}
export const isActiveVacancyText = active =>
  active ? 'Активная' : <Text type="danger">Неактивная</Text>
