import React from 'react'
import { Col, Tag } from 'antd'
import { appConfig } from '../constants/appConfig'

const rolesColor = {
  [appConfig.roles.recruiter]: 'green',
  [appConfig.roles.admin]: 'purple',
  [appConfig.roles.hiringManager]: 'blue',
  [appConfig.roles.manager]: 'volcano',
  [appConfig.roles.TU]: 'volcano',
  [appConfig.roles.operationDirector]: 'volcano',
  [appConfig.roles.partner]: 'volcano'
}

export const usersTagList = ({ list, userPositions, position }) => (
  <>
    {list?.map(role => (
      <Col key={role}>
        <Tag color={rolesColor[role]}>
          {appConfig.rolesLabels[role]}
          {role === appConfig.roles.manager &&
            position?._id &&
            ` - ${userPositions?.find(p => p._id === position?._id)?.name}`}
        </Tag>
      </Col>
    ))}
  </>
)
