import { createContext } from 'react'
import { userShape } from '../constants/propTypesShapes'

export const UserContext = createContext({
  user: {},
  isRecruiter: false,
  isHiringManager: false,
  isManager: false,
  isAdmin: false,
  isPartner: false,
  isOperationDirector: false,
  isTU: false,
  isPrioritySelector: false,
  isAmpUser: false,
  isOneRole: false,
  setUser: () => {}
})

UserContext.Provider.propTypes = {
  user: userShape
}
