import React from 'react'
import { sortBy } from '../../../../../helpers'

function UserFieldsList({ userFields }) {
  return (
    <div>
      {/*<Text type="secondary">Пользовательские поля:</Text>*/}
      <div className="item">&nbsp;</div>
      {userFields
        ?.filter(item => item.field?.enable)
        ?.sort(sortBy('field.sortOrder'))
        ?.map(item => (
          <div className="item" key={item._id}>
            {item.field?.displayName}:{' '}
            {Array.isArray(item.value) ? item.value.join(', ') : item.value}
          </div>
        ))}
      {/*{!userFields?.length && <Text type="secondary">&nbsp;Отсутствуют</Text>}*/}
    </div>
  )
}

export default UserFieldsList
