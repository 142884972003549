import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'
import { AGENCY_KEY } from './agency'
import { USERS_KEY } from './users'

const TOPIC_LOGIN = 'client.jobId.cmd.login'
const TOPIC_LOGOUT = 'client.jobId.cmd.logout'
const TOPIC_AUTH = 'integration.jobId.cmd.setup'
const TOPIC_USERS = 'state.jobId.cmd.get'
const TOPIC_LINK_USERS = 'users.jobId.cmd.link'
const TOPIC_GET_USERS = 'users.jobId.cmd.get'
const TOPIC_JOBSITES_LIST = 'integrations.jobsites.cmd.list'
const TOPIC_ACTION_AUTH = 'action.jobId.cmd.start'
const TOPIC_UTM_TAGS_SET = 'tags.jobId.cmd.set'
const TOPIC_TELEGRAM_STATUS = 'telegram.core.cmd.get_status'
const TOPIC_TELEGRAM_LINK = 'telegram.core.cmd.link'
const TOPIC_TELEGRAM_UNLINK = 'telegram.core.cmd.unlink'

// универсальный медот - использовать его передавай внутрь уникальный топик для сервиса
const fetchIntegration = ({ topic, data }) => api.post({ path: `integrations/${topic}`, data })

const loginJobSite = ({ jobId, data }) => {
  fetchIntegration({ topic: TOPIC_LOGIN.replace('jobId', jobId), data })
}
const logoutJobSite = ({ jobId, data }) =>
  fetchIntegration({ topic: TOPIC_LOGOUT.replace('jobId', jobId), data })
const authJobSite = ({ jobId, data }) =>
  fetchIntegration({ topic: TOPIC_AUTH.replace('jobId', jobId), data })
const linkUsersJobSite = ({ jobId, data }) =>
  fetchIntegration({ topic: TOPIC_LINK_USERS.replace('jobId', jobId), data })
const getUsersJobSite = ({ queryKey }) =>
  fetchIntegration({ topic: TOPIC_GET_USERS.replace('jobId', queryKey[1]) })
const usersJobSite = ({ queryKey }) =>
  fetchIntegration({ topic: TOPIC_USERS.replace('jobId', queryKey[1]) })
// const jobSites = () => fetchIntegration({ topic: TOPIC_JOBSITES_LIST })

const fetchByQuery = ({ queryKey }) => fetchIntegration({ topic: queryKey })
const actionAuth = ({ jobId, data }) =>
  fetchIntegration({
    topic: TOPIC_ACTION_AUTH.replace('jobId', jobId),
    data: { ...data, type: 'authorize' }
  })

const saveUtms = ({ integrationId, data }) =>
  fetchIntegration({ topic: TOPIC_UTM_TAGS_SET.replace('jobId', integrationId), data })

export const useAuthJobSite = () => useMutation(authJobSite)
export const useLoginJobSite = () =>
  useMutation(loginJobSite, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([TOPIC_USERS, variables?.jobId])
    }
  })
export const useLogoutJobSite = () =>
  useMutation(logoutJobSite, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([TOPIC_USERS, variables?.jobId])
    }
  })
export const useActionAuth = () => useMutation(actionAuth)
export const useSaveUtms = () =>
  useMutation(saveUtms, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([TOPIC_USERS, variables?.integrationId])
    }
  })
export const useLinkUsersJobSite = jobId =>
  useMutation(linkUsersJobSite, {
    onSuccess: () => {
      queryClient.refetchQueries([TOPIC_USERS, jobId])
      queryClient.refetchQueries([TOPIC_GET_USERS, jobId])
      queryClient.refetchQueries(USERS_KEY)
      queryClient.refetchQueries(AGENCY_KEY)
    }
  })
export const useGetLinkedUsersJobSite = (jobId, options) =>
  useQuery([TOPIC_GET_USERS, jobId], getUsersJobSite, options)
export const useGetUsersJobSite = (jobId, options) =>
  useQuery([TOPIC_USERS, jobId], usersJobSite, options)
export const useGetJobSites = options => useQuery(TOPIC_JOBSITES_LIST, fetchByQuery, options)

export const useGetTelegramStatus = options =>
  useQuery(TOPIC_TELEGRAM_STATUS, fetchByQuery, options)

const telegramLink = data => fetchIntegration({ topic: TOPIC_TELEGRAM_LINK, data })
const telegramUnlink = data => fetchIntegration({ topic: TOPIC_TELEGRAM_UNLINK, data })

export const useGetTelegramLink = () =>
  useMutation(telegramLink, {
    onSuccess: () => {
      queryClient.refetchQueries(TOPIC_TELEGRAM_STATUS)
    }
  })
export const useGetTelegramUnlink = () =>
  useMutation(telegramUnlink, {
    onSuccess: () => {
      queryClient.refetchQueries(TOPIC_TELEGRAM_STATUS)
    }
  })
