import React from 'react'
import PropTypes from 'prop-types'
import { acronym, generateHexFromString } from '../../helpers'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { imagesPrefix } from '../../constants'

function UserAvatar({ photoUrl = null, userName = '', userId = null, className = null, size }) {
  /**
   * Если есть фото у юзера, просто выводим его
   */
  if (photoUrl) {
    return <Avatar className={className} src={imagesPrefix + photoUrl} size={size} />
  }
  /**
   * Если фото нет, берём акроним юзернейма и задаём ему цвет (типа как в gmail и тп)
   * Для генерации цвета используем приоритетно userId, потому что имя может быть одинаковым, чтоб цвет отличался и можно было различить кандидатов
   */
  const backgroundColor = generateHexFromString(userId || userName)
  return (
    <Avatar
      className={className}
      style={{ backgroundColor }}
      size={size}
      icon={userName ? acronym(userName) : <UserOutlined />}
    />
  )
}

export default UserAvatar

UserAvatar.propTypes = {
  photoUrl: PropTypes.string,
  userName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
}
