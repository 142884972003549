import React from 'react'
import { defaultTitle } from '../../../../constants'

function CurrentSituation({ candidate }) {
  return (
    <>
      <div className="item">Кем работает: {candidate?.currentPosition || defaultTitle}</div>
      <div className="item">Где работает: {candidate?.currentOrganization || defaultTitle}</div>
    </>
  )
}

export default CurrentSituation
