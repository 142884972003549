import React, { useCallback } from 'react'
import { Modal, Row, Col, Form } from 'antd'
import PhoneItem from '../../../../components/Common/FormItems/PhoneItem'

export default function ModalPhoneForm({
  isVisible = false,
  onOk,
  onClose,
  list = [],
  loading = false
}) {
  const [form] = Form.useForm()

  const handleOk = useCallback(() => {
    form.submit()
  }, [form])

  const handleCancel = useCallback(() => {
    onClose?.()
  }, [onClose])

  const handleFinish = useCallback(values => onOk?.(values), [onOk])

  return (
    <Modal
      title="Добавить телефон"
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Сохранить"
      cancelText="Отмена"
      className="integrations-modal"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form form={form} onFinish={handleFinish}>
        {list?.map(user => (
          <Row key={user.external.id}>
            <Col span={12} className="user-info">
              {user.external.name}
              <div>{user.external.email}</div>
            </Col>
            <Col span={12}>
              <PhoneItem label={null} className="user-phone" name={user.external.id} required />
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  )
}
