import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format
} from 'date-fns'
import { numberDeclension } from './index'
import { appConfig } from '../constants/appConfig'
import dayjs from 'dayjs'

export function passedTimeRu(dateString) {
  if (!dateString) return ''
  const date = new Date(dateString)
  const now = new Date()

  // Если разница в секундах положительная, то заданная дата из будущего, просто покажем её пусть видят
  const diffInSeconds = differenceInSeconds(date, now)
  if (diffInSeconds > 0) {
    return format(date, appConfig.formats.shortDateAndTimeFns)
  }

  // Если разница в секундах отрицательная, то заданная дата из прошлого, посчитаем разницу уже без учета знака
  const diffInDays = Math.abs(differenceInDays(date, now))
  const diffInHours = Math.abs(differenceInHours(date, now))
  const diffInMinutes = Math.abs(differenceInMinutes(date, now))
  // Несколько минут назад, полных часов, дней еще нет
  if (diffInHours === 0 && diffInDays === 0) {
    return `${diffInMinutes} ${numberDeclension(diffInMinutes, [
      'минуту',
      'минуты',
      'минут'
    ])} назад`
  }
  // Несколько часов назад, дней еще нет, минуты уже не важны
  if (diffInHours > 0 && diffInHours < 24) {
    return `${diffInHours} ${numberDeclension(diffInHours, ['час', 'часа', 'часов'])} назад`
  }
  // Больше дня назад, часы и минуты не важны
  return format(date, appConfig.formats.shortDateAndTimeFns)
}

// export function passedDaysRu(dateString) {
//   const date = new Date(dateString)
//   const now = new Date()
//
//   const days = Math.abs(differenceInDays(date, now))
//   return `${days} ${numberDeclension(days, ['день', 'дня', 'дней'])}`
// }

export function formatDateTime(dateString) {
  return format(new Date(dateString), appConfig.formats.dateAndTimeFns)
}

export function getAge(birthday) {
  return dayjs().diff(birthday, 'years')
}

/**
 * Создает дефаултный массив рабочих полных суток на всю неделю.
 * Чтобы было отличие от null при сложении с реальным диапазоном рабочих дней
 */
export function makeWeekFullWorkingDays() {
  return Array.from({ length: 7 }, () => ({ start: '00:00', end: '23:59' }))
}

// Показывает разницу в датах словами, если не задана вторая дата, то будет сравнение с "сейчас"
export const getStringDiffDates = (firstDate, secondDate) => {
  let message = ''
  const firstMoment = firstDate ? new Date(firstDate) : new Date()
  const secondMoment = secondDate ? new Date(secondDate) : new Date()
  const days = differenceInDays(firstMoment, secondMoment)
  const hours = differenceInHours(firstMoment, secondMoment)
  let minutes = differenceInMinutes(firstMoment, secondMoment)

  if (hours < 24) {
    minutes = minutes - 60 * hours
  }

  if (days >= 1) {
    message = 'Истекает ' + format(firstMoment, appConfig.formats.shortDateFns)
  } else if (days < 1 && days >= 0 && hours < 24 && minutes > 0) {
    message = 'Истекает через ' + (hours ? hours + ' ч. ' : '') + minutes + ' мин.'
  } else if (days <= 0 && (hours < 0 || minutes < 0)) {
    if (days < 0) {
      message = 'Просрочено на ' + Math.abs(days) + ' д.'
    } else {
      message = 'Просрочено на ' + Math.abs(hours) + ' ч. ' + Math.abs(minutes) + ' мин.'
    }
  }
  return message
}
