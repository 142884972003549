import React, { useContext, useEffect } from 'react'
import { Form } from 'antd'

import { convertCrewmenToObject } from '../../../../helpers/plan'
import RequestNeedTable from '../PlanningPersonal/RequestNeedTable'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import RequestNeedTableAMP from '../PlanningPersonal/RequestNeedTableAMP'

const COLUMNS_SIZE = {
  name: 140,
  partTime: 40,
  fullTime: 40,
  forAdultsOnly: 40,
  morning: 40,
  noon: 40,
  evening: 40,
  night: 40,
  weekend: 40,
  cis: 40,
  male: 40,
  female: 40
}

function DepartmentPlan({ plan }) {
  const [form] = Form.useForm()
  const { isAmpCandidate } = useContext(CandidatePanelContext)

  useEffect(() => {
    if (plan) {
      form.setFieldsValue(convertCrewmenToObject(plan))
    }
  }, [form, plan])

  return (
    <Form form={form} className="planningPersonal" colon={false}>
      {isAmpCandidate ? (
        <RequestNeedTableAMP data={plan?.additional} size="small" readOnly isDepartmentPlan />
      ) : (
        <RequestNeedTable
          readOnly
          isDepartmentPlan
          data={plan.crewmen}
          size="small"
          columnsSize={COLUMNS_SIZE}
          plan={plan}
          form={form}
        />
      )}
    </Form>
  )
}

export default DepartmentPlan
