import React from 'react'
import { Col, Form, Row, DatePicker } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'
import PropTypes from 'prop-types'

const BirthdayItem = ({ readOnly }) => {
  return (
    <Row>
      <Col span={5}>
        <Form.Item label="Дата рождения" required={true} />
      </Col>
      <Col span={6}>
        <Form.Item
          name="birthday"
          rules={[
            {
              required: true,
              message: 'Укажите дату рождения'
            }
          ]}
        >
          <DatePicker
            disabled={readOnly}
            size="middle"
            placeholder="дд.мм.гггг"
            format={appConfig.formats.shortDate}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default BirthdayItem

BirthdayItem.propTypes = {
  readOnly: PropTypes.bool
}
