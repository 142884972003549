import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import { colSettings } from '../../../constants'

const UtmTermItem = ({ disabled = false }) => {
  return (
    <Form.Item
      label="Utm Term"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="utm_term"
    >
      <Input disabled={disabled} />
    </Form.Item>
  )
}

export default UtmTermItem

UtmTermItem.propTypes = {
  disabled: PropTypes.bool
}
