import React, { useCallback, useContext, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { Button, Col, Divider, message, Modal, Row, Typography, Upload } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { useRecoilValue } from 'recoil'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { sortBy } from '../../../../helpers'
import { appConfig } from '../../../../constants/appConfig'
import { modules, nbsp } from '../../../../constants'
import env from '../../../../env.json'
import { refetchCandidate } from '../../../../api/candidate'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'

const { Title, Text } = Typography
const { confirm } = Modal

function Resume() {
  const { candidate, setIsClosableDrawer } = useContext(CandidatePanelContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const renderItem = item => {
    return (
      <div key={item._id} className="mb-3">
        <Title level={5}>{item.company}</Title>
        {item.from && item.to && (
          <>
            <Text>{format(new Date(item.from), appConfig.formats.shortDateFns)}</Text>
            {nbsp}-{nbsp}
            <Text>{format(new Date(item.to), appConfig.formats.shortDateFns)}</Text>
          </>
        )}
        <div>
          <Text strong>{item.position}</Text>
        </div>
        <div className="description">{item.description}</div>
      </div>
    )
  }

  const uploadAction = useCallback(async () => {
    const path = `${env.apiEndpoint}candidates/${candidate._id}/files`
    if (!candidate.files?.length) return path
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Загрузка резюме',
        content:
          'Загрузка нового файла резюме повлечет удаление уже загруженного файла. Продолжить?',
        okText: 'Продолжить',
        cancelText: 'Отмена',
        onOk: () => resolve(path),
        onCancel: () => reject()
      })
    })
  }, [candidate])

  const uploadChange = useCallback(
    ({ file }) => {
      if (file?.status === 'uploading') {
        setIsLoadingUpload(true)
        setIsClosableDrawer(true)
        return
      }
      if (file?.status === 'done' || file?.status === 'error') {
        setIsLoadingUpload(false)
        setIsClosableDrawer(false)
        refetchCandidate()
      }
    },
    [setIsClosableDrawer]
  )

  const beforeUpload = useCallback(file => {
    const isLt5M = file.size / 1024 ** 2 < 5
    if (!isLt5M) {
      message.error('Загружаемый файл не должен быть больше 5MB!')
    }
    return isLt5M
  }, [])

  const handleRequestDownload = useCallback(() => {
    setIsLoadingDownload(true)
    setTimeout(() => setIsLoadingDownload(false), 2000)
  }, [])

  const href = useMemo(() => {
    const url = `candidates/${candidate._id}/files/${candidate.files?.[0]}`
    return `${env.apiEndpoint}${url}`
  }, [candidate])

  return (
    <div className="resume">
      {moduleName === modules.bk && (
        <>
          <Row gutter={16}>
            <Col>
              <Upload
                action={uploadAction}
                name="cv"
                onChange={uploadChange}
                accept=".doc, .docx, .pdf, .jpg, .png"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  loading={isLoadingUpload}
                  disabled={isLoadingDownload}
                >
                  {candidate.files?.length ? 'Заменить' : 'Выбрать'} резюме
                </Button>
              </Upload>
            </Col>
            <Col>
              <a href={href} download="Ошибка. Нет данных">
                <Button
                  type="primary"
                  onClick={handleRequestDownload}
                  loading={isLoadingDownload}
                  icon={<DownloadOutlined />}
                  disabled={!candidate.files?.length || isLoadingUpload}
                >
                  Скачать резюме
                </Button>
              </a>
            </Col>
          </Row>
          <Divider className="small" />
          {candidate?.experience?.length > 0 && (
            <Row className="mb-3">
              <Text type="secondary">Резюме с рекрутингого сайта</Text>
            </Row>
          )}
        </>
      )}
      {candidate?.experience?.sort(sortBy('from')).reverse().map(renderItem)}
    </div>
  )
}

export default Resume
