import React from 'react'
import { Form, Input } from 'antd'
import { colSettings } from '../../../../../constants'

const { TextArea } = Input

function CommentItem({ label = 'Комментарий для директора', placeholder = '' }) {
  return (
    <Form.Item
      label={label}
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="comment"
    >
      <TextArea rows={2} maxLength={300} placeholder={placeholder} className="hm-comment-edit" />
    </Form.Item>
  )
}

export default CommentItem
