import { getDate, isThisMonth, isBefore, subMonths } from 'date-fns'
import { appConfig } from '../constants/appConfig'
import { convertArrayToObject } from './index'
import { formatLocale } from './date-fns'

export function getPeriods({ previousCount = 3, forceGetNext = false } = {}) {
  const now = new Date()
  const periods = [now]
  for (let i = 0; i < previousCount; i++) {
    periods.push(subMonths(new Date(periods[i]), 1))
  }
  if (getDate(now) >= appConfig.nextPlanningAvailableDate || forceGetNext) {
    periods.unshift(subMonths(now, -1))
  }
  return periods.map(period => ({
    id: formatLocale(period, 'Y/M'),
    name: formatLocale(period, 'LLLL yyyy'),
    isCurrent: isThisMonth(period),
    isPast: isBefore(period, now)
  }))
}

export function getPeriodByPlan(plan) {
  return getPeriods().find(period => period.id === `${plan?.year}/${plan?.month}`)
}

export function getPreviousPeriod(periodId) {
  const index = getPeriods()?.findIndex(period => period.id === periodId)
  if (index >= 0) {
    return getPeriods()[index + 1] || null
  }
  return null
}

export function isApproved(plan) {
  return plan?.state === appConfig.planStatuses.approved
}

export function isRejected(plan) {
  return plan?.state === appConfig.planStatuses.rejected
}

export function getApprovedDate(history) {
  return (
    [...history]?.reverse()?.find(item => item.name === appConfig.planStatuses.approved)
      ?.createdAt || null
  )
}

export function getVacanciesCount(vacancies, id) {
  return vacancies?.find(item => item?.vacancy?._id === id || item?.vacancy === id)?.fullTime || 0
}

export function getCrewmenCount(crewmen, id, type) {
  return crewmen?.find(item => item.vacancy?._id === id)?.[type] || 0
}

export function getTotalCrewmenCount(crewmen) {
  return crewmen?.reduce((prev, current) => {
    return current.vacancy?.countInTotal
      ? prev + (current.fullTime || 0) + (current.partTime || 0)
      : prev
  }, 0)
}

export function getTotalCouriersCount(item) {
  return item?.crewmen?.reduce((prev, current) => {
    return current.vacancy?.countCouriersInTotal
      ? prev + (current.fullTime || 0) + (current.partTime || 0)
      : prev
  }, 0)
}

export function existsVacanciesTime(crewmen, vacancyId, time, title) {
  return crewmen?.find(item => item?.vacancy?._id === vacancyId)?.time?.[time] ? title : ''
}

export function convertCrewmenToObject(data) {
  const newData = {
    ...(data || {}),
    crewmen: convertArrayToObject(data?.crewmen?.length ? data.crewmen : [], 'vacancy._id')
  }
  Object.keys(newData.crewmen).forEach(item => {
    if (Array.isArray(newData.crewmen[item].citizenship)) {
      newData.crewmen[item].citizenship = {
        cis: newData.crewmen[item].citizenship.includes('cis')
      }
    }
  })
  return newData
}
