import { useMutation, useQuery } from 'react-query'
import { searchDadata, metroDadata, api } from './api'

export const DATA_SEARCH_KEY = 'dadata-search'
export const DATA_METRO_KEY = 'dadata-metro'

const fetchGetDadataSearch = ({ queryKey }) => {
  const [, /* key */ query] = queryKey
  return searchDadata({ query })
}

export function useDadataSearch(query, options) {
  return useQuery([DATA_SEARCH_KEY, query], fetchGetDadataSearch, options)
}

const fetchGetDadataFio = data => {
  return api.post({ path: 'dadata/name', data })
}

export function useMutateDadataFio() {
  return useMutation(fetchGetDadataFio)
}

const fetchGetDadataMetro = ({ queryKey }) => {
  const [, /* key */ query] = queryKey
  return metroDadata({ query })
}

export function useDadataMetro(query, options) {
  return useQuery([DATA_METRO_KEY, query], fetchGetDadataMetro, options)
}
