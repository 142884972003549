import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { EmailItem } from '../CandidateProfileItems'
import { appConfig } from '../../../../../constants/appConfig'
import UserModalForm from '../../User/UserModalForm'
import ManagersItemSelect from '../ManagersItemSelect/ManagersItemSelect'

const CreateOrSelectManagerItem = ({ role = appConfig.roles.manager, form }) => {
  const [isOpenNewManagers, setIsOpenNewManagers] = useState(false)

  const handleAfterSave = data => {
    form.setFieldsValue({ managerEmail: data?.email, managers: data?._id })
    setIsOpenNewManagers(false)
  }
  const handleClose = () => {
    setIsOpenNewManagers(false)
    form.resetFields([role, role + 'Email'])
  }
  return (
    <>
      <ManagersItemSelect form={form} onOpenNewManager={() => setIsOpenNewManagers(true)} />
      <Row>
        <Col span={24}>
          <EmailItem name={role + 'Email'} required disabled label="" />
        </Col>
      </Row>
      <UserModalForm
        roleDisable
        roles={[role]}
        withoutButton
        isOpen={isOpenNewManagers}
        afterSave={handleAfterSave}
        onClose={handleClose}
      />
    </>
  )
}

export default CreateOrSelectManagerItem

CreateOrSelectManagerItem.propTypes = {
  role: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string
}
