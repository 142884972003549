export const layers = [
  {
    name: 'Уровень 1',
    placeholder: 'Добавьте верхний уровень'
  },
  {
    name: 'Уровень 2',
    placeholder: 'Добавьте второй уровень'
  },
  {
    name: 'Уровень 3',
    placeholder: 'Добавьте третий уровень'
  },
  {
    name: 'Уровень 4',
    placeholder: 'Добавьте четвертый уровень'
  },
  {
    name: 'Уровень 5',
    placeholder: 'Добавьте пятый уровень'
  }
]
export const noOrgStructureName = 'Не задано'

/** ищем noOrgStructureName в списке где находится удаляемый элемент
 * список это все дети родителя для удаляемого(выбраного) элемента
 * так как удаляем всегда последний из выбраных(length - 1) то родитель для него (length - 2)
 **/
export const penultimateIndex = 2
