import { useMutation } from 'react-query'
import { api, queryClient } from '../api'
import { APPOINTMENTS_KEY } from '../appointment'
import { CANDIDATE_KEY, CANDIDATES_KEY, CANDIDATES_SEARCH_KEY } from '../candidate'
import { AMP_KEY } from '../amp'

const putCandidateComment = ({ id, data }) => {
  return api.put({ path: `candidates/${id}/comments`, data })
}

const deleteCandidateComment = ({ id, commentId }) => {
  return api.del({ path: `candidates/${id}/comments/${commentId}` })
}

const patchCandidateComment = ({ id, commentId, data }) => {
  return api.patch({ path: `candidates/${id}/comments/${commentId}`, data })
}

const onSuccessMutateCandidateComment = (data, variables) => {
  queryClient.setQueryData([CANDIDATE_KEY, variables.id], data)
  queryClient.refetchQueries(CANDIDATES_KEY)
  queryClient.refetchQueries(CANDIDATES_SEARCH_KEY, { active: true })
  queryClient.refetchQueries(APPOINTMENTS_KEY, { active: true })
  queryClient.invalidateQueries(AMP_KEY)
  queryClient.refetchQueries(AMP_KEY, { active: true })
}

export function useMutateCreateCandidateComment() {
  return useMutation(putCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment
  })
}

export function useMutateDeleteCandidateComment() {
  return useMutation(deleteCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment
  })
}

export function useMutateUpdateCandidateComment() {
  return useMutation(patchCandidateComment, {
    onSuccess: onSuccessMutateCandidateComment
  })
}
