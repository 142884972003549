import React, { useCallback, useMemo } from 'react'
import { Form, InputNumber, Table, Checkbox } from 'antd'
import { useRecoilValue } from 'recoil'

import { appConfig } from '../../../../constants/appConfig'
import { upperFirst } from '../../../../helpers'
import { useVacancies } from '../../../../hooks/useVacancies'
import { modules } from '../../../../constants'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'

const codes = appConfig.needsVacancies

const defaultSize = {
  name: 'auto',
  partTime: 70,
  fullTime: 70,
  forAdultsOnly: 100,
  morning: 100,
  noon: 100,
  evening: 100,
  night: 100,
  weekend: 100,
  cis: 100,
  male: 50,
  female: 50
}
function RequestNeedTable({
  readOnly = false,
  isDepartmentPlan = false,
  data,
  size = 'middle',
  columnsSize = defaultSize
}) {
  const { isLoadingVacancies, crewmanVacancies } = useVacancies({
    showInForecasting: true
  })
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  const getRenderInput = key => {
    const cb = (text, el) => {
      return (
        <Form.Item name={['crewmen', el._id, key]}>
          {readOnly ? (
            <div>{text}</div>
          ) : (
            <InputNumber min={0} readOnly={readOnly} defaultValue={0} />
          )}
        </Form.Item>
      )
    }
    return cb
  }

  const disableCheckbox = useCallback(
    (key, el) => {
      if (moduleName !== modules.bk) return false
      switch (el.name) {
        case codes.dayCleaner:
          return key === 'night'
        case codes.nightCleaner:
        case codes.nochnoyUborshchik:
          return ['morning', 'day', 'noon', 'evening'].includes(key)
        default:
          return false
      }
    },
    [moduleName]
  )

  const getRenderCheckbox = useCallback(
    arrayKeys => {
      const cb = (text, el) => (
        <Form.Item name={['crewmen', el._id].concat(arrayKeys)} valuePropName="checked">
          <Checkbox disabled={readOnly || disableCheckbox(arrayKeys[arrayKeys.length - 1], el)} />
        </Form.Item>
      )
      return cb
    },
    [disableCheckbox, readOnly]
  )

  const dataSource = useMemo(
    () =>
      (isDepartmentPlan
        ? data
        : [
            // фильтруем вакансии из плана по списку разрешенных к отображению вакансий
            ...(data?.filter(i => crewmanVacancies.find(v => v._id === i?.vacancy?._id)) || []),
            // добавляем разрешенные вакансии которых нет в плане
            ...(
              crewmanVacancies?.map(v => {
                if (!data?.find(i => i.vacancy?._id === v._id)) {
                  return { fullTime: 0, partTime: 0, vacancy: v }
                }
              }) || []
            ).filter(Boolean)
          ]
      )
        ?.map(e => ({ ...e, ...e.vacancy }))
        ?.filter(v =>
          readOnly ? Number.isInteger(v.fullTime) || Number.isInteger(v.partTime) : true
        ),
    [data, readOnly, crewmanVacancies, isDepartmentPlan]
  )

  const columns = [
    {
      title: 'Должность',
      dataIndex: 'displayName',
      width: columnsSize.name,
      render: text => upperFirst(text)
    },
    {
      title: 'ФТ',
      dataIndex: 'fullTime',
      align: 'center',
      width: columnsSize.fullTime,
      render: getRenderInput('fullTime')
    },
    {
      title: 'ПТ',
      dataIndex: 'partTime',
      align: 'center',
      width: columnsSize.partTime,
      render: getRenderInput('partTime')
    },
    {
      title: 'До 18 лет',
      dataIndex: 'forAdultsOnly',
      width: columnsSize.forAdultsOnly,
      align: 'center',
      render: getRenderCheckbox(['forAdultsOnly'])
    },
    {
      title: 'График работы',
      children: [
        {
          title: 'Утро',
          dataIndex: ['time', 'morning'],
          align: 'center',
          width: columnsSize.morning,
          render: getRenderCheckbox(['time', 'morning'])
        },
        {
          title: 'День',
          dataIndex: ['time', 'noon'],
          align: 'center',
          width: columnsSize.noon,
          render: getRenderCheckbox(['time', 'noon'])
        },
        {
          title: 'Вечер',
          dataIndex: ['time', 'evening'],
          align: 'center',
          width: columnsSize.evening,
          render: getRenderCheckbox(['time', 'evening'])
        },
        {
          title: 'Ночь',
          dataIndex: ['time', 'night'],
          align: 'center',
          width: columnsSize.night,
          render: getRenderCheckbox(['time', 'night'])
        },
        {
          title: (
            <div className="text-ellipsis" style={{ width: `${columnsSize.weekend}px` }}>
              Выходные
            </div>
          ),
          dataIndex: ['time', 'weekend'],
          align: 'center',
          width: columnsSize.weekend,
          render: getRenderCheckbox(['time', 'weekend'])
        }
      ]
    },
    {
      title: (
        <div className="text-ellipsis" style={{ width: `${columnsSize.cis}px` }}>
          Гражданство
        </div>
      ),
      children: [
        {
          title: 'СНГ',
          dataIndex: ['citizenship', 'cis'],
          align: 'center',
          width: columnsSize.cis,
          render: getRenderCheckbox(['citizenship', 'cis'])
        }
      ]
    },
    {
      title: 'Пол',
      children: [
        {
          title: 'М',
          dataIndex: ['gender', 'male'],
          align: 'center',
          width: columnsSize.male,
          render: getRenderCheckbox(['gender', 'male'])
        },
        {
          title: 'Ж',
          dataIndex: ['gender', 'female'],
          align: 'center',
          width: columnsSize.female,
          render: getRenderCheckbox(['gender', 'female'])
        }
      ]
    }
  ]

  return (
    <Table
      rowKey="_id"
      loading={isLoadingVacancies}
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      size={size}
    />
  )
}

export default RequestNeedTable
