import React from 'react'
import { Form, Input } from 'antd'
import { colSettings } from '../../../../../constants'
import PropTypes from 'prop-types'

function NameItem({ size = 'middle', labelCol = colSettings.six, wrapperCol = colSettings.full }) {
  return (
    <Form.Item
      label="Имя"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name="name"
      labelAlign="left"
      required
      rules={[
        {
          required: true,
          message: 'Введите имя'
        }
      ]}
    >
      <Input size={size} />
    </Form.Item>
  )
}
NameItem.propTypes = {
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  size: PropTypes.oneOf(['middle', 'small', 'large'])
}
export default NameItem
