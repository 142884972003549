import { api } from './api'
import { useQuery } from 'react-query'

const DASHBOARD_KEY = 'dashboard'
/**
 * при добавлении нового запроса необходимо сбрасывать его кэш
 * при переводе кандидата по статусам
 */
export const REQUIREMENT_KEY = 'requirement'
export const INTERVIEWS_KEY = 'interviews'
export const LEADS_KEY = 'leads'
export const CURRENT_MONTH_INTERVIEWS_KEY = 'currentMonthInterviews'
export const REJECT_REASONS_KEY = 'rejectReasons'
export const CANDIDATE_STATUSES_KEY = 'crewmanStates'
export const SOURCES_AND_STATUSES_KEY = 'sources'

const getRequirements = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${REQUIREMENT_KEY}`, data })
}
const getInterviews = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${INTERVIEWS_KEY}`, data })
}
const getLeads = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${LEADS_KEY}`, data })
}
const getCurrentMonthInterviews = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${CURRENT_MONTH_INTERVIEWS_KEY}`, data })
}
const getRejectReasons = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${REJECT_REASONS_KEY}`, data })
}
const getCandidateStatuses = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${CANDIDATE_STATUSES_KEY}`, data })
}
const getSourcesAndStatuses = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: `${DASHBOARD_KEY}/${SOURCES_AND_STATUSES_KEY}`, data })
}

export function useRequirementsQuery(data, options) {
  return useQuery([REQUIREMENT_KEY, data], getRequirements, options)
}
export function useInterviewsQuery(data, options) {
  return useQuery([INTERVIEWS_KEY, data], getInterviews, options)
}
export function useLeadsQuery(data, options) {
  return useQuery([LEADS_KEY, data], getLeads, options)
}
export function useCurrentMonthInterviewsQuery(data, options) {
  return useQuery([CURRENT_MONTH_INTERVIEWS_KEY, data], getCurrentMonthInterviews, options)
}
export function useRejectReasonsQuery(data, options) {
  return useQuery([REJECT_REASONS_KEY, data], getRejectReasons, options)
}
export function useCandidateStatusesQuery(data, options) {
  return useQuery([CANDIDATE_STATUSES_KEY, data], getCandidateStatuses, options)
}
export function useSourcesAndStatusesQuery(data, options) {
  return useQuery([SOURCES_AND_STATUSES_KEY, data], getSourcesAndStatuses, options)
}
