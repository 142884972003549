import React, { useCallback, useState } from 'react'
import { Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const { Paragraph, Link } = Typography

function EditingParagraph({
  index,
  name = '',
  type,
  onEdit,
  onDelete,
  isEditing = false,
  wrapperClassName,
  isDeleting = false,
  maxLength = 60,
  displayName = ''
}) {
  const [editing, setEditing] = useState(isEditing)
  const handleChange = useCallback(
    value => {
      setEditing(false)
      onEdit({ type, index, value })
    },
    [onEdit, type, index]
  )

  const handleDelete = useCallback(() => {
    onDelete(type, index)
  }, [onDelete, type, index])

  const handleStart = useCallback(() => {
    setEditing(true)
  }, [])

  return (
    <div className={wrapperClassName}>
      <Paragraph
        editable={{
          tooltip: 'Редактировать',
          onChange: handleChange,
          editing,
          maxLength,
          onStart: handleStart
        }}
      >
        {editing ? name : displayName || name}
      </Paragraph>
      {isDeleting && (
        <Link title="Удалить" onClick={handleDelete} className="close">
          <CloseCircleOutlined />
        </Link>
      )}
    </div>
  )
}

export default EditingParagraph
