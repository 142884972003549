import React from 'react'
import { Checkbox, Col, Row, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'

const { Title, Text } = Typography
export default function ({
  list,
  title,
  span,
  needCheckbox,
  onSelectRow,
  onCheckedRow,
  getText,
  noUserText,
  onNeedRightBlock,
  onClickRightBlock,
  getRightBlockText,
  subBlock = null
}) {
  return (
    <>
      <Title className="mt-3 title" level={4}>
        {title}
      </Title>
      {list?.length > 0 ? (
        <>
          {subBlock}
          {list.map(user => (
            <Row key={user._id} justify="space-between" className="user" align="middle">
              <Col span={span}>
                <Row align="middle" onClick={() => onSelectRow?.(user)}>
                  {needCheckbox && (
                    <Col>
                      <Checkbox className="mr-3" checked={onCheckedRow(user._id)} />
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Text>{getText(user.name)}</Text>
                    </Row>
                    <Row>
                      <Text type="secondary">{getText(user.email)}</Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {onNeedRightBlock(user._id) && (
                <Col onClick={() => onClickRightBlock?.(user)} className="stats">
                  {getRightBlockText(user)}
                  <RightOutlined />
                </Col>
              )}
            </Row>
          ))}
        </>
      ) : (
        <Text type="secondary">{noUserText}</Text>
      )}
    </>
  )
}
