import { ColorPicker, Divider } from 'antd'
import React, { useCallback } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'
const colors = {
  recommended: [
    '#000000',
    '#000000E0',
    '#000000A6',
    '#00000073',
    '#00000040',
    '#00000026',
    '#0000001A',
    '#00000012',
    '#0000000A',
    '#00000005',
    '#F5222D',
    '#FA8C16',
    '#FADB14',
    '#8BBB11',
    '#52C41A',
    '#13A8A8',
    '#1677FF',
    '#2F54EB',
    '#722ED1',
    '#EB2F96',
    '#F5222D4D',
    '#FA8C164D',
    '#FADB144D',
    '#8BBB114D',
    '#52C41A4D',
    '#13A8A84D',
    '#1677FF4D',
    '#2F54EB4D',
    '#722ED14D',
    '#EB2F964D'
  ],
  latest: ['#F5222D4D', '#FA8C164D', '#FADB144D', '#8BBB114D', '#52C41A4D', '#13A8A84D']
}
const maxLatestColors = 27

export default function PresetColorPicker({
  onChange,
  defaultValue = '#000',
  handler,
  disabled,
  attributeName = 'color'
}) {
  const [latestColors, setLatestColors] = useLocalStorage('latestColors', colors.latest)
  const handleChange = useCallback(
    (color, value) => {
      onChange?.(handler, value, attributeName)
      const latest = [...new Set([value, ...latestColors])]
      latest.length = Math.min(latest.length, maxLatestColors)

      setLatestColors(latest)
    },
    [handler, onChange, latestColors, setLatestColors, attributeName]
  )

  return (
    <ColorPicker
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={handleChange}
      styles={{
        popupOverlayInner: {
          width: 468 + 24
        }
      }}
      presets={[
        {
          label: 'Рекомендованые',
          colors: colors.recommended
        },
        {
          label: 'Последние',
          colors: latestColors
        }
      ]}
      panelRender={(_, { components: { Picker, Presets } }) => (
        <div
          className="custom-panel"
          style={{
            display: 'flex',
            width: 468
          }}
        >
          <div
            style={{
              flex: 1
            }}
          >
            <Presets
              presets={[
                {
                  label: 'Рекомендованые',
                  colors: colors.recommended
                },
                {
                  label: 'Последние',
                  colors: latestColors
                }
              ]}
            />
          </div>
          <Divider
            type="vertical"
            style={{
              height: 'auto'
            }}
          />
          <div
            style={{
              width: 234
            }}
          >
            <Picker />
          </div>
        </div>
      )}
    />
  )
}
