import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'
import { defaultQueryOptions } from '../constants'

const SETTINGS_KEY = 'settings'
const SETTINGS_LIMITS_KEY = 'settingsLimits'

const getSettings = () => {
  return api.get({ path: 'settings' })
}

const postSettingsByCode = ({ code, value }) => {
  return api.post({ path: 'settings/' + code, data: { value } })
}

const deleteSettingsByCode = ({ code }) => {
  return api.del({ path: 'settings/' + code })
}

const getSettingsLimits = () => {
  return api.get({ path: 'settings/limits' })
}

export function useGetSettingsLimits(options) {
  return useQuery([SETTINGS_LIMITS_KEY], getSettingsLimits, options)
}

export function useGetSettings(options) {
  return useQuery([SETTINGS_KEY], getSettings, options)
}

const settingsQuery = options => ({
  queryKey: [SETTINGS_KEY],
  queryFn: async () => getSettings(),
  ...defaultQueryOptions,
  ...(options || {})
})
export const settingsLoader = async options => {
  const query = settingsQuery(options)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export function useMutateSettingsByCode() {
  return useMutation(postSettingsByCode, {
    onSuccess: () => {
      queryClient.refetchQueries(SETTINGS_KEY)
    }
  })
}

export function useMutateDeleteSettingsByCode() {
  return useMutation(deleteSettingsByCode, {
    onSuccess: () => {
      queryClient.refetchQueries(SETTINGS_KEY)
    }
  })
}
