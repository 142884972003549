import React from 'react'
import PropTypes from 'prop-types'
import './PlanningStatus.css'
import { appConfig } from '../../../../constants/appConfig'

function PlanningStatus({ status = null }) {
  return status ? (
    <div className="planningStatus ml-3 mt-3">{appConfig.planFactStatuses[status]}</div>
  ) : null
}

PlanningStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(appConfig.planFactStatuses))
}

export default PlanningStatus
