import React from 'react'
import { Form, Input } from 'antd'
import { colSettings } from '../../../constants'

const EmailItem = ({
  size = 'middle',
  labelCol = colSettings.full,
  wrapperCol = colSettings.full,
  labelAlign = 'right',
  required = false,
  disabled = false,
  label = 'Email',
  autoComplete = 'on',
  onChange = () => {},
  name = 'email',
  placeholder = 'mail@mail.ru',
  rules
}) => {
  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      labelAlign={labelAlign}
      rules={
        rules || [
          {
            required,
            message: 'Укажите почту'
          }
        ]
      }
    >
      <Input
        size={size}
        type="email"
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default EmailItem
