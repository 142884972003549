import { appConfig } from './appConfig'
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths
} from 'date-fns'

const periodsIds = appConfig.filterPeriods

export const filterPeriods = {
  [periodsIds.lastWeek]: {
    id: periodsIds.lastWeek,
    name: 'Последние 7 дней',
    value: {
      start: endOfDay(subDays(new Date(), 7)),
      end: endOfToday()
    }
  },
  [periodsIds.currentMonth]: {
    id: periodsIds.currentMonth,
    name: 'Этот месяц',
    value: {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date())
    }
  },
  [periodsIds.lastMonth]: {
    id: periodsIds.lastMonth,
    name: 'Прошлый месяц',
    value: {
      start: startOfMonth(subMonths(new Date(), 1)),
      end: endOfMonth(subMonths(new Date(), 1))
    }
  },
  [periodsIds.quarter]: {
    id: periodsIds.quarter,
    name: 'Квартал',
    value: {
      start: startOfQuarter(new Date()),
      end: endOfQuarter(new Date())
    }
  },
  [periodsIds.custom]: {
    id: periodsIds.custom,
    name: 'Выставить вручную'
  }
}
