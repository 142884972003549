import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

export const USER_POSITIONS_KEY = 'userPositions'

const fetchUserPositions = () => {
  return api.get({ path: USER_POSITIONS_KEY })
}
const fetchAddUserPositions = ({ data }) => {
  return api.put({ path: USER_POSITIONS_KEY, data })
}
const fetchUpdateUserPositions = ({ id, data }) => {
  return api.patch({ path: `${USER_POSITIONS_KEY}/${id}`, data })
}
const fetchDeleteUserPositions = ({ id }) => {
  return api.del({ path: `${USER_POSITIONS_KEY}/${id}` })
}

export function useMutateUpdateUserPositions() {
  return useMutation(fetchUpdateUserPositions, {
    onSuccess: () => queryClient.refetchQueries(USER_POSITIONS_KEY)
  })
}
export function useMutateAddUserPositions() {
  return useMutation(fetchAddUserPositions, {
    onSuccess: () => queryClient.refetchQueries(USER_POSITIONS_KEY)
  })
}
export function useMutateDeleteUserPositions() {
  return useMutation(fetchDeleteUserPositions, {
    onSuccess: () => queryClient.refetchQueries(USER_POSITIONS_KEY)
  })
}
export function useUserPositions(options) {
  return useQuery([USER_POSITIONS_KEY], fetchUserPositions, options)
}
