import React from 'react'
import { Form, Input /*Select*/ } from 'antd'
// import { ConfigContext } from '../../../../../contexts/configContext'

// const { Option } = Select

function PreferredPositionItem() {
  // const { references } = useContext(ConfigContext)

  return (
    <Form.Item
      name="preferredPosition"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Желаемая вакансия"
    >
      <Input size="middle" />
      {/*<Select size="middle">*/}
      {/*  {references.data.positions?.map(position => (*/}
      {/*    <Option key={position} value={position}>*/}
      {/*      {position}*/}
      {/*    </Option>*/}
      {/*  ))}*/}
      {/*</Select>*/}
    </Form.Item>
  )
}

export default PreferredPositionItem
