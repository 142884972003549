import { Table, message, Spin, Checkbox, Button, Row, Col, Modal, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { appConfig } from '../../../../constants/appConfig'
import { useMutateCreateUser, useMutateUser, useUsers } from '../../../../api/users'
import { renderNameWithEmail } from '../../../../helpers/department'
import { useLinkUsersJobSite } from '../../../../api/integrations'
import ModalPhoneNotice from '../../Screens/Integrations/ModalPhoneNotice'
import ModalPhoneForm from '../../Screens/Integrations/ModalPhoneForm'
import { numberDeclension, simpleReducer } from '../../../../helpers'
import { formatPhoneNumberForApi } from '../../../../helpers/phone'
import { modules } from '../../../../constants'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'

const { confirm } = Modal
const { Text } = Typography

const modalTypes = {
  phoneNotice: 'phoneNotice',
  phoneForm: 'phoneForm'
}

const initialState = {
  modalType: null
}
const vacancyModules = Object.values(appConfig.modules)

function IntegrationsUsers({ users, jobSiteData }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState({})
  const [selectedAll, setSelectedAll] = useState('')
  const [state, setState] = useReducer(simpleReducer, initialState)
  const {
    data: dataInternalUsers,
    isLoading: isLoadingInternalUsers,
    isError: isErrorInternalUsers
  } = useUsers()
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  useEffect(() => {
    if (isErrorInternalUsers) {
      message.error('Ошибка получения данных о рекрутерах')
    }
  }, [isErrorInternalUsers])

  const {
    mutate: mutateLinkUsersJobSite,
    isSuccess: isSuccessLinkUsersJobSite,
    isError: isErrorLinkUsersJobSite,
    isLoading: isLoadingLinkUsersJobSite
  } = useLinkUsersJobSite(jobSiteData?.id, {
    enabled: Boolean(jobSiteData?.id)
  })

  useEffect(() => {
    if (isErrorLinkUsersJobSite) {
      message.error('Ошибка привязки пользователей')
    } else if (isSuccessLinkUsersJobSite) {
      message.success('Пользователи успешно привязаны')
    }
  }, [isSuccessLinkUsersJobSite, isErrorLinkUsersJobSite])

  const {
    mutate: updateUser,
    isError: isErrorUpdateUser,
    isLoading: isLoadingUpdateUser
  } = useMutateUser()

  const {
    mutateAsync: saveUser,
    isError: isErrorSave,
    isLoading: isLoadingSave
  } = useMutateCreateUser()

  useEffect(() => {
    if (isErrorSave || isErrorUpdateUser) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isErrorSave, isErrorUpdateUser])

  const linkedList = useMemo(() => {
    const internalUsers = dataInternalUsers?.data?.filter(user =>
      users?.find(extUser => extUser.external.email === user.email)
    )

    return (
      users
        ?.map(user => {
          const internal = internalUsers?.find(
            internalUser =>
              internalUser._id === user.linkedId || internalUser.email === user.external.email
          ) || { name: '-' }
          return {
            ...user,
            internal,
            order:
              !user.linkedId && !internal?.email
                ? 0 //не связан и нет внутреннего пользователя
                : !user.linkedId &&
                  internal.roles?.includes(appConfig.roles.recruiter) &&
                  !internal.agency?._id
                ? 1 //не связан и есть внутренний свободный рекрутер
                : user.linkedId
                ? 2 //связан
                : internal.email &&
                  (!internal.roles.includes(appConfig.roles.recruiter) || internal.agency?._id)
                ? 3 //внутренний пользователь не рекрутер либо привязан к агентству
                : 4 //все остальные которые могут быть
          }
        })
        ?.sort((a, b) => {
          if (a.order === b.order) {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
          } else if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        }) || []
    )
  }, [dataInternalUsers?.data, users])

  const checkAllUserSelected = useCallback(
    list => {
      const notLinked = linkedList?.filter(user => !user.linkedId)
      if (!notLinked.length || notLinked.length !== Object.keys(list).length) return ''

      const count = {}
      vacancyModules.map(module => {
        count[module] = 0
      })
      notLinked.forEach(user => {
        if (list[user.external.id]) {
          count[list[user.external.id]] += 1
        }
      })
      return Object.entries(count).find(([, value]) => value === notLinked.length)?.[0] || ''
    },
    [linkedList]
  )

  const handleSelectUser = useCallback(
    (userId, value) => {
      if (
        selectedRowKeys[userId] &&
        (selectedRowKeys[userId] === value ||
          linkedList.find(u => u.external.id === userId)?.module === value)
      ) {
        delete selectedRowKeys[userId]
      } else if (linkedList.find(u => u.external.id === userId)?.module !== value) {
        selectedRowKeys[userId] = value
      }
      setSelectedRowKeys({ ...selectedRowKeys })
      setSelectedAll(checkAllUserSelected({ ...selectedRowKeys }))
    },
    [checkAllUserSelected, selectedRowKeys, linkedList]
  )

  const handleSelectedAll = useCallback(
    ({ target }) => {
      setSelectedAll(target.checked ? appConfig.modules.crewman : '')
      let rowKeys = []
      if (target.checked) {
        rowKeys = linkedList
          // выделяем только, если пользователи еще не залинкованы
          ?.filter(record => !record.linkedId)
          ?.map(user => [user.external.id, appConfig.modules.crewman])
      }
      setSelectedRowKeys(Object.fromEntries(rowKeys))
    },
    [linkedList]
  )

  const handleBkSelectedAll = useCallback(
    ({ target }) => {
      if (selectedAll === target.value) {
        setSelectedAll('')
        setSelectedRowKeys({})
      } else {
        const notLinked = linkedList
          ?.filter(user => !user.linkedId)
          ?.map(user => [user.external.id, target.value])
        const selectedRows = Object.fromEntries(notLinked)
        setSelectedAll(target.value)
        setSelectedRowKeys(selectedRows)
      }
    },
    [selectedAll, linkedList]
  )

  const isAllLinked = useMemo(() => !linkedList.filter(user => !user.linkedId).length, [linkedList])

  const renderCrewmanAllActions = useCallback(
    () => (
      <Checkbox
        checked={selectedAll}
        className="checkbox-line-height-24"
        onChange={handleSelectedAll}
        disabled={isAllLinked}
      >
        Выбрать все
      </Checkbox>
    ),
    [handleSelectedAll, selectedAll, isAllLinked]
  )

  /**
   * TODO: дублирующееся условие
   (!item.internal?.email ||
   (item.internal?.roles?.includes(appConfig.roles.recruiter) &&
   !item.internal?.agency?._id))
   */
  const renderCrewmanActions = useCallback(
    (linkedId, item) => (
      <>
        {!linkedId &&
          (!item.internal?.email ||
            (item.internal?.roles?.includes(appConfig.roles.recruiter) &&
              !item.internal?.agency?._id)) && (
            <Checkbox
              checked={selectedRowKeys?.[item.external.id]}
              className="checkbox-line-height-24"
              value={appConfig.modules.crewman}
              onChange={({ target }) => handleSelectUser(item.external.id, target?.value)}
            >
              {item.external?.email && item.internal?.email
                ? 'Привязать'
                : item.external?.email && !item.internal?.email
                ? 'Создать пользователя'
                : null}
            </Checkbox>
          )}
        {item.internal?.email &&
        (!item.internal?.roles?.includes(appConfig.roles.recruiter) ||
          item.internal?.agency?._id) ? (
          <Text type="secondary">У пользователя другая роль</Text>
        ) : (
          linkedId && <Text type="secondary">Привязан</Text>
        )}
      </>
    ),
    [handleSelectUser, selectedRowKeys]
  )
  const renderAmpAllActions = useCallback(
    () => (
      <>
        <Row>
          <Text type="secondary">
            Выбрать все <small>(не привязанные)</small>
          </Text>
        </Row>
        <Row>
          <Checkbox.Group
            className="checkbox-line-height-24"
            value={selectedAll}
            disabled={isAllLinked}
          >
            {vacancyModules.map(module => (
              <Checkbox key={module} value={module} onClick={handleBkSelectedAll}>
                {appConfig.modulesLabels[module]}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Row>
      </>
    ),
    [selectedAll, handleBkSelectedAll, isAllLinked]
  )

  const renderAmpActions = useCallback(
    (linkedId, item) => (
      <>
        {!linkedId &&
          (!item.internal?.email ||
            (item.internal?.roles?.includes(appConfig.roles.recruiter) &&
              !item.internal?.agency?._id)) && (
            <Col>
              <Row>
                <Text type="secondary" mark={selectedRowKeys?.[item?.external?.id]}>
                  {item.external?.email && item.internal?.email
                    ? 'Привязать'
                    : item.external?.email && !item.internal?.email
                    ? 'Создать пользователя'
                    : null}
                </Text>
              </Row>
              <Row>
                <Checkbox.Group
                  className="checkbox-line-height-24"
                  value={selectedRowKeys?.[item?.external?.id] || ''}
                  // onChange={({ target }) => handleSelectUser(item.external.id, target?.value)}
                >
                  {vacancyModules.map(module => (
                    <Checkbox
                      // defaultChecked={selectedRowKeys?.[item?.external?.id] === module}
                      // className="checkbox-line-height-24"
                      key={module}
                      value={module}
                      onClick={({ target }) => handleSelectUser(item.external.id, target?.value)}
                    >
                      {appConfig.modulesLabels[module]}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Row>
            </Col>
          )}
        {item.internal?.email &&
        (!item.internal?.roles?.includes(appConfig.roles.recruiter) ||
          item.internal?.agency?._id) ? (
          <Text type="secondary">У пользователя другая роль</Text>
        ) : (
          linkedId && (
            <Col>
              <Row>
                <Text type="secondary" mark={selectedRowKeys?.[item?.external?.id]}>
                  {selectedRowKeys?.[item?.external?.id] ? 'Изменить' : 'Привязан'}
                </Text>
              </Row>
              <Row>
                <Checkbox.Group
                  value={selectedRowKeys?.[item?.external?.id] || item?.module || ''}
                  className="checkbox-line-height-24"
                >
                  {vacancyModules.map(module => (
                    <Checkbox
                      key={module}
                      value={module}
                      onClick={({ target }) => handleSelectUser(item.external.id, target?.value)}
                    >
                      {appConfig.modulesLabels[module]}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Row>
            </Col>
          )
        )}
      </>
    ),
    [handleSelectUser, selectedRowKeys]
  )
  const handleCancel = useCallback(() => {
    setSelectedRowKeys({})
    setSelectedAll('')
  }, [])

  const columns = [
    {
      title: `${jobSiteData?.displayName} пользователи`,
      dataIndex: 'external',
      render: renderNameWithEmail
    },
    {
      title: 'Solvopro пользователи',
      dataIndex: 'internal',
      render: renderNameWithEmail
    },
    {
      title: moduleName === modules.bk ? renderAmpAllActions : renderCrewmanAllActions,
      dataIndex: 'linkedId',
      render: moduleName === modules.bk ? renderAmpActions : renderCrewmanActions
    }
  ]

  const usersWithoutPhone = useMemo(
    () =>
      linkedList.filter(
        user => selectedRowKeys?.[user.external.id] && !(user.external.phone || user.internal.phone)
      ),
    [linkedList, selectedRowKeys]
  )
  const createAndLinkingUsers = useCallback(
    async list => {
      const newUsers = list?.filter(user => !user.internal?._id)
      for (let i = 0; i < newUsers.length; i++) {
        await saveUser({
          ...newUsers[i].external,
          roles: [appConfig.roles.recruiter],
          modules: [newUsers[i].module]
        }).then(({ data }) => {
          const user = list.find(user => user.external.id === newUsers[i].external.id)
          user.internal = data
          user.linkedId = data._id
          user.module = data.modules[0]
        })
      }
      // если поменяли module привязаному пользователю то обновляем пользователя
      const reLinkedUsers = list?.filter(
        user =>
          user.linkedId &&
          !(
            user.internal.modules.length === 1 &&
            user.internal.modules[0] === selectedRowKeys[user.external.id]
          )
      )
      for (let i = 0; i < reLinkedUsers.length; i++) {
        await updateUser({
          id: reLinkedUsers[i]?.internal._id,
          data: {
            modules: [selectedRowKeys[reLinkedUsers[i].external.id]]
          }
        })
      }

      mutateLinkUsersJobSite({
        jobId: jobSiteData?.id,
        data: {
          users: list.map(user => ({
            externalId: user.external.id,
            linkedId: user.linkedId || user.internal._id,
            module: user.module
          }))
        }
      })
      setState({ modalType: null })
      handleCancel()
    },
    [mutateLinkUsersJobSite, saveUser, handleCancel, jobSiteData, updateUser, selectedRowKeys]
  )

  const handleSave = () => {
    if (usersWithoutPhone.length) {
      setState({ modalType: modalTypes.phoneNotice })
    } else {
      confirm({
        title: 'Привязка пользователей',
        content: `Вы собираетесь привязать ${
          Object.keys(selectedRowKeys)?.length
        } ${numberDeclension(Object.keys(selectedRowKeys)?.length, [
          'пользователя',
          'пользователей',
          'пользователей'
        ])}`,
        okText: 'Подтвердить',
        cancelText: 'Отмена',
        onOk: async () => {
          const users = linkedList
            ?.filter(user => selectedRowKeys?.[user.external.id])
            ?.map(user => ({
              ...user,
              module: selectedRowKeys?.[user.external.id]
            }))
          await createAndLinkingUsers(users)
        }
      })
    }
  }

  const handleSaveWithoutPhones = useCallback(async () => {
    const users = linkedList
      ?.filter(user => selectedRowKeys?.[user.external.id] && user.external.phone)
      ?.map(user => ({
        ...user,
        module: selectedRowKeys?.[user.external.id]
      }))
    await createAndLinkingUsers(users)
  }, [createAndLinkingUsers, selectedRowKeys, linkedList])

  const handleCloseModal = useCallback(() => setState({ modalType: null }), [])
  const handleOkPhoneNotice = useCallback(() => setState({ modalType: modalTypes.phoneForm }), [])

  const handleOkPhoneForm = useCallback(
    async values => {
      console.log('phone values', values)
      const users = Object.entries(selectedRowKeys || {}).map(([id, module]) => {
        const user = linkedList.find(u => u.external.id === id)
        if (values[user.external.id]) {
          user.external.phone = formatPhoneNumberForApi(values[user.external.id])
        }
        user.module = module
        return user
      })
      await createAndLinkingUsers(users)
    },
    [createAndLinkingUsers, linkedList, selectedRowKeys]
  )

  return (
    <Spin spinning={isLoadingInternalUsers}>
      <div className="content IntegrationsUsers">
        <Table
          size="middle"
          columns={columns}
          rowKey={item => item.external.id}
          dataSource={linkedList}
          pagination={false}
          scroll={{ x: '100%', y: 'calc(100vh - 360px)' }}
        />
      </div>
      <Row justify="end" className="mt-3">
        <Col>
          <Button
            type="link"
            disabled={isLoadingSave || isLoadingLinkUsersJobSite || isLoadingUpdateUser}
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={!Object.keys(selectedRowKeys).length}
            loading={isLoadingSave || isLoadingLinkUsersJobSite || isLoadingUpdateUser}
          >
            Сохранить
          </Button>
        </Col>
      </Row>
      <ModalPhoneNotice
        isVisible={state.modalType === modalTypes.phoneNotice}
        onClose={handleCloseModal}
        onOk={handleOkPhoneNotice}
        onSave={handleSaveWithoutPhones}
        list={usersWithoutPhone}
        loading={isLoadingSave || isLoadingLinkUsersJobSite || isLoadingUpdateUser}
      />
      <ModalPhoneForm
        isVisible={state.modalType === modalTypes.phoneForm}
        onOk={handleOkPhoneForm}
        onClose={handleCloseModal}
        list={usersWithoutPhone}
        loading={isLoadingSave || isLoadingLinkUsersJobSite || isLoadingUpdateUser}
      />
    </Spin>
  )
}
export default IntegrationsUsers
