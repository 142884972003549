import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { colSettings } from '../../../constants'

function CurrentPositionItem({ label = 'Кем работает', autoComplete = 'on' }) {
  return (
    <Form.Item
      label={label}
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="currentPosition"
    >
      <Input placeholder="Должность" autoComplete={autoComplete} />
    </Form.Item>
  )
}

export default CurrentPositionItem

CurrentPositionItem.propTypes = {
  label: PropTypes.string,
  autoComplete: PropTypes.string
}
