import React from 'react'
import PropTypes from 'prop-types'

const Toolbar = props => {
  const {
    localizer: { messages },
    onNavigate
  } = props

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('TODAY')}>
          {messages.today}
        </button>
        <button type="button" onClick={() => onNavigate('PREV')}>
          {messages.previous}
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          {messages.next}
        </button>
      </span>
    </div>
  )
}

Toolbar.propTypes = {
  localizer: PropTypes.object,
  onNavigate: PropTypes.func
}

export default Toolbar
