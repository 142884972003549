import React from 'react'
import { Col, Form, Row, Input } from 'antd'
import PropTypes from 'prop-types'

const PhoneItem = ({ readOnly }) => {
  const PHONE_VALIDATION_MESSAGE = 'Укажите телефон в формате +70001234567'

  const validatePhone = (rule, value, callback) => {
    const phonePattern = /\+7[0-9]{10}/
    !(value && phonePattern.test(value))
      ? callback(new Error(PHONE_VALIDATION_MESSAGE))
      : callback()
  }

  return (
    <Row>
      <Col span={5}>
        <Form.Item label="Телефон" required={true} />
      </Col>
      <Col span={6}>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: PHONE_VALIDATION_MESSAGE,
              validator: validatePhone
            }
          ]}
        >
          <Input disabled={readOnly} type="phone" placeholder="+70001234567" maxLength={12} />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default PhoneItem

PhoneItem.propTypes = {
  readOnly: PropTypes.bool
}
