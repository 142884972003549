import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Typography, message, Spin, Col, Row, Select, Form } from 'antd'
import './CandidateRejectForm.css'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { selectSearchFilter } from '../../../../helpers'
import { useDepartmentsQuery } from '../../../../api/department'
import { defaultQueryOptions } from '../../../../constants'

const { Title } = Typography
const { Option } = Select

function CandidateSelectDepartmentForAction({ action, onAction, buttonsProps, candidate }) {
  const { departmentType, lowerCaseDepartmentType } = useConfigContextData()

  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    candidate?.application?.department?._id || null
  )

  const {
    data: departmentData,
    isError: isErrorDepartment,
    isLoading: isLoadingDepartment
  } = useDepartmentsQuery(
    {},
    {
      ...defaultQueryOptions
    }
  )
  const departments = useMemo(() => departmentData?.data, [departmentData?.data])

  useEffect(() => {
    if (isErrorDepartment) {
      message.error('Ошибка поиска орг. единиц')
    }
  }, [isErrorDepartment])

  const handleChange = useCallback(id => setSelectedDepartmentId(id), [])

  return (
    <div className="candidateSelectDepartmentForAction">
      <Row>
        <Title level={4} className="title">
          Выберите {lowerCaseDepartmentType}:
        </Title>
      </Row>
      <Row>
        <Col span={16}>
          <Spin spinning={isLoadingDepartment}>
            <Form.Item name="department">
              <Select
                showSearch
                allowClear
                value={selectedDepartmentId}
                placeholder={`${departmentType} для интервью`}
                loading={isLoadingDepartment}
                defaultValue={selectedDepartmentId}
                filterOption={selectSearchFilter}
                optionFilterProp="children"
                onChange={handleChange}
              >
                {departments?.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Spin>
        </Col>
      </Row>
      <Row>
        <div className="btn-wrap mt-3">
          <Button
            {...buttonsProps?.ok}
            onClick={() => onAction(action, { departmentId: selectedDepartmentId })}
            disabled={!selectedDepartmentId}
          >
            Оформить
          </Button>
          <Button {...buttonsProps?.cancel} onClick={() => onAction(null, null)}>
            Отмена
          </Button>
        </div>
      </Row>
    </div>
  )
}

export default CandidateSelectDepartmentForAction
