import React, { useCallback, useMemo } from 'react'
import { Form, Input, Select } from 'antd'
import { colSettings } from '../../../../../constants'
import { useGetUserFields } from '../../../../../api/users'

// const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

function UserFieldsFormItem() {
  const { data: userFields } = useGetUserFields({ retry: 0 })
  const fields = useMemo(() => userFields?.data, [userFields?.data])

  const renderField = useCallback(field => {
    switch (field.controlType) {
      case 'select':
        return (
          <Select
            // size="small"
            placeholder="Выберите значение"
            notFoundContent="Нет вариантов"
            defaultValue={field.defaultValue}
            mode={field.multi ? 'multiple' : ''}
            // onChange={onChange}
            disabled={!field.enable}
            // getPopupContainer={trigger => trigger.parentNode}
          >
            {field.options.map(c => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        )
      case 'textarea':
        return <TextArea rows={4} maxLength={300} placeholder="" />
      default:
        return null
    }
  }, [])
  return (
    <div>
      {/*<Text type="secondary">Пользовательские поля</Text>*/}
      {fields
        ?.filter(item => item.enable)
        ?.map(field =>
          field ? (
            <Form.Item
              key={field._id}
              label={field.displayName}
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name={['userFields', field._id]}
              rules={[
                {
                  required: field.required,
                  message: `Укажите ${field.displayName?.toLowerCase()}`
                }
              ]}
            >
              {renderField(field)}
            </Form.Item>
          ) : null
        )}
    </div>
  )
}

export default UserFieldsFormItem
