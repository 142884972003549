import React from 'react'
import { Form, Select } from 'antd'
import { colSettings } from '../../../../../constants'
import PropTypes from 'prop-types'

const { Option } = Select
function AgencyItem({
  size = 'middle',
  agencies = [],
  labelCol = colSettings.six,
  wrapperCol = colSettings.full
}) {
  return (
    <Form.Item
      label="Агентство"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name="agency"
      labelAlign="left"
    >
      <Select size={size} allowClear>
        {agencies?.map(({ _id, name }) => (
          <Option key={_id} value={_id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}
AgencyItem.propTypes = {
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  agencies: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string, name: PropTypes.string })),
  size: PropTypes.oneOf(['middle', 'small', 'large'])
}
export default AgencyItem
