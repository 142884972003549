import { Col, Row, Typography } from 'antd'
import React from 'react'
import { langCases } from '../constants/appConfig'
import { emptyTitle } from '../constants'
import { formatPhoneNumber } from './phone'

const { Text } = Typography

export const renderRestaurantName = (text, item, priority) => {
  return (
    <Col>
      <Row>
        <Text>{text}</Text>
      </Row>
      <Row>
        <Text type="secondary">{item?.address}</Text>
      </Row>
      {priority && item?.plan?.priority && (
        <Row>
          <Text type="success">Приоритет</Text>
        </Row>
      )}
    </Col>
  )
}
export const renderRestaurantInfo = (text, item, priority) => {
  return (
    <>
      <Row>
        <Text>{text}</Text>
      </Row>
      <Row>
        <Text type="secondary">{item?.address}</Text>
      </Row>
      {item?.hiringManager?.email && (
        <Row className="overflow-hidden">
          <Text type="secondary">
            <a href={`mailto:${item?.hiringManager?.email}`}>{item?.hiringManager?.email}</a>
          </Text>
        </Row>
      )}
      {item?.phone && (
        <Row>
          <Text type="secondary">
            <a href={`tel:${item?.phone}`}>{formatPhoneNumber(item?.phone)}</a>
          </Text>
        </Row>
      )}
      {priority && item?.plan?.priority && (
        <Row>
          <Text type="success">Приоритет</Text>
        </Row>
      )}
    </>
  )
}
export const renderNameWithEmail = text => {
  if (!text) return emptyTitle
  return (
    <Col>
      <Row>
        <Text>{text.name}</Text>
      </Row>
      <Row>
        <Text type="secondary">{text.email}</Text>
      </Row>
    </Col>
  )
}

export const getDepartmentSingularList = (departmentTypes = []) => {
  return departmentTypes.reduce((prev, curr) => {
    prev.push(curr.singular[langCases.nominative])
    return prev
  }, [])
}
