import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { FolderFilled, RightOutlined } from '@ant-design/icons'
import { noOrgStructureName } from '../../../../../constants/orgStructure'

function UnitItem({ unit, onSelect, onArrowClick, currentItem, isLastSelected, isLastLayer }) {
  const handleClick = () => {
    onSelect(unit)
  }

  const handleArrowClick = event => {
    event.preventDefault()
    event.stopPropagation()
    onArrowClick(unit)
  }

  return (
    <Row onClick={handleClick}>
      <Col
        span={22}
        className={`unit ${isLastSelected && 'last'} ${
          unit._id && unit._id === currentItem?._id && 'active'
        }`}
      >
        <FolderFilled />
        {unit.name || noOrgStructureName}
      </Col>
      {!isLastLayer && (
        <Col span={2} onClick={handleArrowClick} className="unit">
          <RightOutlined />
        </Col>
      )}
    </Row>
  )
}

UnitItem.defaultProps = {
  onSelect: () => {},
  onArrowClick: () => {},
  currentItem: null,
  isLastSelected: false,
  isLastLayer: false
}

UnitItem.propTypes = {
  onSelect: PropTypes.func,
  onArrowClick: PropTypes.func,
  currentItem: PropTypes.object,
  isLastSelected: PropTypes.bool,
  isLastLayer: PropTypes.bool
}

export default UnitItem
