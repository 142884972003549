import React from 'react'
import { Checkbox, Col, Form, Row } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'
import { colSettings } from '../../../../../constants'

function PreferredTimeItem() {
  return (
    <Form.Item
      name="preferredTime"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      label="Время работы"
    >
      <Checkbox.Group>
        <Row>
          {appConfig.preferences.times.map(time => (
            <Col key={time}>
              <Checkbox value={time} className="checkbox-line-height-24">
                {time}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

export default PreferredTimeItem
