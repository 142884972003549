import { noOrgStructureName } from '../constants/orgStructure'

export const getOrgUnitsIds = listUnits => {
  return Array.isArray(listUnits)
    ? listUnits?.reduce((prev, cur) => {
        prev.push(cur?._id || cur)
        return prev
      }, [])
    : null
}

export const getSelectedUnitsByIds = (ids, orgStructure) => {
  if (ids?.length && orgStructure) {
    const initUnits = []
    ids.reduce((prev, curId) => {
      const unit = prev?.find(item => item._id === curId)
      unit && initUnits.push(unit)
      return unit?.children
    }, orgStructure?.units || [])
    return initUnits
  }
  return null
}

export const addDepartmentsToBranch = ({ parentUnit, unit, departmentsList, withParent }) => {
  // parentOrganizationUnits - вся цепочка орг единиц(ищется по всем родителям)
  // а сам департамент принадлежит к последней орг единице
  const departments = departmentsList?.filter(
    e => e?.parentOrganizationUnits?.[e?.parentOrganizationUnits?.length - 1] === unit._id
  )
  // если орг единицы нет ни в одном из департаментов то значит ничего на орг единицу не назначено
  if (!departmentsList?.find(e => e?.parentOrganizationUnits?.includes(unit._id))) {
    if (withParent) {
      if (parentUnit) {
        parentUnit.children = parentUnit.children?.filter(item => item._id !== unit._id)
        return
      }
    } else {
      unit.disabled = true
    }
  }
  if (unit.deleted) {
    unit.name = noOrgStructureName
  }
  // обогащаем орг структуру департаментами
  if (departments?.length) {
    unit.children = unit.children.concat(
      departments.map(department => ({
        ...department,
        title: department.name,
        isDepartment: true
      }))
    )
  }
  unit.children
    ?.filter(child => !child.isDepartment)
    ?.forEach(child =>
      addDepartmentsToBranch({
        unit: child,
        departmentsList,
        parentUnit: withParent && unit,
        withParent
      })
    )
  return unit
}
