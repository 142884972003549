import React, { useCallback, useEffect, useMemo } from 'react'
import { Form, message, Col, Row, Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { colSettings } from '../../../../../constants'
import { selectSearchFilter } from '../../../../../helpers'
import { useAgenciesQuery } from '../../../../../api/agency'
import { withFeatureToggle } from '../../../../../components/Common/FeatureToggle'
import { appConfig } from '../../../../../constants/appConfig'
import './AgenciesItemSelector.less'

const { Option } = Select

const AgenciesItemSelector = ({
  form,
  onOpenNewAgency,
  onSelectAgency,
  label = null,
  size = 'large',
  placeholder = 'Выберите агенства',
  labelOneRow = false,
  mode = 'multiple'
}) => {
  const {
    data: agenciesData,
    isError: isErrorAgencies,
    isLoading: isLoadingAgencies
  } = useAgenciesQuery({ locked: false })

  const agencies = useMemo(() => {
    if (onOpenNewAgency) {
      return [
        {
          _id: 'new',
          name: '+ Создать новое агенство'
        },
        ...(agenciesData?.data?.filter(({ locked }) => !locked) || [])
      ]
    } else {
      return agenciesData?.data?.filter(({ locked }) => !locked) || []
    }
  }, [agenciesData?.data, onOpenNewAgency])

  useEffect(() => {
    if (isErrorAgencies) {
      message.error('Ошибка загрузки данных агенств')
    }
  }, [isErrorAgencies])

  const handleSelect = useCallback(
    id => {
      if (id) {
        if (id === 'new') {
          onOpenNewAgency?.()
        } else {
          form?.setFieldsValue?.({ agencies: id })
          onSelectAgency?.(id)
        }
      }
    },
    [form, onSelectAgency, onOpenNewAgency]
  )

  return (
    <>
      {label && <div className="sub-title">{label}</div>}
      <Row gutter={[12, 6]} justify="start" align="middle">
        <Col span={!labelOneRow && 24}>
          <Form.Item
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="agencies"
            noStyle={labelOneRow}
          >
            <Select
              className="manager-selector"
              loading={isLoadingAgencies}
              onChange={handleSelect}
              size={size}
              placeholder={placeholder}
              showSearch
              optionFilterProp="children"
              filterOption={selectSearchFilter}
              allowClear
              mode={mode}
              tagRender={props => (
                <div className="ant-select-selection-overflow-item">
                  <span
                    className={`ant-select-selection-item ${
                      !agencies.find(a => a._id === props.value) && 'disabled'
                    }`}
                  >
                    <span className="ant-select-selection-item-content">
                      <div>{agenciesData?.data?.find(a => props.value === a._id)?.name}</div>
                    </span>
                    <span
                      className="ant-select-selection-item-remove"
                      onMouseDown={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onClick={props.onClose}
                    >
                      <CloseOutlined />
                    </span>
                  </span>
                </div>
              )}
            >
              {agencies?.map(agency => (
                <Option
                  key={agency._id}
                  value={agency._id}
                  onMouseDown={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <div>{agency.name}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
export default withFeatureToggle(AgenciesItemSelector, appConfig.features.agencies)
