import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DatePicker, Form, Input, Row, Select, Tabs, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'
import { differenceInDays, differenceInHours, differenceInMinutes, format } from 'date-fns'

import { appConfig } from '../../../../constants/appConfig'
import { CandidateItem } from '../'
import {
  debounce,
  getFilterDrawerCount,
  selectSearchFilter,
  sortBy,
  groupBy,
  sortByLastHistory
} from '../../../../helpers'
import { hiringManagerSettingsAtom, findSettingsAtom } from '../../../../recoil/atoms'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { filterPeriods } from '../../../../constants/filterPeriods'
import { colSettings, DEFAULT_PERIODS, localStorageName } from '../../../../constants'
import { VacanciesItem } from '../FormItems/CandidateProfileItems'
import { useVacancies } from '../../../../hooks/useVacancies'

import './CandidateGroupMenu.less'
import { parseFilterLocalStorage } from '../../Helpers'

const { Option } = Select
const { RangePicker } = DatePicker
const { Search } = Input
const { Text } = Typography

const periods = DEFAULT_PERIODS
const expiredOptions = [
  { name: 'Да', value: 1 },
  { name: 'Нет', value: 0 },
  { name: 'Все', value: -1 }
]

const CandidateGroupMenu = ({
  groups,
  showProfile,
  workflowName,
  searchKey = 'search',
  searchByButton = false,
  isSearch = false, // компонент используется на странице поиска
  onChange,
  filters,
  initFilters
}) => {
  const {
    /*getWorkflowStateLabelsByName,*/
    getWorkflowStatesWithLabelsByName,
    getStateWithLabelByWorkflowAndName
  } = useConfigContextData()

  const [hiringManagerSettings, setHiringManagerSettings] =
    useRecoilState(hiringManagerSettingsAtom)
  const [findSettings, setFindSettings] = useRecoilState(findSettingsAtom)

  const [settings, setSettings] = useMemo(
    () =>
      isSearch
        ? [findSettings, setFindSettings]
        : [hiringManagerSettings, setHiringManagerSettings],
    [findSettings, setFindSettings, hiringManagerSettings, setHiringManagerSettings, isSearch]
  )

  const [search, setSearch] = useState(settings?.[searchKey] || '')
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(undefined)
  // const [rangeOptions, setRangeOptions] = useState({})
  const [form] = Form.useForm()
  const { vacancies } = useVacancies()

  useEffect(() => {
    if (!isSearch) {
      form.resetFields()
      let data = { ...initFilters, ...parseFilterLocalStorage(localStorageName) }
      if (form.isFieldsTouched()) {
        data = { ...initFilters, ...form.getFieldsValue() }
      }
      const { start, end } = data || periods?.find(period => period.id === data.period)?.value || {}

      // setRangeOptions({ start, end })
      setSelectedPeriod(data.period)

      form.setFieldsValue({ ...data, range: [dayjs(start), dayjs(end)] })
    }
  }, [form, initFilters, isSearch])

  const handleChangeFilter = useCallback(
    options => {
      form.setFieldsValue(options)
      onChange(options)
    },
    [onChange, form]
  )
  const handleResetAll = useCallback(() => {
    const data = {
      start: undefined,
      end: undefined,
      period: undefined,
      expired: undefined,
      vacancies: undefined
    }
    handleChangeFilter(data)
    setSelectedPeriod(undefined)
    setIsFiltersOpen(false)
  }, [handleChangeFilter])

  // const getData = useCallback(() => {
  //   const { range, ...restValues } = form.getFieldsValue()
  //   let [start, end] = range || [rangeOptions.start, rangeOptions.end]
  //   if (!start || !end) {
  //     start = filters.start
  //     end = filters.end
  //   }
  //   return {
  //     ...restValues,
  //     start,
  //     end
  //   }
  // }, [filters, rangeOptions, form])

  const handleSelectExpired = useCallback(
    value => {
      handleChangeFilter({ expired: value })
    },
    [handleChangeFilter]
  )

  const handleChangeVacancies = useCallback(
    value => {
      handleChangeFilter({ vacancies: value?.length ? value : undefined })
    },
    [handleChangeFilter]
  )

  const handleSelectPeriod = useCallback(
    periodId => {
      const data = { period: periodId }
      if (periodId !== filterPeriods.custom.id) {
        const { start, end } = periods?.find(period => period.id === periodId)?.value || {}
        // setRangeOptions({ start, end })
        form.setFieldsValue({ range: [dayjs(start), dayjs(end)] })
        data.start = start
        data.end = end
      }
      setSelectedPeriod(periodId)
      handleChangeFilter(data)
    },
    [form, handleChangeFilter]
  )

  const handleChangeRange = useCallback(
    (data, clearData) => {
      const [start, end] = data || clearData
      setSelectedPeriod(filterPeriods.custom.id)
      form.setFieldsValue({ period: filterPeriods.custom.id })
      // setRangeOptions({ start, end })
      handleChangeFilter({ start, end, period: filterPeriods.custom.id })
    },
    [form, handleChangeFilter]
  )

  const setContextSearch = useCallback(
    value =>
      setSettings(oldValues => ({
        ...(oldValues || {}),
        [searchKey]: value
      })),
    [searchKey, setSettings]
  )

  const handleSearch = useCallback(
    value => setContextSearch(value), //value.replace(/\D/g, '')
    [setContextSearch]
  )

  const debounceSearch = useMemo(
    () =>
      debounce(value => {
        setContextSearch(value)
      }, 800),
    [setContextSearch]
  )

  const handleChangeSearch = useCallback(
    ({ target }) => {
      setSearch(target.value)
      !searchByButton && debounceSearch(target.value) //target.value.replace(/\D/g, '')
    },
    [searchByButton, debounceSearch]
  )

  const groupsState = useMemo(
    () => groupBy(groups?.sort(sortByLastHistory()), 'state.displayName'),
    [groups]
  )
  // const getStatusLabel = useCallback(
  //   status =>
  //     isSearch
  //       ? getWorkflowStateLabelsByName(status?.key)
  //       : getStateWithLabelByWorkflowAndName({
  //           stateKey: status?.key,
  //           workFlowName: isAmpUser
  //             ? appConfig.workflows.amp.name
  //             : appConfig.workflows.candidate.name
  //         })?.label,
  //   [getWorkflowStateLabelsByName, getStateWithLabelByWorkflowAndName, isSearch, isAmpUser]
  // )
  const existApplicationStatusList = useMemo(
    () =>
      getWorkflowStatesWithLabelsByName(workflowName)
        ?.filter(item => groupsState[item.displayName]?.length > 0)
        ?.sort(sortBy('sortOrder')),
    [groupsState, workflowName, getWorkflowStatesWithLabelsByName]
  )

  const activeTab = useMemo(
    () =>
      existApplicationStatusList?.find(status => status.displayName === settings?.activeTab)
        ?.displayName || existApplicationStatusList[0]?.displayName,
    [existApplicationStatusList, settings]
  )

  // @TODO использовать из хелпера getStringDiffDates
  const getDueDateRes = useCallback(groupItem => {
    let dueDateRes = {}
    const currentState = groupItem?.history[groupItem?.history?.length - 1]
    if (currentState) {
      const now = new Date()
      const departmentDate = currentState.expireAt ? new Date(currentState.expireAt) : new Date()
      const days = differenceInDays(departmentDate, now)
      const hours = differenceInHours(departmentDate, now)
      let minutes = differenceInMinutes(departmentDate, now)

      if (hours < 24) {
        minutes = minutes - 60 * hours
      }

      dueDateRes = { msg: '', style: 'green' }

      if (days >= 1) {
        dueDateRes.msg = 'Истекает ' + format(departmentDate, appConfig.formats.shortDateFns)
      } else if (days < 1 && days >= 0 && hours < 24 && minutes > 0) {
        dueDateRes.msg = 'Истекает через ' + (hours ? hours + ' ч. ' : '') + minutes + ' мин.'
      } else if (days <= 0 && (hours < 0 || minutes < 0)) {
        dueDateRes.style = 'red'
        if (days < 0) {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(days) + ' д.'
        } else {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(hours) + ' ч. ' + Math.abs(minutes) + ' мин.'
        }
      }
    }
    return dueDateRes
  }, [])

  const getStatusWthDate = useCallback(
    (status, candidate) => {
      if (
        // candidate?.state?.name === appConfig.statuses.values.interviewScheduled &&
        candidate?.state?.interview
      ) {
        const statusName =
          getStateWithLabelByWorkflowAndName({
            stateKey: status?.name,
            workFlowName: candidate?.vacancy?.workflow?.name
          })?.label || status?.name
        const departmentDate = dayjs(candidate.state.interview.start)
          .utcOffset(candidate.state.interview?.timeOffset || 0)
          .format(appConfig.formats.dateAndTime)
        return `${statusName} ${departmentDate}`
      }
      return ''
    },
    [getStateWithLabelByWorkflowAndName]
  )

  const handleTabsChange = useCallback(
    value =>
      setSettings(oldValues => ({
        ...(oldValues || {}),
        activeTab: value
      })),
    [setSettings]
  )

  const tabsItems = useMemo(
    () =>
      existApplicationStatusList?.map(status => {
        const count = groupsState[status.displayName]?.length || 0
        const groupList = groupsState[status.displayName] || []
        return {
          key: status.displayName,
          label: `${status.displayName} (${count})`,
          children: (
            <>
              {groupList?.map(groupItem => (
                <CandidateItem
                  key={groupItem._id}
                  candidate={groupItem}
                  dueDate={getDueDateRes(groupItem)}
                  showProfile={showProfile}
                  status={getStatusWthDate(status, groupItem)}
                  interview={groupItem.state.interview}
                  interviewerDepartment={groupItem.application?.interviewerDepartment}
                  department={groupItem.application?.department}
                  isSearch={isSearch}
                />
              ))}
            </>
          )
        }
      }),
    [
      existApplicationStatusList,
      getDueDateRes,
      getStatusWthDate,
      groupsState,
      isSearch,
      showProfile
    ]
  )

  const tabsList = useMemo(() => {
    return tabsItems?.length ? (
      <Tabs
        className="custom-tabs"
        onChange={handleTabsChange}
        activeKey={activeTab}
        items={tabsItems}
      />
    ) : null
  }, [activeTab, handleTabsChange, tabsItems])

  return (
    <>
      <Row align="top" justify="space-between" className="btn-container">
        <Col span={16}>
          <Search
            type="tel"
            name="phone"
            size="large"
            placeholder="Поиск по телефону или ФИО"
            value={search}
            onSearch={handleSearch}
            onChange={handleChangeSearch}
            className="searchInput find-list"
            enterButton={
              <Button type="primary" disabled={!search} icon={<SearchOutlined />} size="small" />
            }
            allowClear
          />
          {search && (
            <Row className="search-hint">
              <Text type="secondary">
                <small>
                  введите полный номер телефона или ФИО чтобы получить более точные результаты
                </small>
              </Text>
            </Row>
          )}
        </Col>
        {!isSearch && (
          <Col className={`filter-buttons ${isFiltersOpen && 'opened'}`}>
            <Badge count={getFilterDrawerCount(filters)}>
              <Button size="large" type="primary" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
                Фильтры
              </Button>
            </Badge>
            <Button type="link" onClick={handleResetAll}>
              Сбросить
            </Button>
          </Col>
        )}
      </Row>

      {!isSearch && isFiltersOpen && (
        <Form form={form} className="filters-container">
          <Row gutter={[12, 6]} justify="start" align="top">
            <Col span={8}>
              <Row gutter={[12, 6]} justify="start">
                {/*<Col>Период</Col>*/}
                <Col span={24}>
                  <Form.Item name="period" label="Период" labelCol={colSettings.full}>
                    <Select
                      size="large"
                      placeholder="Период"
                      showSearch
                      optionFilterProp="children"
                      filterOption={selectSearchFilter}
                      onSelect={handleSelectPeriod}
                    >
                      {periods?.map(period => (
                        <Option key={period.id} value={period.id}>
                          {period.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {selectedPeriod === filterPeriods.custom.id && (
                <Row gutter={[12, 6]} justify="start">
                  {/*<Col>Точные даты</Col>*/}
                  <Col span={24}>
                    <Form.Item name="range" label="Точные даты" labelCol={colSettings.full}>
                      <RangePicker
                        size="large"
                        placeholder={['Начало', 'Конец']}
                        onChange={handleChangeRange}
                        allowClear={false} /* default value (false) sometime not works */
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
            <Col span={8}>
              <VacanciesItem
                onChange={handleChangeVacancies}
                vacancyList={vacancies}
                size="large"
                mode="multiple"
                required={false}
                name="vacancies"
                allowClear
              />
            </Col>
            <Col span={8}>
              <Form.Item name="expired" label="Просрочено" labelCol={colSettings.full}>
                <Select
                  size="large"
                  placeholder="Просрочено"
                  // showSearch
                  optionFilterProp="children"
                  filterOption={selectSearchFilter}
                  onSelect={handleSelectExpired}
                >
                  {expiredOptions?.map(item => (
                    <Option key={item.name} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}

      {tabsList}
    </>
  )
}

export default CandidateGroupMenu
