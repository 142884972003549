import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { format } from 'date-fns'
import { Col, message, Row, Typography } from 'antd'

import { CandidatePanelContext } from '../../../../contexts/candidatePanelContext'
import { initialPagination, simpleReducer } from '../../../../helpers'
import { appConfig } from '../../../../constants/appConfig'
import { nbsp } from '../../../../constants'
import { useQueryFindMessages } from '../../../../api/chatBot'

const { Text } = Typography
function Resume() {
  const { candidate } = useContext(CandidatePanelContext)
  const initialState = {
    pagination: initialPagination
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isError: isErrorFindMessages,
    isSuccess: isSuccessFindMessages,
    data: dataMessages
  } = useQueryFindMessages(
    {
      candidate: candidate._id,
      pagination: { limit: state.pagination?.pageSize, page: state.pagination?.current }
    },
    { enabled: !!candidate._id }
  )

  useEffect(() => {
    if (isSuccessFindMessages) {
      setState({
        pagination: {
          current: dataMessages?.data?.page,
          total: dataMessages?.data?.totalDocs,
          pageSize: dataMessages?.data?.limit
        }
      })
    } else if (isErrorFindMessages) {
      message.error('Ошибка получения переписки с ботом')
    }
  }, [dataMessages, isSuccessFindMessages, isErrorFindMessages])

  const messages = useMemo(
    () => dataMessages?.data?.docs || dataMessages?.data || [],
    [dataMessages?.data]
  )

  const renderItem = useCallback(item => {
    return (
      <Row key={item._id} className="mb-3" align="top" gutter={[8, 4]}>
        <Col className="fs-26 bot-icon" span={1}>
          {item?.direction === appConfig.chat.direction.outgoing ? (
            <span>&#129302;</span>
          ) : (
            <span>&#128104;</span>
          )}
        </Col>
        <Col span={22}>
          {item.received && (
            <Text type="secondary">
              <small>
                {format(new Date(item.received), appConfig.formats.shortDateAndTimeFns)},{nbsp}
                {nbsp}
                {nbsp}
                {item.state}
              </small>
            </Text>
          )}
          <div className="description">{item.text}</div>
        </Col>
      </Row>
    )
  }, [])

  return <div className="chat-bot">{messages?.map(renderItem)}</div>
}

export default Resume
