import React, { Suspense, useEffect } from 'react'
import { useLoaderData, useOutlet } from 'react-router-dom'
import { Spin } from 'antd'
import { useProfile } from '../hooks/useProfile'

export const AuthLayout = () => {
  const userPromise = useLoaderData()
  const outlet = useOutlet()
  const { login, isUserLoaded } = useProfile()

  useEffect(() => {
    login(userPromise?.data)
  }, [userPromise?.data, login])

  return (
    <Suspense fallback={<Spin size="large" className="full-screen-spin" />}>
      {isUserLoaded && outlet}
    </Suspense>
  )
}
