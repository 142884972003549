import { useQuery } from 'react-query'
import { api } from './api'

export const PLAN_KEY = 'plan'
export const PLANS_KEY = 'plans'

const fetchGetPlan = ({ queryKey }) => {
  const [, /* key */ period] = queryKey
  return api.get({ path: `plan/${period}` })
}

const fetchGetPlanById = ({ queryKey }) => {
  const [, /* key */ id] = queryKey
  return api.get({ path: `plans/${id}` })
}

export function useGetPlanById(id, options) {
  return useQuery([PLAN_KEY, id], fetchGetPlanById, options)
}

export function useGetPlan(period, options) {
  return useQuery([PLAN_KEY, period], fetchGetPlan, options)
}

const fetchPostPlanFind = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  return api.post({ path: 'plans/find', data })
}

export function usePlanFindQuery(data) {
  return useQuery([PLANS_KEY, data], fetchPostPlanFind)
}
