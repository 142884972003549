import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Tooltip, Typography } from 'antd'
import { useMutateProfile } from '../../../../api/users'
import { strongPasswordMessage, testPassword, validatePass } from '../../../../helpers'
import { colSettings } from '../../../../constants'

const modes = { hide: 'hide', edit: 'edit' }
const { Link } = Typography

function PasswordChange() {
  const [form] = Form.useForm()
  const [mode, setMode] = useState(modes.hide)
  const [password, setPassword] = useState('')
  const { mutate: savePassword, isSuccess, isError, isLoading } = useMutateProfile()

  useEffect(() => {
    if (isSuccess) {
      message.info('Новый пароль сохранен')
      setMode(modes.hide)
    } else if (isError) {
      message.error('Ошибка сохранения пароля')
    }
  }, [isSuccess, isError])

  const handleModeEdit = () => {
    setMode(modes.edit)
  }

  const handleSavePassword = async values => {
    await savePassword({
      password: values.password
    })
  }

  const validator = validatePass(form)
  const strongPassword = useMemo(() => testPassword(password), [password])

  return (
    <div>
      {mode === modes.hide ? (
        <Link onClick={handleModeEdit}>Изменить пароль</Link>
      ) : (
        <Form form={form} onFinish={handleSavePassword}>
          <Row gutter={24} align="middle">
            <Col span={8}>
              <Tooltip open={!strongPassword.isStrong && password} title={strongPasswordMessage}>
                <Form.Item
                  name="password"
                  label="Новый пароль"
                  labelCol={colSettings.full}
                  wrapperCol={colSettings.full}
                  rules={[
                    {
                      required: true,
                      validator
                    }
                  ]}
                >
                  <Input.Password onChange={({ target }) => setPassword(target?.value)} />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Form.Item
                name="repeatPassword"
                label="Подтвердить пароль"
                labelCol={colSettings.full}
                wrapperCol={colSettings.full}
                rules={[
                  {
                    required: true,
                    validator
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={5} className="mt-3">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  )
}

export default PasswordChange
