import React from 'react'
import { Col, Form, Row, Input, DatePicker } from 'antd'
import { appConfig } from '../../../constants/appConfig'
import { getAge } from '../../../helpers/time'
import { colSettings } from '../../../constants'

const BirthAndAgeItem = ({
  form,
  birthdayColSpan = 16,
  ageColSpan = 8,
  onBirthdayChange,
  required = true
}) => {
  const handleBirthdayChange = birthday => {
    if (birthday) {
      form.setFieldsValue({
        age: getAge(birthday)
      })
      onBirthdayChange?.(birthday)
    } else {
      form.setFieldsValue({
        age: null
      })
    }
  }

  const reformatDate = event => {
    let separator = '.'
    let strippedInput = event.target.value.replace(/\D/g, '')
    if (
      (event.target.value.length === 2 || event.target.value.length === 5) &&
      event.key === 'Backspace'
    ) {
      strippedInput = strippedInput.slice(0, -1)
    }
    let newInput = ''
    for (let i = 0; i < strippedInput.length; i += 1) {
      if (i === 2 || i === 4) newInput += separator
      newInput += strippedInput.charAt(i)
    }
    if (strippedInput.length === 2 || strippedInput.length === 4) newInput += separator
    event.target.value = newInput
  }

  React.useEffect(() => {
    const input = document.getElementById('birthday')
    if (input) {
      input.addEventListener('keyup', reformatDate)
      input.maxLength = 10
    }
    return () => {
      input?.removeEventListener('keyup', reformatDate)
    }
  }, [])

  return (
    <Row>
      <Col span={birthdayColSpan}>
        <Form.Item
          label="Дата рождения"
          labelCol={colSettings.full}
          wrapperCol={{ span: 22 }}
          name="birthday"
          rules={[
            {
              required,
              message: 'Укажите дату рождения'
            }
          ]}
        >
          <DatePicker
            size="middle"
            placeholder="дд.мм.гггг"
            format={appConfig.formats.shortDate}
            onChange={handleBirthdayChange}
            getPopupContainer={trigger => trigger.parentNode}
          />
        </Form.Item>
      </Col>
      <Col span={ageColSpan}>
        <Form.Item
          label="Возраст"
          name="age"
          labelCol={colSettings.full}
          wrapperCol={colSettings.full}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default BirthAndAgeItem
