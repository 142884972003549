import React, { useContext } from 'react'

import { UserContext } from '../../../../contexts/userContext'
import { getListOfNamesSeparatedByCommas } from '../../../../helpers'

const CandidateItem = ({
  interview,
  interviewerDepartment,
  candidate,
  showProfile,
  status,
  dueDate = null,
  department,
  isSearch = false // компонент используется на странице поиска
}) => {
  // const avaStyle = props.candidate?.profile?.photoUrl
  //   ? ({ background: `url("${props.candidate.profile.photoUrl}")` })
  //   : {}

  const { user, isHiringManager } = useContext(UserContext)
  const isDelegatingInterview =
    interviewerDepartment?._id && department?._id && interviewerDepartment?._id !== department?._id
  const isEmployerDepartment =
    isHiringManager &&
    interviewerDepartment?._id &&
    interviewerDepartment?._id !== user.department?._id

  return (
    <div key={candidate._id} className="candidates-item" onClick={() => showProfile(candidate)}>
      {/* <div className='ava' style={avaStyle}/> */}
      <div className="info">
        <div className="name">{candidate.name}</div>
        {status && <div className="status">{status}</div>}
        <div className="status">{candidate.vacancy?.displayName}</div>
        {dueDate?.msg && dueDate?.style && (
          <div className={`due_date ${dueDate.style}`}>{dueDate.msg}</div>
        )}
        {interview?._doc?.attendees?.length && (
          <div>Участники: {getListOfNamesSeparatedByCommas(interview?._doc?.attendees)}</div>
        )}
        {isDelegatingInterview &&
          !isSearch &&
          (isEmployerDepartment ? (
            <div className="status">
              Кандидат будет собеседоваться в: <b>{interviewerDepartment?.name}</b>
            </div>
          ) : (
            <div className="status">
              Кандидат будет работать в: <b>{department?.name}</b>
            </div>
          ))}
      </div>
    </div>
  )
}

export default CandidateItem
