import React, { lazy } from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useSetRecoilState } from 'recoil'

import { ProtectedLayout } from './components/ProtectedLayout'
import { HomeLayout } from './components/HomeLayout'
import { UserLayout } from './components/UserLayout'
import { AuthLayout } from './components/AuthLayout'
import { RequiredDataLayout } from './components/RequiredDataLayout'
import { Theme } from './components/Theme'
import { profileLoader } from './api/users'
import { settingsLoader } from './api/setting'
import { workflowsLoader } from './api/workflows'
import { featuresLoader } from './api/features'
import { AppointmentProvider } from './hooks/useAppointment'
import MassRecruitment from './modules/massRecruitment/MassRecruitment'
import Auth from './modules/massRecruitment/Screens/Auth/Auth'
import {
  EXTERNAL_BUTTON,
  EXTERNAL_BUTTON_CANDIDATE_PAGE,
  FORGOT_PASSWORD,
  NO_AUTH_CANDIDATE_PAGE,
  PASSWORD_RECOVERY
} from './constants/routes'
import { ExternalCandidateEdit, ExternalMain, ExternalAuthError } from './modules/ExternalButton'
import { ForgotPassword, PasswordRecovery } from './modules/massRecruitment/Screens'
import { ErrorLayout } from './modules/massRecruitment/Screens/ErrorLayout/ErrorLayout'
import { customPropertiesSettingsAtom } from './recoil/atoms'
// import { modules } from './constants'
const NoAuthCandidate = lazy(() => import('./modules/NoAuthCandidate'))

const requiredDataLoader = async () => {
  const settings = settingsLoader()
  const workflows = workflowsLoader()
  const features = featuresLoader()
  return Promise.all([settings, workflows, features]).then(([settings, workflows, features]) => {
    // console.log('%c 123456789', 'color: green; font-weight: bold; font-size: 32px', {
    //   settings,
    //   workflows,
    //   features
    // })
    // if (settings?.status === 200) {
    //   initLocale(settings?.data?.[appConfig.settings.language])
    //   window.__localeId__ = settings?.data?.[appConfig.settings.language]
    // } else {
    //   message.error('Ошибка получения настроек')
    // }
    // if (workflows?.status !== 200) {
    //   message.error('Ошибка получения процессов найма')
    // }
    // if (features?.status !== 200) {
    //   message.error('Ошибка получения списка фич')
    // }
    return { settings: settings?.data, workflows: workflows?.data, features: features?.data }
  })
}
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(
  // const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<UserLayout />}>
      <Route path={PASSWORD_RECOVERY} element={<PasswordRecovery />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={NO_AUTH_CANDIDATE_PAGE} element={<NoAuthCandidate />} />

      <Route
        element={<RequiredDataLayout />}
        loader={requiredDataLoader}
        errorElement={<ExternalAuthError />}
      >
        <Route path={EXTERNAL_BUTTON} element={<ExternalMain />} />
        <Route path={EXTERNAL_BUTTON_CANDIDATE_PAGE} element={<ExternalCandidateEdit />} />
      </Route>

      <Route
        element={<AuthLayout />}
        loader={profileLoader}
        errorElement={
          <HomeLayout>
            <Auth />
          </HomeLayout>
        }
      >
        <Route element={<RequiredDataLayout />} loader={requiredDataLoader}>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Auth />} />
          </Route>
          <Route element={<AppointmentProvider />}>
            <Route element={<ProtectedLayout />} errorElement={<ErrorLayout />}>
              <Route path="/*" element={<MassRecruitment />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
)
export default function App({ customProperties }) {
  const setCustomProperties = useSetRecoilState(customPropertiesSettingsAtom)

  setCustomProperties(oldValues => ({
    ...(oldValues || {}),
    ...(customProperties || {})
    // menuTheme: 'dark', // для тестирования Бургера
    // moduleName: modules.bk // для тестирования Бургера
  }))

  return (
    <Theme>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Theme>
  )
}
