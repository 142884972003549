import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useReducer,
  Children,
  cloneElement,
  useContext
} from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Table, Spin, ConfigProvider, Typography, message, Row, Col } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { HolderOutlined } from '@ant-design/icons'
// import Highlighter from 'react-highlight-words'

import { WORKFLOW_STATE_PAGE } from '../../../../constants/routes'
import { /*emptyFilter, modules,*/ textTrue } from '../../../../constants'
import { getItemStyle, getListStyle, reorder } from '../../Helpers/dragndrop'

// import { SearchFilter } from '../index'
import {
  simpleReducer,
  initialPagination,
  upperFirst,
  numberDeclension,
  sortBy,
  areArraysIntersect
} from '../../../../helpers'

import { appConfig } from '../../../../constants/appConfig'
// import { ConfigContext } from '../../../../contexts/configContext'
import {
  workflowFiltersAtom,
  workflowSettingsAtom,
  workflowSorterAtom
} from '../../../../recoil/atoms'
import { workflowSettingsSelector } from '../../../../recoil/selectors'
import { usersTagList } from '../../../../helpers/user'
import { useMutateUpdateWorkflow } from '../../../../api/workflows'
import { ConfigContext } from '../../../../contexts/configContext'

const { Text } = Typography

function WorkflowStatesList({ workflow }) {
  const navigate = useNavigate()
  const tableRef = useRef(null)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  // Скрыто - нужно для фильтрации и сортировки
  // const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  const setWorkflowSettings = useSetRecoilState(workflowSettingsAtom)
  const workflowSettings = useRecoilValue(workflowSettingsSelector)
  const setWorkflowFilters = useSetRecoilState(workflowFiltersAtom)
  const setWorkflowSorter = useSetRecoilState(workflowSorterAtom)

  const initialState = {
    pagination: initialPagination,
    workflowStates: []
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    const states = Object.entries(workflow.states || {})
      .map(([name, value]) => ({
        ...value,
        name
      }))
      .filter(state => (state.features ? areArraysIntersect(state.features, features) : true))
    // фильтруем если выбран фильтр
    if (workflowSettings?.filters?.showFor) {
      states.filter(s => {
        let needReturn = false
        workflowSettings?.filters?.showFor.forEach(role => {
          if (role) {
            if (s.showFor?.includes(role)) {
              needReturn = true
            }
          } else if (!s.showFor || !s.showFor?.length) {
            needReturn = true
          }
        })
        return needReturn
      })
    }
    setState({ workflowStates: states.sort(sortBy('sortOrder')) })
  }, [workflow, workflowSettings?.filters?.showFor, features])

  const {
    mutate: mutateUpdateWorkflow,
    isLoading: isLoadingUpdateWorkflow,
    isSuccess: isSuccessUpdateWorkflow,
    isError: isErrorUpdateWorkflow
  } = useMutateUpdateWorkflow()

  useEffect(() => {
    if (isSuccessUpdateWorkflow) {
      message.success('Процесс успешно обновлен!')
    }
    if (isErrorUpdateWorkflow) {
      message.error('Ошибка обновления данных процесса')
    }
  }, [isSuccessUpdateWorkflow, isErrorUpdateWorkflow])

  // Скрыто - нужно для фильтрации и сортировки
  // const roles = useMemo(() => {
  //   const commonRoles = [appConfig.roles.hiringManager, appConfig.roles.recruiter]
  //   const bkRoles = [appConfig.roles.partner, appConfig.roles.TU, appConfig.roles.operationDirector]
  //   return moduleName !== modules.bk ? commonRoles : [...commonRoles, ...bkRoles]
  // }, [moduleName])

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      const items = reorder(
        state.workflowStates,
        result.source.index,
        result.destination.index
      ).map((item, index) => {
        item.sortOrder = index + 1
        return item
      })

      const workflowClone = JSON.parse(JSON.stringify(workflow))
      const notFeatureStates = Object.entries(workflow.states || {})
        .map(([name, value]) => ({
          ...value,
          name
        }))
        .filter(state => (state.features ? !areArraysIntersect(state.features, features) : false))

      workflowClone.states = [...items, ...notFeatureStates].reduce(
        (prev, cur) => ({ ...prev, [cur.name]: cur }),
        {}
      )
      mutateUpdateWorkflow(workflowClone)
    },
    [state.workflowStates, mutateUpdateWorkflow, workflow, features]
  )

  const DraggableBodyRow = useCallback(
    ({ index, className, style, ...restProps }) => (
      <Draggable key={restProps.name} draggableId={restProps.name} index={index}>
        {(provided, snapshot) => {
          if (snapshot?.isDragging) {
            // библиотека "react-beautiful-dnd"/"@hello-pangea/dnd" добавляет к перетаскиваемому элементу "position: fixed"
            // для сохранения размеров ячеек перетаскиваемой строки таблицы необходимо задать каждой ячейке "width"
            const headerWidth = [
              ...(tableRef?.current?.querySelectorAll('thead .ant-table-cell') || [])
            ].map(e => e?.clientWidth)

            return (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`${className} draggable-item`}
                style={{
                  ...style,
                  ...getItemStyle(snapshot?.isDragging, provided.draggableProps.style),
                  padding: 0,
                  margin: 0
                }}
              >
                {Children.map(restProps.children, (child, index) => (
                  <td width={headerWidth[index]}>{cloneElement(child)}</td>
                ))}
              </tr>
            )
          }

          return (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`${className} draggable-item`}
              style={{
                ...style,
                ...getItemStyle(snapshot?.isDragging, provided.draggableProps.style)
              }}
              {...restProps}
            />
          )
        }}
      </Draggable>
    ),
    []
  )

  const DraggableBodyWrapper = useCallback(
    ({ className, ...restProps }) => (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot?.isDraggingOver)}
              className={`${className} droppable-list`}
            >
              {restProps.children}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    ),
    [onDragEnd]
  )

  const components = useMemo(
    () => ({
      body: {
        wrapper: DraggableBodyWrapper,
        row: DraggableBodyRow
      }
    }),
    [DraggableBodyRow, DraggableBodyWrapper]
  )

  const columns = [
    {
      title: '',
      dataIndex: 'dnd',
      width: 20,
      render: () => <HolderOutlined />
    },
    {
      title: 'Название статуса',
      dataIndex: 'displayName',
      width: 250,
      render: (text, item) => <Text type={item.showInDashboards && 'success'}>{text}</Text>
      // filteredValue: workflowSettings?.filters?.displayName || null,
      // ...SearchFilter('displayName', 'по статусу', ({ isInclude, text, searchText, item }) => {
      //   return (
      //     <Text type={item.happy && 'success'}>
      //       {isInclude ? (
      //         <Highlighter
      //           highlightStyle={{
      //             backgroundColor: '#ffc069',
      //             padding: 0
      //           }}
      //           searchWords={[searchText]}
      //           autoEscape
      //           textToHighlight={text ? text?.toString() : ''}
      //         />
      //       ) : (
      //         text
      //       )}
      //     </Text>
      //   )
      // }),
      // sortOrder:
      //   workflowSettings?.sorter?.field === 'displayName' && workflowSettings?.sorter?.order,
      // sorter: (a, b) => (a?.displayName > b?.displayName ? 1 : -1)
    },
    {
      title: 'SLA',
      dataIndex: 'sla',
      width: 200,
      render: sla => {
        if (sla?.date) {
          const dayStrings = ['день', 'дня', 'дней']
          const hourStrings = ['час', 'часа', 'часов']
          const minuteStrings = ['минута', 'минуты', 'минут']
          let days = 0
          let hours = 0
          let minutes = 0
          if (sla?.duration?.days)
            days = `${sla.duration.days} ${numberDeclension(sla.duration.days, dayStrings)}`
          if (sla?.duration?.hours)
            hours = `${sla.duration.hours} ${numberDeclension(sla.duration.hours, hourStrings)}`
          if (sla?.duration?.minutes)
            minutes = `${sla.duration.minutes} ${numberDeclension(
              sla.duration.minutes,
              minuteStrings
            )}`

          if (!(days || hours || minutes)) return ''
          return (
            <Col>
              <Row>
                {days ? days : ''} {hours ? hours : ''} {minutes ? minutes : ''}
              </Row>
              <Row>
                {appConfig.workflows.sla.find(w => w.key === sla?.date)?.name?.toLowerCase()}
              </Row>
            </Col>
          )
        }
      }
      // sortOrder: workflowSettings?.sorter?.field === 'sla' && workflowSettings?.sorter.order,
      // sorter: (a, b) => {
      //   if (!(a?.sla?.duration?.days || a?.sla?.duration?.hours || a?.sla?.duration?.minutes))
      //     return 1
      //   if (a?.sla?.duration?.days || b?.sla?.duration?.days)
      //     return a?.sla?.duration?.days > b?.sla?.duration?.days ? 1 : -1
      //   if (a?.sla?.duration?.hours || b?.sla?.duration?.hours)
      //     return a?.sla?.duration?.hours > b?.sla?.duration?.hours ? 1 : -1
      //   if (a?.sla?.duration?.minutes || b?.sla?.duration?.minutes)
      //     return a?.sla?.duration?.minutes > b?.sla?.duration?.minutes ? 1 : -1
      //   return a?.sla?.date > b?.sla?.date ? 1 : -1
      // }
    },
    {
      title: 'Переиспользование',
      dataIndex: 'reuseWhenExpired',
      width: 200,
      render: text => (text ? upperFirst(textTrue) : '-')
      // sortOrder:
      //   workflowSettings?.sorter?.field === 'reuseWhenExpired' && workflowSettings?.sorter.order,
      // sorter: (a, b) => {
      //   if (a?.reuseWhenExpired !== 'boolean') return 1
      //   return a?.reuseWhenExpired > b?.reuseWhenExpired ? 1 : -1
      // }
    },
    {
      title: 'Видимость',
      dataIndex: 'showFor',
      width: 150,
      render: roles => usersTagList({ list: roles })
      // filteredValue: workflowSettings?.filters?.showFor || null,
      // filters: [
      //   emptyFilter,
      //   ...(roles?.map(r => ({
      //     text: appConfig.rolesLabels[r],
      //     value: r
      //   })) || [])
      // ]
    }
  ]

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const { field, order } = sorter
      setState({
        pagination
      })
      setWorkflowSettings(oldValues => ({
        ...(oldValues || {}),
        current: pagination.current,
        pageSize: pagination.pageSize
      }))
      setWorkflowFilters(filters)
      setWorkflowSorter({ field, order })
    },
    [setWorkflowSettings, setWorkflowFilters, setWorkflowSorter]
  )

  const handleSelectRow = useCallback(
    (record, index) => ({
      index,
      name: record.name,
      onClick: () => {
        navigate(
          generatePath(WORKFLOW_STATE_PAGE, { workflowName: workflow.name, stateName: record.name })
        )
        setWorkflowSettings(oldValues => ({
          ...(oldValues || {}),
          activeTab: record.state?.name
        }))
      }
    }),
    [setWorkflowSettings, navigate, workflow]
  )
  // const getRowClassName = useCallback(record => (record.happy ? 'happy-flow-row' : ''), [])

  return (
    <div className="WorkflowStatesList">
      <Spin spinning={isLoadingUpdateWorkflow} size="large">
        {state.workflowStates.length > 0 && (
          <ConfigProvider locale={ruRu}>
            <Table
              className="clickable-table"
              size="middle"
              ref={tableRef}
              columns={columns}
              components={components}
              pagination={state.pagination}
              rowKey="name"
              dataSource={state.workflowStates}
              onChange={handleTableChange}
              onRow={handleSelectRow}
              // scroll={tableScroll}
              // rowClassName={getRowClassName}
            />
          </ConfigProvider>
        )}
      </Spin>
    </div>
  )
}

export default WorkflowStatesList
