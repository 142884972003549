import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Col, Row, Typography, Form, Button, message, Spin } from 'antd'
import WorkTimeDayItem from '../FormItems/WorkTimeSchedulerItems/WorkTimeDayItem'
import './WorkTimeScheduler.less'
import { UserContext } from '../../../../contexts/userContext'
import { ConfigContext } from '../../../../contexts/configContext'
import { convertArrayToObjectWithIndex } from '../../../../helpers'
import { useMutateDepartmentSettings } from '../../../../api/department'
import { useGetSettings } from '../../../../api/setting'
import { appConfig } from '../../../../constants/appConfig'

const { Title } = Typography

function WorkTimeScheduler() {
  const [form] = Form.useForm()
  const userData = useContext(UserContext)
  const {
    settings: { data: settingsData, setData }
  } = useContext(ConfigContext)
  const {
    mutate: saveDepartmentSettings,
    data: departmentSettingsData,
    isSuccess,
    isError,
    isLoading
  } = useMutateDepartmentSettings()
  const {
    data: configData,
    isSuccess: isSuccessConfig,
    isError: isErrorConfig,
    isLoading: isLoadingConfig
  } = useGetSettings()

  useEffect(() => {
    if (isSuccessConfig && configData?.data) {
      setData?.(configData.data)
    } else if (isErrorConfig) {
      message.error('Ошибка загрузки настроек')
    }
  }, [setData, configData, isSuccessConfig, isErrorConfig])

  const settings = useMemo(() => {
    let settings = userData.user?.department?.settings?.interviewHours || []
    settings = settings.map(srcItem => {
      const item = { ...srcItem }
      if (item.start === '0' || item.end === '0') {
        item.notWork = true
        item.start = settingsData[appConfig.settings.interviewScheduler.start]
        item.end = settingsData[appConfig.settings.interviewScheduler.end]
      } else {
        item.notWork = false
      }
      return item
    })
    return convertArrayToObjectWithIndex(settings)
  }, [userData, settingsData])

  const fillForm = useCallback(() => {
    form.setFieldsValue(settings)
  }, [form, settings])

  const handleSave = async days => {
    Object.values(days).forEach(day => {
      if (day.notWork) {
        day.start = '0'
        day.end = '0'
      }
      return day
    })
    await saveDepartmentSettings({
      interviewHours: Object.values(days)
    })
  }

  const makeDayItems = (start, end) => {
    const list = []
    for (let i = start; i <= end; i++) {
      list.push(
        <WorkTimeDayItem
          key={i}
          dayWeekIndex={i}
          initNotWork={settings?.[i]?.notWork}
          intervalStart={
            settingsData[appConfig.settings.interviewScheduler.start] ||
            appConfig.interviewHours.start
          }
          intervalEnd={
            settingsData[appConfig.settings.interviewScheduler.end] || appConfig.interviewHours.end
          }
        />
      )
    }
    return list
  }

  useEffect(() => {
    if (isSuccess) {
      userData.setUser({
        ...userData.user,
        department: departmentSettingsData.data
      })
      message.info('Расписание сохранено')
    } else if (isError) {
      message.error('Ошибка сохранения расписания')
    }
  }, [isSuccess, isError, departmentSettingsData])

  useEffect(() => {
    fillForm()
  }, [fillForm])

  return (
    <div className="mt-3 workTimeScheduler">
      <Title level={5}>Мое расписание</Title>
      <Spin spinning={isLoading || isLoadingConfig}>
        <Form form={form} onFinish={handleSave}>
          <Row gutter={24}>
            <Col span={12}>{makeDayItems(0, 3)}</Col>
            <Col span={12}>{makeDayItems(4, 6)}</Col>
          </Row>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form>
      </Spin>
    </div>
  )
}

export default WorkTimeScheduler
