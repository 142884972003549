import React, { useContext, useState } from 'react'
import { useOutlet } from 'react-router-dom'
import { AppointmentContext } from '../contexts/appointmentContext'

export const AppointmentProvider = () => {
  const outlet = useOutlet()
  const [appointment, setAppointment] = useState({})

  return (
    <AppointmentContext.Provider value={{ appointment, setAppointment }}>
      {outlet}
    </AppointmentContext.Provider>
  )
}

export const useAppointment = () => {
  return useContext(AppointmentContext)
}
