import React, { useContext, useEffect, useState } from 'react'
import { Radio, Button, Input, Typography, message, Spin } from 'antd'
import './CandidateRejectForm.css'
import { useGetSettings } from '../../../../api/setting'
import { ConfigContext } from '../../../../contexts/configContext'
import { parseArray } from '../../../../helpers/setting'

const { TextArea } = Input
const { Title, Text } = Typography

function CandidateRejectForm({ action, onAction, buttonsProps }) {
  const { settings } = useContext(ConfigContext)
  const {
    isSuccess: isSuccessReasons,
    isError: isErrorReasons,
    isLoading: isLoadingReasons,
    data: reasonsData
  } = useGetSettings()

  useEffect(() => {
    if (isSuccessReasons && reasonsData?.data) {
      if (JSON.stringify(settings.data) !== JSON.stringify(reasonsData.data)) {
        settings.setData?.(reasonsData.data)
      }
    } else if (isErrorReasons) {
      message.error('Ошибка получения настроек отказа')
    }
  }, [isSuccessReasons, isErrorReasons, reasonsData, settings])

  const [rejectReason, setRejectReason] = useState('')
  const [comment, setComment] = useState('')

  const handleOptionChange = ({ target }) => setRejectReason(target.value)
  const handleCommentChange = ({ target }) => setComment(target.value)

  const reasons = parseArray(settings.data[action.currentStep])

  return (
    <div className="candidateRejectForm">
      <Title level={4} className="title">
        Выберите причину отказа:
      </Title>
      <Spin spinning={isLoadingReasons}>
        <Radio.Group onChange={handleOptionChange} value={rejectReason}>
          {reasons.map((reason, index) => (
            <Radio key={index} value={reason} className="radio">
              {reason}
            </Radio>
          ))}
        </Radio.Group>
      </Spin>

      <div className="mb-3">
        <Text strong className="title d-flex">
          Комментарий
        </Text>

        <TextArea
          placeholder="Введите комментарий"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
      <div className="btn-wrap">
        <Button
          {...(buttonsProps?.ok || {})}
          onClick={() => onAction(action, { rejectReason, comment })}
          disabled={!rejectReason}
        >
          Сохранить
        </Button>
        <Button {...(buttonsProps?.cancel || {})} onClick={() => onAction(null, null)}>
          Отмена
        </Button>
      </div>
    </div>
  )
}

export default CandidateRejectForm
