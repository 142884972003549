import { appConfig } from '../constants/appConfig'

export const isCandidateForCheckupTab = candidate => {
  const validActions = [
    appConfig.workflows.candidate.actions.sendToMedicalTest
    // appConfig.workflows.candidate.actions.candidateApproved
  ]
  return (
    candidate.checkup ||
    validActions.some(status => candidate.possibleActions?.find(action => action.name === status))
  )
}
