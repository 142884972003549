import { useQuery } from 'react-query'
import { api } from './api'

export const APPOINTMENTS_KEY = 'appointments'
export const APPOINTMENTS_USER_KEY = 'appointments_user'

const fetchGetAppointments = ({ queryKey }) => {
  const [, /* key */ { start, end, departmentId }] = queryKey
  const path = 'departmentAppointments'
  const params = { start, end, ...(departmentId && { departmentId }) }
  const urlParams = new URLSearchParams(params)
  return api.get({ path: `${path}?${urlParams.toString()}` })
}

export function useAppointmentsQuery(data, option) {
  return useQuery([APPOINTMENTS_KEY, data], fetchGetAppointments, option)
}
