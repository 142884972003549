import React, { useContext } from 'react'
import { Form, Select } from 'antd'
import { colSettings } from '../../../../../constants'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'

const { Option } = Select

function VacanciesItem({
  onChange,
  vacancyList,
  label = 'Вакансия',
  required = true,
  allowClear = false,
  size = 'middle',
  name = 'vacancy',
  wrapperCol = colSettings.full,
  mode = ''
}) {
  const { candidate } = useContext(CandidatePanelContext)

  return (
    <Form.Item
      label={label}
      required={required}
      labelCol={colSettings.full}
      wrapperCol={wrapperCol}
      name={name}
      rules={[{ required, message: 'Укажите вакансию' }]}
    >
      <Select
        size={size}
        placeholder="Выберите вакансию"
        onChange={value => onChange?.(value)}
        mode={mode}
        allowClear={allowClear}
      >
        {vacancyList?.map(vacancy => (
          <Option
            key={vacancy._id}
            value={vacancy._id}
            disabled={candidate?._id && candidate.vacancy?.module !== vacancy.module}
          >
            {vacancy.displayName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default VacanciesItem
