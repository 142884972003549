import { useQuery } from 'react-query'
import { api } from './api'

export const CHAT_BOT_KEY = 'chatBot'

const fetchFindMessages = ({ queryKey }) => {
  const [, /* key */ { pagination, candidate }] = queryKey
  // const params = pagination
  return api.get({ path: `candidates/${candidate}/messages`, params: pagination })
}

export function useQueryFindMessages(data, options) {
  return useQuery([CHAT_BOT_KEY, data], fetchFindMessages, options)
}
