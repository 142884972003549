import React, {
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useLayoutEffect,
  useMemo
} from 'react'
import { dateFnsLocalizer, Calendar, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { message, Typography } from 'antd'
import dayjs from 'dayjs'
import {
  parse,
  getDay,
  set,
  endOfWeek,
  startOfWeek,
  toDate,
  sub,
  differenceInHours,
  max as maxFns,
  min as minFns,
  format
} from 'date-fns'
import dragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import { simpleReducer } from '../../helpers'
import { formatPhoneNumber } from '../../helpers/phone'
import Toolbar from './Toolbar'
import { appConfig, langCases } from '../../constants/appConfig'
import { useAppointmentsQuery } from '../../api/appointment'
import { useDepartmentByIdQuery } from '../../api/department'
import { useGetSettings } from '../../api/setting'
import { ConfigContext } from '../../contexts/configContext'
import { makeWeekFullWorkingDays } from '../../helpers/time'
import { nbsp } from '../../constants'
import { useQueryAmpAppointments } from '../../api/amp'
import { UserContext } from '../../contexts/userContext'

import { useConfigContextData } from '../../hooks/useConfigContextData'
import {
  formatLocale,
  getOffset,
  locales,
  splitTimeAndSetDate,
  startOfWeekLocale
} from '../../helpers/date-fns'
import { CandidatePanelContext } from '../../contexts/candidatePanelContext'

const localizer = dateFnsLocalizer({
  format: formatLocale,
  parse,
  startOfWeek: startOfWeekLocale,
  getDay,
  locales
})
const DragAndDropCalendar = dragAndDrop(Calendar)
const { Title, Text } = Typography

const SchedulerCustom = ({
  action = null,
  onSelectEvent,
  selectable = true,
  onMoveEvent,
  events,
  hideHeader = false,
  hideTitle = false,
  title = 'Перепланировать собеседование',
  footer = null,
  department = null,
  disableSelect = false,
  defaultView = Views.WEEK,
  interview
}) => {
  const initialState = {
    city: null,
    director: null,
    interviewStart: formatLocale(
      set(new Date(), { hours: appConfig.interviewHours.start, minutes: 0, seconds: 0 }),
      appConfig.formats.shortTimeWithSeconds
    ),
    interviewEnd: formatLocale(
      set(new Date(), { hours: appConfig.interviewHours.end, minutes: 0, seconds: 0 }),
      appConfig.formats.shortTimeWithSeconds
    ),
    eventSelected: false,
    events: events || [],
    interviewHours:
      (department?.plan?.interviewerDepartment?._id
        ? department?.plan?.interviewerDepartment?.settings?.interviewHours
        : department?.settings?.interviewHours) || makeWeekFullWorkingDays(),
    week: {
      start: startOfWeek(new Date(), { weekStartsOn: 1 }).toString(),
      end: endOfWeek(new Date(), { weekStartsOn: 1 }).toString()
    }
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { settings } = useContext(ConfigContext)
  const { isTU, isPartner, isOperationDirector } = useContext(UserContext)
  const { getDepartmentSingularName } = useConfigContextData()
  const { isAmpCandidate } = useContext(CandidatePanelContext)

  const {
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    data: settingsData
  } = useGetSettings()

  useEffect(() => {
    if (
      isSuccessSettings &&
      settingsData?.data &&
      JSON.stringify(settings.data) !== JSON.stringify(settingsData.data)
    ) {
      settings.setData?.(settingsData.data)
    } else if (isErrorSettings) {
      message.error('Ошибка получения настроек')
    }
  }, [settings, settingsData, isErrorSettings, isSuccessSettings])

  const activeDepartmentId = useMemo(
    () =>
      // moduleName === modules.bk &&
      // action &&
      // action.name !== appConfig.workflows.candidate.actions.scheduleInterview
      //   ? candidate?.application?.interviewerDepartment?._id ||
      //     candidate?.application?.department?._id
      //   :
      department?.plan?.interviewerDepartment?._id ||
      (action?.name === appConfig.workflows.candidate.actions.changeDepartment
        ? department?._id
        : interview?.department?._id || department?._id),
    [department, interview, /* moduleName, candidate,*/ action]
  )

  const isAmpUser = useMemo(
    () => isPartner || isOperationDirector || isTU,
    [isPartner, isOperationDirector, isTU]
  )
  // const isInboxQuery = useMemo(
  //   () =>
  //     isHiringManager ||
  //     moduleName !== modules.bk ||
  //     action?.name === appConfig.workflows.candidate.actions.scheduleInterview,
  //   [moduleName, action, isHiringManager]
  // )

  const {
    data: appointmentsData,
    isSuccess: isSuccessAppointments,
    isError: isErrorAppointments
  } = useAppointmentsQuery(
    {
      start: state.week.start,
      end: state.week.end,
      departmentId: activeDepartmentId
    },
    {
      retry: false,
      enabled: !isAmpCandidate && !isAmpUser
    }
  )

  const {
    data: ampAppointmentsData,
    isSuccess: isSuccessAmpAppointments,
    isError: isErrorAmpAppointments,
    error: errorAmpAppointments
  } = useQueryAmpAppointments(
    {
      start: state.week.start,
      end: state.week.end,
      departmentId: activeDepartmentId,
      action: action?.name
    },
    {
      retry: false,
      enabled: isAmpCandidate || isAmpUser
    }
  )
  const prepareEvents = useCallback(
    data =>
      data.map(appointment => {
        const offset =
          appointment.interview?.timeOffset || // только для директора
          department?.plan?.interviewerDepartment?.timeOffset ||
          department?.timeOffset ||
          getOffset(formatLocale(new Date(), 'XXX'))

        return {
          ...appointment,
          /**
           * Время от бэка нам приходит в UTC, для корректного показа учитываем timeOffset
           * Если в appointment не указан департамент, значит собеседование хотят в локальном времени компьютера
           *
           */
          start: dayjs(appointment.interview.start)
            .utcOffset(offset)
            .format(appConfig.formats.shortDateAndTimeApi),
          end: dayjs(appointment.interview.end)
            .utcOffset(offset)
            .format(appConfig.formats.shortDateAndTimeApi),
          eventType: appointment?.hasFeedback && 'hasFeedback'
        }
      }),
    [department?.timeOffset, department?.plan?.interviewerDepartment?.timeOffset]
  )

  useEffect(() => {
    if (isSuccessAppointments && appointmentsData?.data?.length && !state.eventSelected) {
      setState({ events: prepareEvents(appointmentsData.data.filter(app => app.interview)) })
    }
  }, [isSuccessAppointments, appointmentsData, prepareEvents, state.eventSelected])

  useEffect(() => {
    if (isSuccessAmpAppointments && ampAppointmentsData?.data?.length && !state.eventSelected) {
      setState({ events: prepareEvents(ampAppointmentsData.data.filter(app => app.interview)) })
    }
  }, [isSuccessAmpAppointments, ampAppointmentsData, prepareEvents, state.eventSelected])

  const {
    data: departmentData,
    status: departmentStatus
    // isLoading: departmentIsLoading
  } = useDepartmentByIdQuery(activeDepartmentId, {
    enabled: !!activeDepartmentId,
    retry: false
  })

  useEffect(() => {
    if (departmentStatus === 'success' && departmentData?.data?.settings?.interviewHours) {
      const { interviewHours } = departmentData.data.settings
      setState({ interviewHours })
    }
    setState({ activeDepartment: departmentData?.data })
  }, [departmentStatus, departmentData])

  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isErrorAmpAppointments) {
      if (errorAmpAppointments?.response?.status === 404) {
        let errorMessage = ''
        switch (action?.name) {
          case appConfig.workflows.candidate.actions.rescheduleODInterview:
          case appConfig.workflows.candidate.actions.odInterview: {
            errorMessage = `У ${getDepartmentSingularName(
              langCases.genitive
            )?.toLowerCase()} нет операционного директора`
            break
          }
          case appConfig.workflows.candidate.actions.rescheduleHRBPInterview:
          case appConfig.workflows.candidate.actions.hrBPInterview: {
            errorMessage = `У ${getDepartmentSingularName(
              langCases.genitive
            )?.toLowerCase()} нет ТУ/РМП`
            break
          }
          default:
            errorMessage = 'Неизвестная ошибка'
        }
        message.error(errorMessage)
      } else {
        message.error('Ошибка получения расписания')
      }
    }
  }, [isErrorAmpAppointments, errorAmpAppointments, action?.name, getDepartmentSingularName])

  useEffect(() => {
    if (isErrorAppointments) {
      message.error('Ошибка получения расписания')
    }
    if (departmentStatus === 'error') {
      message.error('Ошибка получения орг. единиц')
    }
  }, [isErrorAppointments, departmentStatus])

  const min = useMemo(() => splitTimeAndSetDate(state.interviewStart), [state.interviewStart])
  const max = useMemo(() => splitTimeAndSetDate(state.interviewEnd), [state.interviewEnd])

  const handleSelect = useCallback(
    ({ start, end }) => {
      /**
       * запрещаем назначать собес на время за пределами календаря
       */
      if (
        dayjs(start).hour() < dayjs(min).hour() ||
        dayjs(end).hour() - (dayjs(end).minute() ? 0 : 1) > dayjs(max).hour()
        //        * запрещаем назначать собес на время за пределами расписания
        // dayjs(start).hour() <
        //   state.interviewHours[
        //     (dayjs(start).day() ? dayjs(start).day() : state.interviewHours.length) - 1
        //   ]?.start?.split(':')?.[0] ||
        // dayjs(end).hour() - (dayjs(end).minute() ? 0 : 1) >
        //   state.interviewHours[
        //     (dayjs(end).day() ? dayjs(end).day() : state.interviewHours.length) - 1
        //   ]?.end?.split(':')?.[0]
      )
        return
      const eventType = 'reserve'
      const newEvents = [
        ...(state.events?.filter(e => e.eventType !== eventType) || []),
        {
          start: new Date(start),
          end: new Date(end),
          eventType
        }
      ]

      setState({
        eventSelected: true,
        events: newEvents
      })
    },
    [state.events, min, max]
  )

  const handleNavigate = useCallback(start => {
    const week = {
      start: startOfWeek(new Date(start), { weekStartsOn: 1 }).toString(),
      end: endOfWeek(new Date(start), { weekStartsOn: 1 }).toString()
    }

    setState({ week })
  }, [])

  // const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

  const applyHoursSettings = useCallback(
    interviewHours => {
      const settingsStart = settings.data[appConfig.settings.interviewScheduler.start]
      const settingsEnd = settings.data[appConfig.settings.interviewScheduler.end]
      if (interviewHours && settingsStart && settingsEnd) {
        return interviewHours.map(day => {
          if (day.start === '0' || day.end === '0') {
            return day
          } else {
            return {
              start: format(
                maxFns([splitTimeAndSetDate(day.start), splitTimeAndSetDate(settingsStart)]),
                appConfig.formats.shortTime
              ),
              end: format(
                minFns([splitTimeAndSetDate(day.end), splitTimeAndSetDate(settingsEnd)]),
                appConfig.formats.shortTime
              )
            }
          }
        })
      }
      return interviewHours
    },
    [settings.data]
  )

  const fillDisabledDays = useCallback(
    (min, max) => {
      const workTime = applyHoursSettings(state.interviewHours)
      if (workTime) {
        const currentCalendar = document.getElementsByClassName(`department-${department?._id}`)

        // на экране может быть 2 календаря на основной странице и на дровере(модалке)
        for (const calendar of currentCalendar) {
          const header = calendar?.getElementsByClassName('rbc-time-header-cell')
          const dayCell = calendar?.getElementsByClassName('rbc-allday-cell')
          const daySlots = calendar?.getElementsByClassName('rbc-time-content')
          const monthRow = calendar?.getElementsByClassName('rbc-month-row')
          let days = []
          let cells = []
          let slots = []

          if (header && header.length > 0) {
            days = header[0].children // дни недели
            cells = dayCell[0].children // пустая строка под днями недели
            slots = daySlots[0].children // столбец с временными ячейками в каждом дне

            if (workTime && days.length > 0 && cells.length > 0 && slots.length > 0) {
              for (let i = 0; i < days.length; i++) {
                // тут 0 - это время
                if (workTime[i]?.start === '0' && workTime[i]?.end === '0') {
                  days[i].className += ' disable_day' // дни недели
                  slots[i + 1].className += ' disable_day' // пустая строка под днями недели
                  cells[0].children[i].className += ' disable_day' // столбец с временными ячейками в каждом дне

                  const innerSlots = slots[i + 1].getElementsByClassName('rbc-timeslot-group')
                  for (let s = 0; s < innerSlots.length; s++) {
                    if (!innerSlots[s]) return
                    innerSlots[s].className += ' disable_day' // каждая временная ячейка в выбраном дне
                  }
                } else {
                  const min2 = splitTimeAndSetDate(workTime[i]?.start)
                  const max2 = splitTimeAndSetDate(workTime[i]?.end)
                  const diffStart = differenceInHours(min2, min)
                  const diffEnd = differenceInHours(max, max2)
                  const innerSlots = slots[i + 1].getElementsByClassName('rbc-timeslot-group')

                  for (let s = 0; s < diffStart; s++) {
                    if (!innerSlots[s]) return
                    innerSlots[s].className += ' disable_day'
                  }

                  for (let e = innerSlots.length - diffEnd; e < innerSlots.length; e++) {
                    if (!innerSlots[e]) return
                    innerSlots[e].className += ' disable_day'
                  }
                }
              }
            }
          } else if (monthRow && monthRow.length > 0) {
            for (let i = 0; i < monthRow.length; i++) {
              let week = monthRow[i].getElementsByClassName('rbc-row-bg')
              week = week[0].getElementsByClassName('rbc-day-bg')

              for (let j = 0; j < workTime.length; j++) {
                if (workTime[j].start === 0 && workTime[j].end === 0) {
                  week[j].className += ' disable_day'
                }
              }
            }
          }
        }
      }
    },
    [applyHoursSettings, department?._id, state.interviewHours]
  )

  const handleSelectEvent = useCallback(event => onSelectEvent?.(event), [onSelectEvent])

  const handleViewChange = useCallback(() => {
    fillDisabledDays(min, max)
  }, [fillDisabledDays, min, max])

  const handleMoveEvent = useCallback(
    data => {
      const eventType = 'reserve'
      if (data.event.eventType === eventType) {
        const newEvents = [
          ...(state.events?.filter(e => e.eventType !== eventType) || []),
          {
            start: new Date(data.start),
            end: new Date(data.end),
            eventType
          }
        ]

        setState({
          events: newEvents
        })
      }
      if (onMoveEvent && window.confirm('Изменить дату и время события?')) {
        onMoveEvent(data)
      }
    },
    [onMoveEvent, state.events]
  )

  const eventPropGetter = useCallback(
    ev => ({
      className: ev.eventType + ' ' + ev.interview?.color,
      style: {
        backgroundColor: ev.interview?.color === '#000' ? 'white' : ev.interview?.color,
        borderColor: ev.interview?.color,
        color: ev.interview?.color === '#000' ? ev.interview?.color : 'white'
      }
    }),
    []
  )

  useLayoutEffect(() => {
    fillDisabledDays(min, max)
  }, [fillDisabledDays, min, max])

  return (
    <div className={`department-${department?._id}`}>
      {!hideTitle && (
        <Title level={4} className={''}>
          {title}
        </Title>
      )}
      {department?.plan?.interviewerDepartment?._id && (
        <Text className="danger-text">
          ВНИМАНИЕ: Собеседование будет проходить в{' '}
          {
            getDepartmentSingularName(langCases.prepositional)
              ?.toLowerCase()
              ?.split(' ')
              ?.reverse()?.[0]
          }
          {nbsp}
          <b>{state.activeDepartment?.name}</b>: {state.activeDepartment?.address}
          {state.activeDepartment?.phone ? (
            <>
              {', '}
              <a href={'tel:' + state.activeDepartment?.phone} className="white-space-nowrap">
                {formatPhoneNumber(state.activeDepartment?.phone)}
              </a>
            </>
          ) : (
            ''
          )}
          {state.activeDepartment?.hiringManager?.email ? (
            <>
              {', '}
              <a href={'mailto:' + state.activeDepartment?.hiringManager?.email}>
                {state.activeDepartment?.hiringManager?.email}
              </a>
            </>
          ) : (
            ''
          )}
        </Text>
      )}
      <DragAndDropCalendar
        events={state.events}
        startAccessor={ev => new Date(ev.start)}
        endAccessor={ev => new Date(ev.end)}
        titleAccessor={ev => {
          // отображение заголовка для событий больше одного дня
          const start = format(new Date(ev.start), appConfig.formats.shortTime)
          const end = format(new Date(ev.end), appConfig.formats.shortTime)
          return `${start} - ${end}`
        }}
        resourceIdAccessor={ev => ev.candidateId}
        eventPropGetter={eventPropGetter}
        selectable={selectable}
        step={30}
        localizer={localizer}
        min={toDate(min)}
        max={toDate(sub(max, { minutes: 1 }))} // .sub - чтобы не отображать строку с последним часом на который нельзя назначить собес
        defaultView={defaultView}
        scrollToTime={new Date(2017, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={handleSelectEvent} // Клик по событию в календаре
        onSelectSlot={disableSelect ? null : handleSelect} // Выделение области onDragStart={console.log}
        onView={handleViewChange}
        onEventDrop={handleMoveEvent} // перетаскивание
        views={[Views.WORK_WEEK, Views.WEEK, Views.MONTH]}
        messages={appConfig.schedulerWeekNavigate}
        components={hideHeader ? { toolbar: Toolbar } : {}}
        onNavigate={handleNavigate} // переключение недель
      />
      {state.director && !state.eventSelected && <Text type="danger">* Выберите слот</Text>}
      {footer && footer(action, state)}
    </div>
  )
}

export default SchedulerCustom
