import { useMutation, useQuery } from 'react-query'
import { api, queryClient } from './api'
import { defaultQueryOptions } from '../constants'
import { PLAN_KEY, PLANS_KEY } from './plan'

const VACANCY_SYS_KEY = 'systemvacancies'
export const VACANCIES_KEY = 'vacancies'

const getSystemVacancies = () => {
  return api.get({ path: 'systemvacancies' })
}

const getVacancies = ({ queryKey }) => {
  const [, /* key */ params] = queryKey
  return api.get({ path: 'vacancies', params })
}

export function useGetSystemVacancies(options = defaultQueryOptions) {
  return useQuery([VACANCY_SYS_KEY], getSystemVacancies, options)
}

export function useGetVacancies(data, options) {
  return useQuery([VACANCIES_KEY, data], getVacancies, options)
}

const fetchUpdateVacancy = data => {
  return api.patch({ path: `${VACANCIES_KEY}/${data._id}`, data })
}
const fetchCreateVacancy = data => {
  let path = `${VACANCIES_KEY}`
  if (data.notify) {
    path += '?notify=1'
  }
  return api.put({ path, data })
}
export function useMutateUpdateVacancy() {
  return useMutation(fetchUpdateVacancy, {
    onSuccess: () => {
      queryClient.refetchQueries(VACANCIES_KEY)
      queryClient.refetchQueries(PLAN_KEY, { active: true })
      queryClient.invalidateQueries(PLAN_KEY)
      queryClient.refetchQueries(PLANS_KEY)
    }
  })
}
export function useMutateCreateVacancy() {
  return useMutation(fetchCreateVacancy, {
    onSuccess: () => {
      queryClient.refetchQueries(VACANCIES_KEY)
      queryClient.refetchQueries(PLAN_KEY, { active: true })
      queryClient.refetchQueries(PLANS_KEY)
    }
  })
}
