import { getEnvironmentByHost } from '../index'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

function getSentryOptions() {
  const environment = getEnvironmentByHost()

  return {
    dsn: 'https://572460a9bb104fe98917422382430913@sentry.improvity.ru/2',
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'http://localhost:3000/',
          /^http:\/\/localhost:3000\/api/,
          /^https:\/\/dev\.solvopro\.improvity\.ru\/api/,
          'localhost'
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    release: 'solvopro@' + window.solvopro?.version,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  }
}

Sentry.init(getSentryOptions())
