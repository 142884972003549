import axios from 'axios'
import { QueryClient } from 'react-query/core'
import * as Sentry from '@sentry/react'

import env from '../env'
import { dadataToken, dadataUrl, metroDadataUrl, threeMinutes } from '../constants'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: threeMinutes,
      retry: 1
    }
  }
})

// TODO: for refresh token
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.error('API error:', error)
    const request = error.config

    if (error.response && ![401, 404].includes(error.response?.status)) {
      const { data, headers, method, url } = request
      Sentry.captureException(new Error(error.message + ' ' + request.url), {
        contexts: {
          request: { data, headers, method, url }
        }
      })
    }
    // console.log('%c request.url', 'color: blue; font-weight: bold; font-size: 22px', {
    //   request,
    //   resp: error.response
    // })
    // if (error.response?.status === 404 && request.url?.includes('chunk')) {
    //   return location.reload()
    // }
    return Promise.reject(error)
  }
)

const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json'
})

const getDadataHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Token ' + dadataToken
})

const get = ({ path, data, params }) => makeRequest({ method: 'GET', path, data, params })
const post = ({ path, data }) => makeRequest({ method: 'POST', path, data })
const put = ({ path, data }) => makeRequest({ method: 'PUT', path, data })
const patch = ({ path, data }) => makeRequest({ method: 'PATCH', path, data })
const del = ({ path, data }) => makeRequest({ method: 'DELETE', path, data })

const makeRequest = ({ method, path, data, params }) =>
  axios.request({
    url: `${process.env.API_HOST ? process.env.API_HOST : ''}${env.apiEndpoint}${path}`,
    method,
    data,
    params,
    headers: getHeaders()
  })

export const api = {
  get,
  post,
  put,
  patch,
  del
}

const getDadataOptions = () => ({
  mode: 'cors',
  headers: getDadataHeaders()
})
export const searchDadata = data => {
  const options = getDadataOptions()
  return axios.post(dadataUrl, data, options)
}
export const metroDadata = data => {
  const options = getDadataOptions()
  return axios.post(metroDadataUrl, data, options)
}

// по возможности использовать <a href="...">
export const downloadFile = ({ path, data, params, method, filename, onSuccess, onError }) => {
  const urlParams = new URLSearchParams(
    Object.entries(params || {}).filter(([, value]) => !!value)
  ).toString()
  const url = params ? `${path}?${urlParams}` : path

  return fetch(`${env.apiEndpoint}${url}`, {
    headers: getHeaders(),
    ...(method && { method }),
    ...(data && { body: JSON.stringify(data) })
  })
    .then(response => {
      if (response?.status !== 200) throw Error(response?.statusText)
      return response.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      onSuccess?.()
    })
    .catch(e => {
      onError?.()
      console.error(`API downloadFile "${filename}":`, e)
    })
}
