import { useQuery } from 'react-query'
import { api, queryClient } from './api'
import { defaultQueryOptions } from '../constants'

const FEATURES_KEY = 'features'

const fetchGetFeatures = () => {
  return api.get({ path: 'features' })
}

/**
 * By default features will be invariable so we keep they in cache without refetch
 */
export function useQueryFeatures(options = {}) {
  return useQuery([FEATURES_KEY], fetchGetFeatures, { ...defaultQueryOptions, ...options })
}

const featuresQuery = options => ({
  queryKey: [FEATURES_KEY],
  queryFn: async () => fetchGetFeatures(),
  ...defaultQueryOptions,
  ...(options || {})
})
export const featuresLoader = async options => {
  const query = featuresQuery(options)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}
