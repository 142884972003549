import React, { useContext } from 'react'
import { ConfigContext } from '../../../contexts/configContext'

export const withFeatureToggle = (WrappedComponent, featureName) => props => {
  const {
    features: { data: features, isLoaded: isLoadedFeatures }
  } = useContext(ConfigContext)
  return isLoadedFeatures && features?.includes(featureName) ? (
    <WrappedComponent {...props} />
  ) : null
}
