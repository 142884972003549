import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useRecoilValue } from 'recoil'

import layersIcon from '../../../../../img/orgStructure/layers-blue.svg'
import UnitItem from './UnitItem'
import { simpleReducer } from '../../../../../helpers'
import AddUnitItem from '../Actions/AddUnitItem'
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { modules } from '../../../../../constants'
import { customPropertiesSettingsAtom } from '../../../../../recoil/atoms'

function OrgStructureUnit({
  layerIndex,
  data = null,
  onSelect,
  selectedUnits = null,
  lastSelectedId
}) {
  const { layers } = useContext(OrgStructureContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)

  const initialState = {
    currentItem: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleSelect = useCallback(
    item => {
      setState({ currentItem: item })
      onSelect?.(layerIndex, item)
    },
    [onSelect, layerIndex]
  )

  useEffect(() => {
    if (!selectedUnits?.[layerIndex]) {
      setState({ currentItem: null })
    }
  }, [selectedUnits, layerIndex])

  const isShownAddItem = useMemo(() => {
    const prevIndex = layerIndex - 1

    if (layerIndex === 0) {
      return true
    } else if (selectedUnits?.[prevIndex]) {
      return true
    }
    return false
  }, [selectedUnits, layerIndex])

  return (
    <div className="layer">
      {moduleName !== modules.bk && (
        <div className="layerHeader">
          <img src={layersIcon} alt="layer" />
          {layers[layerIndex]}
        </div>
      )}
      <div className="layerBody">
        {data?.map(unit => (
          <UnitItem
            key={unit._id}
            isLastSelected={unit._id === lastSelectedId}
            isLastLayer={layers?.length - 1 === layerIndex}
            unit={unit}
            onSelect={handleSelect}
            currentItem={state.currentItem}
          />
        ))}
        {isShownAddItem && moduleName !== modules.bk && (
          <AddUnitItem layerIndex={layerIndex} selectedUnits={selectedUnits} />
        )}
      </div>
    </div>
  )
}

export default OrgStructureUnit
