import React, { useState } from 'react'
import { Tooltip, Typography } from 'antd'

const { Paragraph } = Typography

export default function TooltipParagraph({ children, ellipsis, ...props }) {
  const [truncated, setTruncated] = useState(false)

  return (
    <Tooltip title={truncated ? children : undefined}>
      <Paragraph {...props} ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  )
}
