import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, message, Row, Spin, Typography } from 'antd'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { generatePath, Link } from 'react-router-dom'

import addCandidate from '../../../../img/addCandidate.png'
import './ProfileCreate.less'
import { appConfig } from '../../../../constants/appConfig'
import {
  BirthAndAgeItem,
  CitizenshipItem,
  CommentItem,
  DadataAddressItem,
  EmailItem,
  FioItem,
  PhoneItem,
  RequestTypeItem,
  VacanciesItem,
  UtmMediumItem,
  UtmSourceItem
} from '../FormItems/CandidateProfileItems'
import { useFindSimilar, useMutateCreateCandidate } from '../../../../api/candidate'
import { useMutateFlowReuseCandidate } from '../../../../api/flow/candidate'
import { UserContext } from '../../../../contexts/userContext'
import { useReferences } from '../../../../hooks/useReferences'
import { debounce, simpleReducer } from '../../../../helpers'
import {
  customPropertiesSettingsAtom,
  hiringManagerSettingsAtom,
  inboxSettingsAtom
} from '../../../../recoil/atoms'
import CandidateDuplicateItem from './Duplicate/CandidateDuplicateItem'
import { UtmModal } from './UtmModal'
import { modules } from '../../../../constants'
import { useVacancies } from '../../../../hooks/useVacancies'
import ReuseModalForm from '../ReuseModalForm/ReuseModalForm'
import { CANDIDATE_PAGE } from '../../../../constants/routes'
import { UserFieldsFormItem } from '../'

const { Text } = Typography
const initialState = {
  vacancyList: [],
  currentVacancy: null,
  dadataAddress: null,
  utms: {
    requestType: null,
    utm_medium: null
  },
  phone: undefined,
  selectedCandidate: undefined,
  candidates: []
}

const ProfileCreate = props => {
  const setHiringManagerSettings = useSetRecoilState(hiringManagerSettingsAtom)
  const setInboxSettings = useSetRecoilState(inboxSettingsAtom)
  const { isHiringManager, user } = useContext(UserContext)
  const [form] = Form.useForm()
  const [utmForm] = Form.useForm()
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isReuseModalVisible, setIsReuseModalVisible] = useState(false)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const { isActiveVacancy } = useVacancies()

  const isExternalUser = useMemo(() => Boolean(user.agency), [user])

  useReferences({
    citizenships: true,
    requestTypes: true,
    utms: true,
    utmsQuery: state.utms.requestType
  })

  /**
   * Create candidate
   */
  const { mutate, status, isLoading } = useMutateCreateCandidate()

  useEffect(() => {
    if (status === 'success') {
      setHiringManagerSettings(oldValues => ({
        ...(oldValues || {}),
        activeTab: null
      }))
      setInboxSettings(oldValues => ({
        ...(oldValues || {}),
        activeTab: null
      }))
      props.onSave()
    }
    if (status === 'error') {
      message.error('Ошибка добавления кандидата')
    }
  }, [setInboxSettings, setHiringManagerSettings, status, props])

  const {
    mutate: reuseCandidate,
    isLoading: isLoadingReuse,
    isSuccess: isSuccessReuse,
    isError: isErrorReuse
  } = useMutateFlowReuseCandidate()

  useEffect(() => {
    if (isSuccessReuse) {
      props.onSave()
    } else if (isErrorReuse) {
      message.error('Ошибка переиспользования кандидата')
    }
  }, [isSuccessReuse, isErrorReuse, props])

  const { isLoadingVacancies, vacancies } = useVacancies({
    showInLists: true
  })

  useEffect(() => {
    if (vacancies) {
      // Если одно значение селекта, то сразу выбрать, если нет то юзер выберет сам
      const currentVacancy = vacancies.length === 1 ? vacancies[0] : null
      form.setFieldsValue({
        vacancy: currentVacancy?._id
      })
      setState({
        vacancyList: vacancies,
        currentVacancy
      })
    }
  }, [vacancies, form])

  const handleFillAddress = data => setState({ dadataAddress: data })

  const handleAddUser = useCallback(
    async values => {
      values.birthday = values?.birthday?.format(appConfig.formats.shortDateApi)
      values.city = state.dadataAddress?.city || state.dadataAddress?.settlement
      values.similar = state.candidates?.map(item => item._id)
      if (values.userFields) {
        values.userFields = Object.entries(values.userFields).map(([field, value]) => ({
          field,
          value
        }))
      }
      await mutate({ data: values })
    },
    [mutate, state.candidates, state.dadataAddress]
  )

  const handleSetRequestType = value => {
    setState({
      utms: {
        requestType: value,
        utm_medium: null
      }
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null,
      utm_campaign: null
    })
  }
  const handleSetUtmMedium = value => {
    setState({
      utms: {
        ...state.utms,
        utm_medium: value
      }
    })
    form.setFieldsValue({
      utm_source: null,
      utm_campaign: null
    })
  }

  const handleChangeVacancy = useCallback(
    value => {
      setState({
        currentVacancy: state.vacancyList?.find(vacancy => vacancy._id === value)
      })
    },
    [state.vacancyList]
  )

  const { data: dataFindSimilar } = useFindSimilar(
    { phone: state.phone },
    {
      enabled: !!state.phone
    }
  )

  const handleSetPhone = useCallback(() => {
    const phone = form.getFieldValue('phone')
    setState({ phone })
  }, [form])

  const debounceSetPhone = useMemo(() => debounce(handleSetPhone, 800), [handleSetPhone])

  useEffect(() => {
    const data = { candidates: dataFindSimilar ? dataFindSimilar.data.similar : [] }
    if (!state.selectedCandidate && data.candidates.length > 0) {
      data.selectedCandidate = data.candidates[0]
    }
    if (state.selectedCandidate && data.candidates.length === 0) {
      data.selectedCandidate = undefined
    }
    setState(data)
  }, [dataFindSimilar, state.selectedCandidate])

  const ownCandidate = useMemo(
    () =>
      isHiringManager &&
      state.candidates.find(c => c.application?.department?._id === user.department?._id),
    [isHiringManager, state.candidates, user.department]
  )

  const handleSelectCandidate = candidate => {
    setState({ selectedCandidate: candidate })
  }

  const handleReuseCandidate = useCallback(async () => {
    const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
    const data = {
      requestType: requestType || null,
      utm_source: utm_source || null,
      utm_medium: utm_medium || null,
      utm_campaign: null,
      utm_term: null
    }
    setIsModalVisible(false)
    utmForm.setFieldsValue({
      requestType: null,
      utm_medium: null,
      utm_source: null
    })
    if (isActiveVacancy(state.selectedCandidate?.vacancy?._id)) {
      await reuseCandidate({ id: state.selectedCandidate?._id, data })
    } else {
      return setIsReuseModalVisible(true)
    }
  }, [reuseCandidate, state.selectedCandidate, utmForm, isActiveVacancy])

  const handleReuseCandidateWithActiveVacancy = useCallback(
    async values => {
      const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
      const data = {
        requestType: requestType || null,
        utm_source: utm_source || null,
        utm_medium: utm_medium || null,
        utm_campaign: null,
        utm_term: null,
        vacancy: values?.vacancy
      }
      await reuseCandidate({ id: state.selectedCandidate?._id, data })
      setIsReuseModalVisible(false)
    },
    [reuseCandidate, state.selectedCandidate, utmForm]
  )

  const handleReuse = useCallback(() => {
    if (user.agency) handleReuseCandidate()
    else setIsModalVisible(true)
  }, [handleReuseCandidate, user.agency])

  return (
    <div className="profile-create-wrap">
      <Spin spinning={isLoading || isLoadingVacancies || isLoadingReuse} size="large">
        <Form form={form} onFinish={handleAddUser} scrollToFirstError>
          <Row gutter={8}>
            <Col span={10} className="profile-create-block mt-3">
              <div className="block-title">НОВЫЙ КАНДИДАТ</div>
              <PhoneItem required onChange={debounceSetPhone} />
              <VacanciesItem vacancyList={state.vacancyList} onChange={handleChangeVacancy} />
              <FioItem required />
              <EmailItem />
              <BirthAndAgeItem form={form} />
              <CitizenshipItem />
              {!isHiringManager && <DadataAddressItem onChange={handleFillAddress} />}
              {!isExternalUser && (
                <RequestTypeItem
                  value={state.utms.requestType}
                  onChange={handleSetRequestType}
                  required={moduleName === modules.bk}
                />
              )}
              {!isExternalUser && (
                <UtmMediumItem value={state.utms.utm_medium} onChange={handleSetUtmMedium} />
              )}
              {!isExternalUser && (
                <UtmSourceItem
                  utmMedium={state.utms.utm_medium}
                  required={moduleName === modules.bk}
                />
              )}
              <CommentItem />
              <UserFieldsFormItem />
            </Col>

            <Col span={12} className="duplicate-block" offset={2}>
              {state.candidates.length === 0 ? (
                <img src={addCandidate} alt="add candidate" className="addCandidate" />
              ) : (
                <CandidateDuplicateItem
                  candidates={state.candidates}
                  candidate={state.selectedCandidate}
                  selectCandidate={handleSelectCandidate}
                  wrapperClassName="second-block"
                />
              )}
            </Col>
          </Row>
          <div className="button-block">
            {ownCandidate?.activeStatus && (
              <Row gutter={16} className="with-border-bottom">
                <Col span={24} className="button-wrapper-white">
                  <Text type="danger" className="text-center">
                    Данный кандидат уже находится в вашем ресторане на активных статусах подбора.
                    Зайдите в раздел Мои кандидаты и продолжите работать{' '}
                    <Link to={generatePath(CANDIDATE_PAGE, { id: ownCandidate?._id })}>
                      в созданной карточке
                    </Link>
                  </Text>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col span={10} className="button-wrapper-white">
                <Button type="primary" htmlType="submit" disabled={ownCandidate?._id}>
                  Сохранить
                </Button>
              </Col>
              <Col span={12} className="button-wrapper-gray" offset={2}>
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={
                    !state.selectedCandidate ||
                    state.selectedCandidate?.activeStatus ||
                    ownCandidate?.activeStatus
                  }
                  onClick={handleReuse}
                >
                  Использовать повторно
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <UtmModal
          open={isModalVisible}
          onOk={handleReuseCandidate}
          onCancel={() => setIsModalVisible(false)}
          form={utmForm}
          requestType={state.utms.requestType}
          utmMedium={state.utms.utm_medium}
          onRequestTypeChange={handleSetRequestType}
          onUtmMediumChange={handleSetUtmMedium}
        />
        <ReuseModalForm
          list={vacancies}
          loading={isLoadingReuse}
          isVisible={isReuseModalVisible}
          onClose={() => setIsReuseModalVisible(false)}
          onOk={handleReuseCandidateWithActiveVacancy}
        />
      </Spin>
    </div>
  )
}

ProfileCreate.propTypes = {
  onSave: PropTypes.func.isRequired
}

export default ProfileCreate
