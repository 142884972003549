import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { colSettings } from '../../../constants'

function CurrentOrganizationItem({
  label = 'Где работает',
  wrapperClassName = '',
  autoComplete = 'on'
}) {
  return (
    <Form.Item
      label={label}
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="currentOrganization"
      className={wrapperClassName}
    >
      <Input placeholder="Организация" autoComplete={autoComplete} />
    </Form.Item>
  )
}

export default CurrentOrganizationItem

CurrentOrganizationItem.propTypes = {
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  autoComplete: PropTypes.string
}
