import { Typography, Button, message, Modal, Input, Form } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
const { Text } = Typography
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { useMutateAddOrgStructure } from '../../../../../api/orgStructure'
import { FolderFilled } from '@ant-design/icons'
import { colSettings } from '../../../../../constants'
import orgEditUnitImg from '../../../../../img/orgStructure/edit.svg'

function EditUnit({ selectedUnits }) {
  const orgStructure = useContext(OrgStructureContext)
  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [value, setValue] = useState('')

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateAddOrgStructure()

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
      setIsModalVisible(false)
      setValue('')
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  useEffect(() => {
    if (selectedUnits && isModalVisible) {
      form.setFieldsValue({ name: selectedUnits?.[selectedUnits.length - 1]?.name })
    }
  }, [selectedUnits, form, isModalVisible])

  const handleClose = () => setIsModalVisible(false)
  const setUnit = (data, layerIndex = 0) => {
    if (layerIndex === selectedUnits.length) {
      data.name = value
      data.deleted = false
      return null
    }

    const unit = data?.children?.find(item => item._id === selectedUnits[layerIndex]._id)
    setUnit(unit, layerIndex + 1)
  }
  const handleSave = () => {
    const units = { children: [...orgStructure.units] }
    setUnit(units)
    const data = {
      layers: orgStructure.layers,
      units: units.children
    }
    mutateOrgStructure(data)
  }

  if (!selectedUnits?.length) return null
  return (
    <div className="org-layer-action">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsModalVisible(true)}
      >
        <img src={orgEditUnitImg} alt="button edit unit" />
        <Text>Редактировать</Text>
      </Button>
      <Modal
        title={
          <div className="modal-title">
            <FolderFilled />
            Редактировать
          </div>
        }
        open={isModalVisible}
        onOk={handleSave}
        onCancel={handleClose}
        className=""
        okText="Сохранить"
        okButtonProps={{ loading: isLoadingOrgStructure, disabled: !value }}
        cancelButtonProps={{ disabled: isLoadingOrgStructure }}
        cancelText="Отмена"
      >
        <Form form={form}>
          <Form.Item
            label="Название"
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="name"
            rules={[{ required: true, message: 'Пожалуйста введите название орг единицы' }]}
          >
            <Input size="large" value={value} onChange={({ target }) => setValue(target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default EditUnit
