import React, { Suspense, useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Spin, message } from 'antd'

import { RequiredDataProvider } from '../hooks/useRequiredData.js'
import { useGetSettings } from '../api/setting'
import { initLocale } from '../helpers/date-fns'
import { useQueryWorkflows } from '../api/workflows'
import { useQueryFeatures } from '../api/features'
import { useProfile } from '../hooks/useProfile'

export const RequiredDataLayout = () => {
  const { user } = useProfile()
  const dataPromise = useLoaderData()

  const {
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    data: dataSettings
  } = useGetSettings({
    refetchOnWindowFocus: false,
    enabled: !!Object.keys(user).length
  })

  useEffect(() => {
    if (isSuccessSettings && dataSettings?.data) {
      initLocale(dataSettings?.data?.language)
      window.__localeId__ = dataSettings?.data?.language
    } else if (isErrorSettings) {
      message.error('Ошибка получения настроек')
    }
  }, [dataSettings, isErrorSettings, isSuccessSettings])

  const {
    // isSuccess: isSuccessWorkflows,
    isError: isErrorWorkflows,
    data: dataWorkflows
  } = useQueryWorkflows({
    enabled: !!Object.keys(user).length
  })

  useEffect(() => {
    if (isErrorWorkflows) {
      message.error('Ошибка получения процессов найма')
    }
  }, [isErrorWorkflows])

  const {
    // isSuccess: isSuccessFeatures,
    isError: isErrorFeatures,
    data: dataFeatures
  } = useQueryFeatures({
    enabled: !!Object.keys(user).length
  })
  useEffect(() => {
    if (isErrorFeatures) {
      message.error('Ошибка получения списка фич')
    }
  }, [isErrorFeatures])

  return (
    <Suspense fallback={<Spin size="large" className="full-screen-spin" />}>
      <RequiredDataProvider
        settings={dataPromise?.settings || dataSettings?.data}
        features={dataPromise?.features || dataFeatures?.data}
        workflows={dataWorkflows?.data || dataPromise?.workflows}
      />
    </Suspense>
  )
}
