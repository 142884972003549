const GRID_GAP = 5
const HANDLERS_GRID_GAP = 20

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
export const reorderHandlers = ({ list, source, destination }) => {
  const current = [...list[source.droppableId]]
  const next = [...(list[destination.droppableId] || [])]
  const target = current[source.index]

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index)
    return {
      ...list,
      [source.droppableId]: reordered.map((item, index) => {
        item.appearance = item.appearance || {}
        item.appearance.x = index + 1
        return item
      })
    }
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1)
  // insert into next
  next.splice(destination.index, 0, target)

  return {
    ...list,
    [source.droppableId]: current,
    [destination.droppableId]: next.map((item, index) => {
      item.appearance = item.appearance || {}
      item.appearance.x = index + 1
      item.appearance.y = Number(destination.droppableId)
      return item
    })
  }
}
export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: GRID_GAP,
  margin: `0 0 ${GRID_GAP}px 0`,
  // borderBottom: 'none!important',
  // border: 'none!important',

  // change background colour if dragging
  background: isDragging ? 'lightblue' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightgrey' : 'white',
  padding: GRID_GAP
})

export const getHandlersItemStyle = (isDragging, draggableStyle, handlerStyles) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '3px 15px',
  margin: '0 20px 20px 0',
  textAlign: 'center',
  textWrap: 'nowrap',
  minWidth: handlerStyles?.width || 'auto',
  border: `1px solid ${
    (handlerStyles?.color !== 'transparent' && handlerStyles?.color) || 'black'
  }`,
  borderRadius: 6,
  color:
    handlerStyles?.fill && handlerStyles?.color !== 'transparent'
      ? 'white'
      : (handlerStyles?.color !== 'transparent' && handlerStyles?.color) || 'black',
  // change background colour if dragging
  background: isDragging
    ? 'lightblue'
    : handlerStyles?.fill && handlerStyles?.color !== 'transparent'
    ? handlerStyles?.color
    : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

export const getHandlersListStyle = isDraggingOver => ({
  border: '1px solid lightgrey',
  minHeight: 51,
  background: isDraggingOver ? 'lightgrey' : 'white',
  padding: HANDLERS_GRID_GAP,
  paddingBottom: 0,
  marginBottom: GRID_GAP,
  flexWrap: 'wrap'
})
