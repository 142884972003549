import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/**
 * Обертка для ссылок
 * Если передан параметр 'to' то оборачивает контент в <Link /> в противном случае в тэг <a />
 *
 * @param props
 * @returns {*}
 * @constructor
 * @example:
 *   <LinkWrapper to={disabled ? null : `/item/${id}`} className='item'>
 *      item content
 *   </LinkWrapper>
 */
function LinkWrapper(props) {
  const Component = props.to ? Link : 'a'
  return <Component {...props}>{props.children}</Component>
}

export default LinkWrapper

LinkWrapper.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}
LinkWrapper.defaultProps = {
  to: null,
  children: null
}
