import { format, startOfWeek, set } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { enUS, ru } from 'date-fns/locale'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { appConfig } from '../constants/appConfig'

export const locales = { enUS, ru }
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekday)
dayjs.extend(localeData)
const language = {
  en: import('dayjs/locale/en'),
  ru: import('dayjs/locale/ru')
}
export const initLocale = (locale = 'ru') => {
  language[locale].then(() => {
    dayjs.locale(locale)
  })
}

export const formatLocale = (date, formatStr = appConfig.formats.shortDateFns, options) =>
  format(new Date(date), formatStr, {
    ...options,
    locale: locales[window.__localeId__ || 'ru']
  })

export const startOfWeekLocale = (date, options) =>
  startOfWeek(date, {
    ...options,
    weekStartsOn: 1
  })

export const getOffset = zone => getTimezoneOffset(zone) / 60_000

export const splitTimeAndSetDate = (time = '') =>
  set(new Date(), {
    hours: time.split(':')?.[0],
    minutes: time.split(':')?.[1],
    seconds: time.split(':')?.[2] || 0
  })

const ruTz = [
  {
    name: 'Asia/Anadyr',
    displayName: 'Анадырь'
  },
  {
    name: 'Asia/Barnaul',
    displayName: 'Барнаул'
  },
  {
    name: 'Asia/Chita',
    displayName: 'Чита'
  },
  {
    name: 'Asia/Irkutsk',
    displayName: 'Иркутск'
  },
  {
    name: 'Asia/Kamchatka',
    displayName: 'Камчатка'
  },
  {
    name: 'Asia/Krasnoyarsk',
    displayName: 'Красноярск'
  },
  {
    name: 'Asia/Magadan',
    displayName: 'Магадан'
  },
  {
    name: 'Asia/Novokuznetsk',
    displayName: 'Новокузнетцк'
  },
  {
    name: 'Asia/Novosibirsk',
    displayName: 'Новосибирск'
  },
  {
    name: 'Asia/Omsk',
    displayName: 'Омск'
  },
  {
    name: 'Asia/Sakhalin',
    displayName: 'Сахалин'
  },
  {
    name: 'Asia/Srednekolymsk',
    displayName: 'Среднеколымск'
  },
  {
    name: 'Asia/Tomsk',
    displayName: 'Томск'
  },
  {
    name: 'Asia/Vladivostok',
    displayName: 'Владивосток'
  },
  {
    name: 'Asia/Yakutsk',
    displayName: 'Якутск'
  },
  {
    name: 'Asia/Yekaterinburg',
    displayName: 'Екатеринбург'
  },
  {
    name: 'Europe/Astrakhan',
    displayName: 'Астрахань'
  },
  {
    name: 'Europe/Kaliningrad',
    displayName: 'Калининград'
  },
  {
    name: 'Europe/Kirov',
    displayName: 'Киров'
  },
  {
    name: 'Europe/Moscow',
    displayName: 'Москва',
    defaultActive: true
  },
  {
    name: 'Europe/Samara',
    displayName: 'Самара'
  },
  {
    name: 'Europe/Saratov',
    displayName: 'Саратов'
  },
  {
    name: 'Europe/Simferopol',
    displayName: 'Симферополь'
  },
  {
    name: 'Europe/Ulyanovsk',
    displayName: 'Ульяновск'
  },
  {
    name: 'Europe/Volgograd',
    displayName: 'Волгоград'
  }
]
export const tz = ruTz
  .map(e => ({
    ...e,
    utc: dayjs.tz(new Date(), e.name).format('Z'),
    offset: dayjs.tz(new Date(), e.name).utcOffset()
  }))
  .filter(e => e.displayName)
  .sort((a, b) =>
    a.offset > b.offset ? 1 : a.offset < b.offset ? -1 : a.displayName > b.displayName ? 1 : -1
  )
export const activeTzName = tz.find(z => z.defaultActive).name
