import React from 'react'
import { Switch, Form, Col, Row } from 'antd'
import { colSettings } from '../../../constants'

function ActiveFlagItem({
  size = 'middle',
  disabled = false,
  labelCol = colSettings.six,
  wrapperCol = colSettings.full
}) {
  return (
    <Row justify="space-between" align="baseline">
      <Col className="text-center">Активен:</Col>
      <Form.Item
        // label="Активен"
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        name="active"
        // labelAlign="left"
        valuePropName="checked"
        initialValue
      >
        <Switch size={size} disabled={disabled} />
      </Form.Item>
    </Row>
  )
}
export default ActiveFlagItem
