import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import 'animate.css'
import './Animate.less'

function HorizontalSlideTwoBlocks({ showSecondaryBlock, primaryBlock, secondaryBlock }) {
  const [primaryClass, setPrimaryClass] = useState('')
  const [secondaryClass, setSecondaryClass] = useState('hidden')

  useEffect(() => {
    if (showSecondaryBlock) {
      setPrimaryClass('animate__fadeOutLeft')
      setSecondaryClass('animate__fadeInRight')
    } else if (secondaryClass !== 'hidden') {
      setPrimaryClass('animate__fadeInLeft')
      setSecondaryClass('animate__fadeOutRight')
      setTimeout(() => setSecondaryClass('hidden'), 500) // в конце анимации прячем блок, длительность анимации 0.5s прописана в ./Animate.less
    }
  }, [secondaryClass, showSecondaryBlock])

  return (
    <Row gutter={48} className="relative-block">
      <Col span={24} className={`animate__animated ${primaryClass}`}>
        {primaryBlock}
      </Col>
      <Col span={24} className={`animate__animated animate-secondary ${secondaryClass}`}>
        {secondaryBlock}
      </Col>
    </Row>
  )
}
export default HorizontalSlideTwoBlocks
