import React from 'react'
import { Form, Input } from 'antd'
import { colSettings } from '../../../../../constants'

const ProfileUrlItem = ({ disabled = false }) => {
  return (
    <Form.Item
      label="Ссылка на резюме"
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="profileUrl"
    >
      <Input size="middle" placeholder="http://example.ru/" disabled={disabled} />
    </Form.Item>
  )
}

export default ProfileUrlItem
