import { atom } from 'recoil'
import { initialPagination } from '../helpers'

//  инициализация фильтров из 'localStorage'
const filteredInfo = localStorage.getItem('filterModel') || '{}'
const sortedInfo = localStorage.getItem('sortModel') || '{}'
const workflowFilteredInfo = localStorage.getItem('workflowFilterModel') || '{}'
const workflowSortedInfo = localStorage.getItem('workflowSortModel') || '{}'
const vacancyFilteredInfo = localStorage.getItem('vacancyFilterModel') || '{}'
const vacancySortedInfo = localStorage.getItem('vacancySortModel') || '{}'

export const initPageSettings = {
  // кандидаты рекрутера
  inbox: {
    current: initialPagination.current,
    pageSize: initialPagination.pageSize,
    search: '',
    mine: false,
    activeTab: ''
  },
  // Настройка воронки
  workflow: {
    current: initialPagination.current,
    pageSize: initialPagination.pageSize,
    search: '',
    activeTab: ''
  },
  // вакансии
  vacancy: {
    current: initialPagination.current,
    pageSize: initialPagination.pageSize,
    search: '',
    activeTab: ''
  },
  // поиск кандидата
  find: {
    findNumber: '',
    activeTab: ''
  },
  // кандидаты директора
  hiringManager: {
    search: '',
    activeTab: ''
  },
  // пропсы прокинутые в компонент извне
  customProperties: {
    menuTheme: 'light'
  }
}
const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const findSettingsAtom = atom({
  key: 'findSettings',
  default: initPageSettings.find
})

export const hiringManagerSettingsAtom = atom({
  key: 'hiringManagerSettings',
  default: initPageSettings.hiringManager
})

export const inboxSettingsAtom = atom({
  key: 'inboxSettings',
  default: initPageSettings.inbox
})

export const inboxFiltersAtom = atom({
  key: 'inboxFilter',
  default: JSON.parse(filteredInfo),
  effects: [localStorageEffect('filterModel')]
})

export const inboxSorterAtom = atom({
  key: 'inboxSort',
  default: JSON.parse(sortedInfo),
  effects: [localStorageEffect('sortModel')]
})

export const workflowSettingsAtom = atom({
  key: 'workflowSettings',
  default: initPageSettings.workflow
})

export const workflowFiltersAtom = atom({
  key: 'workflowFilter',
  default: JSON.parse(workflowFilteredInfo),
  effects: [localStorageEffect('workflowFilterModel')]
})

export const workflowSorterAtom = atom({
  key: 'workflowSort',
  default: JSON.parse(workflowSortedInfo),
  effects: [localStorageEffect('workflowSortModel')]
})

export const vacancySettingsAtom = atom({
  key: 'vacancySettings',
  default: initPageSettings.vacancy
})

export const customPropertiesSettingsAtom = atom({
  key: 'customProperties',
  default: initPageSettings.customProperties
  // effects: [
  //   ({ onSet }) => {
  //     onSet(newID => {
  //       console.debug(
  //         '%c Current user ID:',
  //         'color: green; font-weight: bold; font-size: 22px',
  //         newID
  //       )
  //     })
  //   }
  // ]
})

export const vacancyFiltersAtom = atom({
  key: 'vacancyFilter',
  default: JSON.parse(vacancyFilteredInfo),
  effects: [localStorageEffect('vacancyFilterModel')]
})

export const vacancySorterAtom = atom({
  key: 'vacancySort',
  default: JSON.parse(vacancySortedInfo),
  effects: [localStorageEffect('vacancySortModel')]
})
