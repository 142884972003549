import React, { useState, useCallback } from 'react'
import { Layout } from 'antd'

import { Header, Sidebar } from './Components'
import Routes from '../../Routes'
import { modules } from '../../constants'
import SeedDataModal from './Components/SeedDataModal/SeedDataModal'

const { Content } = Layout

const MassRecruitment = () => {
  const [collapsed, setCollapsed] = useState(false)
  const toggle = useCallback(() => setCollapsed(prevValue => !prevValue), [])

  return (
    <>
      <Sidebar collapsed={collapsed} />
      <Layout style={{ minHeight: '100vh' }}>
        <Header toggle={toggle} collapsed={collapsed} />
        <Content>
          <Routes module={modules.massRecruitment} />
        </Content>
      </Layout>
      <SeedDataModal />
    </>
  )
}

export default MassRecruitment
