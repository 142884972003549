import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Button, Col, Divider, Form, message, Modal, Row, Typography } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import { appConfig } from '../../../../constants/appConfig'
import { passedTimeRu } from '../../../../helpers/time'
import reloadImg from '../../../../img/reload.svg'
import { RequestTypeItem, UtmMediumItem, UtmSourceItem } from '../FormItems/CandidateProfileItems'
import { simpleReducer } from '../../../../helpers'
import { useReferences } from '../../../../hooks/useReferences'
import { useLogoutJobSite, useSaveUtms } from '../../../../api/integrations'

const { Text } = Typography
function IntegrationsStatus({ data, onRefresh, jobSiteData }) {
  const [isOpenModalSettingsIsOpen, setIsOpenModalSettingsIsOpen] = useState(false)
  const [form] = Form.useForm()
  const initUtms = {
    requestType: null,
    utm_medium: null
  }
  const [utms, setUtms] = useReducer(simpleReducer, initUtms)
  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })

  const {
    mutate: saveUtms,
    isSuccess: isSuccesSaveUtms,
    isError: isErrorSaveUtms,
    isLoading: isLoadingSaveUtms
  } = useSaveUtms()
  useEffect(() => {
    if (isSuccesSaveUtms) {
      message.success('Источники успешно сохранены')
    } else if (isErrorSaveUtms) {
      message.error('Ошибка сохранения источников')
    }
  }, [isSuccesSaveUtms, isErrorSaveUtms])

  const {
    mutate: mutateLogoutJobSite,
    isSuccess: isSuccesLogoutJobSite,
    isError: isErrorLogoutJobSite,
    isLoading: isLoadingLogoutJobSite
  } = useLogoutJobSite()
  useEffect(() => {
    if (isSuccesLogoutJobSite) {
      message.success('Вы успешно вышли из аккаунта')
    } else if (isErrorLogoutJobSite) {
      message.error('Ошибка выхода из аккаунта')
    }
  }, [isSuccesLogoutJobSite, isErrorLogoutJobSite])

  useEffect(() => {
    setUtms({
      requestType: data?.settings?.tags?.requestType,
      utm_medium: data?.settings?.tags?.utmMedium
    })
    form.setFieldsValue({
      requestType: data?.settings?.tags?.requestType || null,
      utm_medium: data?.settings?.tags?.utmMedium || null,
      utm_source: data?.settings?.tags?.utmSource || null
    })
  }, [data?.settings?.tags, form])
  const handleSetRequestType = value => {
    setUtms({
      requestType: value,
      utm_medium: null
    })
    form.setFieldsValue({
      utm_medium: null,
      utm_source: null
    })
  }
  const handleSetUtmMedium = value => {
    setUtms({
      ...utms,
      utm_medium: value
    })
    form.setFieldsValue({
      utm_source: null
    })
  }

  const handleLogout = useCallback(() => {
    mutateLogoutJobSite({ jobId: jobSiteData?.id })
  }, [mutateLogoutJobSite, jobSiteData])

  const handleSaveUtms = useCallback(() => {
    const { requestType, utm_medium, utm_source } = form.getFieldsValue()
    saveUtms({
      integrationId: jobSiteData.id,
      data: { requestType, utmMedium: utm_medium, utmSource: utm_source }
    })
    setIsOpenModalSettingsIsOpen(false)
  }, [form, jobSiteData.id, saveUtms])

  return (
    <Row align="middle" justify="space-between" className="IntegrationsStatus mt-3 ">
      <Col className=" mb-3">
        <Row gutter={[36, 12]}>
          <Col>
            {appConfig.integrationStatuses.online === data?.status ? (
              <Text type="success">Онлайн</Text>
            ) : (
              <Row align="middle">
                <Text type="danger">Оффлайн</Text>
                <img
                  src={reloadImg}
                  alt="reload"
                  className="cursor-pointer ml-3"
                  onClick={onRefresh}
                />
              </Row>
            )}
          </Col>
          <Col>
            Последний ответ:{' '}
            <Text type="secondary">
              {data?.lastRespond
                ? passedTimeRu(data.lastRespond)
                : `${
                    jobSiteData.id === appConfig.integrationSites.hrm ? 'кандидаты' : 'отклики'
                  } не поступали`}
            </Text>
          </Col>
        </Row>
      </Col>
      <Row gutter={[12, 6]} align="middle" className=" mb-3">
        <Col>
          <Button type="primary" onClick={() => setIsOpenModalSettingsIsOpen(true)}>
            <SettingOutlined />
            <span className="ml-3">Настройки источника</span>
          </Button>
        </Col>
        {jobSiteData.id === appConfig.integrationSites.hrm &&
          appConfig.integrationStatuses.online === data?.status && (
            <Col>
              <Button type="primary" onClick={handleLogout} loading={isLoadingLogoutJobSite}>
                Выйти из аккаунта HRM
              </Button>
            </Col>
          )}
      </Row>
      <Modal
        title={<div className="no-gutters block-title">Настройки источника</div>}
        open={isOpenModalSettingsIsOpen}
        onOk={handleSaveUtms}
        onCancel={() => setIsOpenModalSettingsIsOpen(false)}
        className="userModalForm"
        okText="Сохранить"
        okButtonProps={{ loading: isLoadingSaveUtms }}
        cancelButtonProps={{ disabled: isLoadingSaveUtms, type: 'link' }}
        cancelText="Отмена"
      >
        <Divider rootClassName="small" />
        <Form form={form}>
          <RequestTypeItem value={utms?.requestType} onChange={handleSetRequestType} />
          <UtmMediumItem value={utms?.utm_medium} onChange={value => handleSetUtmMedium(value)} />
          {jobSiteData.id !== appConfig.integrationSites.hrm && (
            <UtmSourceItem utmMedium={utms?.utm_medium} />
          )}
        </Form>
      </Modal>
    </Row>
  )
}
export default IntegrationsStatus
