import { useQuery } from 'react-query'
import { api } from './api'

export const AMP_KEY = 'amp'
const BK_PREFIX = 'bk'

const fetchAmpCandidatesMine = ({ queryKey }) => {
  const [, /* key */ data] = queryKey
  const params = { ...data }
  if (params?.expired < 0) {
    delete params.expired
  }
  if (params?.period) {
    delete params.period
  }
  if (params?.range) {
    delete params.range
  }
  if (params?.vacancies) {
    params.vacancies = params.vacancies.join()
  }
  return api.get({ path: `${BK_PREFIX}/${AMP_KEY}/mine`, params })
}

const fetchAmpAppointments = ({ queryKey }) => {
  const [, /* key */ { start, end, departmentId, action }] = queryKey
  const params = { start, end, ...(action && { action }), ...(departmentId && { departmentId }) }
  const urlParams = new URLSearchParams(params)
  return api.get({ path: `${BK_PREFIX}/${AMP_KEY}/appointments?${urlParams.toString()}` })
}

export function useQueryAmpCandidatesMine(query, options) {
  return useQuery([AMP_KEY, query], fetchAmpCandidatesMine, options)
}

export function useQueryAmpAppointments(data, options) {
  return useQuery([AMP_KEY, data], fetchAmpAppointments, options)
}
