import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { parseToArrayWords } from '../../../helpers'

const FioItem = ({ labelColSpan, wrapperColSpan, size, required, autoComplete }) => {
  const NAME_VALIDATION_MESSAGE = 'Укажите фамилию, имя, отчество через пробел'

  const validateName = async (rule, value) => {
    let isLessTwoWords = false

    if (value) {
      const words = parseToArrayWords(value)
      isLessTwoWords = words.length < 2
    }

    if (isLessTwoWords) {
      throw new Error(NAME_VALIDATION_MESSAGE)
    }
  }

  return (
    <Form.Item
      label="ФИО"
      labelCol={{ span: labelColSpan }}
      wrapperCol={{ span: wrapperColSpan }}
      name="name"
      rules={[{ required, message: NAME_VALIDATION_MESSAGE }, { validator: validateName }]}
    >
      <Input size={size} placeholder="Фамилия Имя Отчество" autoComplete={autoComplete} />
    </Form.Item>
  )
}

export default FioItem

FioItem.defaultProps = {
  labelColSpan: 24,
  wrapperColSpan: 24,
  size: 'middle',
  required: false,
  autoComplete: 'on'
}
FioItem.propTypes = {
  labelColSpan: PropTypes.number,
  wrapperColSpan: PropTypes.number,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  required: PropTypes.bool,
  autoComplete: PropTypes.string
}
