import {
  Typography,
  Button,
  message,
  Modal,
  Col,
  Row,
  Table,
  Space,
  Form,
  Select,
  Input
} from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'

import UserModalForm from '../../User/UserModalForm'
import { appConfig } from '../../../../../constants/appConfig'
import { useUserPositions } from '../../../../../api/userPositions'
import { selectSearchFilter, sortBy } from '../../../../../helpers'
import { useGetPartners, useMutateUpdatePartnerById } from '../../../../../api/partners'
import { renderNameWithEmail } from '../../../../../helpers/department'
import { colSettings } from '../../../../../constants'
import { useUsers } from '../../../../../api/users'
import { SearchFilter } from '../../index'

const { confirm } = Modal
const { Option } = Select
const { Text } = Typography

function AddRMP() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isOpenNewRMP, setIsOpenNewRMP] = useState(false)
  const [isOpenChangeRMP, setIsOpenChangeRMP] = useState(false)
  const [selectedOperationRegion, setSelectedOperationRegion] = useState('')
  const [partners, setPartners] = useState([])
  const { data: dataUserPositions, isError: isErrorUserPositions } = useUserPositions()
  const [form] = Form.useForm()

  const userPositions = useMemo(
    () => dataUserPositions?.data?.sort(sortBy('name')) || [],
    [dataUserPositions?.data]
  )
  useEffect(() => {
    if (isErrorUserPositions) {
      message.error('Ошибка получения позиций пользователя')
    }
  }, [isErrorUserPositions])

  const { data: dataPartners, isError: isErrorPartners } = useGetPartners()

  useEffect(() => {
    setPartners(dataPartners?.data || [])
  }, [dataPartners?.data])

  useEffect(() => {
    if (isErrorPartners) {
      message.error('Ошибка получения операционных регионов')
    }
  }, [isErrorPartners])

  const rmpPosition = useMemo(
    () => userPositions.find(p => p.name === appConfig.rolesLabels.partner),
    [userPositions]
  )

  const { data: dataManagers, isError: isErrorManagers } = useUsers(
    { filters: { roles: [appConfig.roles.partner] } },
    { enabled: !!rmpPosition?._id }
  )
  const rmpList = useMemo(
    () => dataManagers?.data?.sort(sortBy('name')) || [],
    [dataManagers?.data]
  )
  useEffect(() => {
    if (isErrorManagers) {
      message.error('Ошибка загрузки списка партнеровй')
    }
  }, [isErrorManagers])

  const handleClose = () => setIsModalVisible(false)
  const handleCloseCreateRmp = useCallback(() => setIsOpenNewRMP(false), [])

  const {
    isSuccess: isSuccessUpdatePartnerById,
    isError: isErrorUpdatePartnerById,
    mutate: mutateUpdatePartnerById,
    isLoading: isLoadingUpdatePartnerById
  } = useMutateUpdatePartnerById()

  useEffect(() => {
    if (isSuccessUpdatePartnerById) {
      message.success('Данные успешно сохранены')
    } else if (isErrorUpdatePartnerById) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessUpdatePartnerById, isErrorUpdatePartnerById])

  const editPartner = useCallback(
    item => {
      form.setFieldsValue({
        name: item.name,
        rmp: item.partner?._id
      })
      setIsOpenChangeRMP(true)
    },
    [form]
  )

  const handleChangeOperationRegion = useCallback(item => {
    setSelectedOperationRegion(item)
  }, [])

  const handleUpdateUser = useCallback(
    data => {
      mutateUpdatePartnerById({
        id: data._id,
        data: { operatingRegion: selectedOperationRegion }
      })
      setIsOpenNewRMP(false)
    },
    [mutateUpdatePartnerById, selectedOperationRegion]
  )
  const handleSave = useCallback(() => {
    const values = form.getFieldsValue()
    mutateUpdatePartnerById({ id: values.rmp, data: { operatingRegion: values.name } })
    setIsOpenChangeRMP(false)
  }, [form, mutateUpdatePartnerById])

  const deletePartner = useCallback(
    item => {
      confirm({
        title: 'Удаление РМП',
        content: (
          <div>
            Вы действительно хотите удалить РМП у операционного региона: <b>{item.name}</b>?
          </div>
        ),
        okText: 'Удалить',
        cancelText: 'Отмена',
        onOk: () =>
          mutateUpdatePartnerById({ id: item.partner._id, data: { operatingRegion: null } })
      })
    },
    [mutateUpdatePartnerById]
  )

  const partnerNameRender = ({ isInclude, text, searchText }) => {
    return isInclude ? (
      <Col>
        <Row>
          <Text>
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.name ? text.name?.toString() : ''}
            />
          </Text>
        </Row>
        <Row>
          <Text type="secondary">
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text.email ? text.email.toString() : ''}
            />
          </Text>
        </Row>
      </Col>
    ) : (
      renderNameWithEmail(text)
    )
  }

  const columns = [
    {
      title: 'Операционный регион',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a?.name > b?.name ? 1 : -1),
      showSorterTooltip: false
    },
    {
      title: 'ФИО + email',
      dataIndex: 'partner',
      key: 'partner',
      sorter: (a, b) => {
        if (!a?.partner) return 1
        return a?.partner?.name > b?.partner?.name ? 1 : -1
      },
      showSorterTooltip: false,
      ...SearchFilter('partner', 'по ФИО или email', partnerNameRender)
    },
    {
      title: 'Действия',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="no-padding"
            onClick={() => editPartner(record)}
            disabled={isLoadingUpdatePartnerById}
            type="link"
          >
            редактировать
          </Button>
          {record.partner?._id && (
            <>
              /
              <Button
                className="no-padding"
                onClick={() => deletePartner(record)}
                disabled={isLoadingUpdatePartnerById}
                type="link"
              >
                удалить
              </Button>
            </>
          )}
        </Space>
      )
    }
  ]

  const handleTableChange = useCallback(() => {
    setPartners([...partners])
  }, [partners])

  return (
    <div className="">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsModalVisible(true)}
      >
        <Text>РМП</Text>
      </Button>
      <Modal
        width={980}
        style={{ top: '50px' }}
        title={<div className="modal-title">РМП</div>}
        open={isModalVisible}
        onCancel={handleClose}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ disabled: isLoadingUpdatePartnerById }}
        cancelText="Закрыть"
      >
        <Col>
          <Row justify="end">
            <Button type="primary" size="medium" onClick={() => setIsOpenNewRMP(true)}>
              Добавить РМП
            </Button>
          </Row>
          <Row justify="space-between" className="mt-3">
            <Table
              columns={columns}
              dataSource={partners}
              scroll={{ y: 'calc(100vh - 350px)' }}
              pagination={false}
              onChange={handleTableChange}
            />
          </Row>
        </Col>
        {isOpenNewRMP && (
          <UserModalForm
            roleDisable
            positionDisable
            roles={[appConfig.roles.partner, appConfig.roles.manager]}
            withoutButton
            isOpen={isOpenNewRMP}
            afterSave={handleUpdateUser}
            onClose={handleCloseCreateRmp}
            userPositions={userPositions}
            position={rmpPosition?._id}
            operationRegions={partners}
            onChangeOperationRegion={handleChangeOperationRegion}
            modules={[appConfig.modules.crewman, appConfig.modules.amp]}
          />
        )}
      </Modal>
      <Modal
        title={<div className="modal-title">Сменить РМП</div>}
        open={isOpenChangeRMP}
        onCancel={() => setIsOpenChangeRMP(false)}
        onOk={handleSave}
        okButtonProps={{ disabled: isLoadingUpdatePartnerById }}
        cancelButtonProps={{ disabled: isLoadingUpdatePartnerById }}
        cancelText="Закрыть"
        okText="Сохранить"
      >
        <Form form={form}>
          <Form.Item
            label="Операционный регион"
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="name"
            labelAlign="left"
            required
          >
            <Input size="large" disabled />
          </Form.Item>
          <Form.Item
            label="РМП"
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            labelAlign="left"
            name="rmp"
            rules={[
              {
                required: true,
                message: 'Выберите РМП'
              }
            ]}
          >
            <Select
              size="large"
              placeholder="Выберите РМП"
              showSearch
              filterOption={selectSearchFilter}
            >
              {rmpList.map(rmp => (
                <Option key={rmp._id} value={rmp._id}>
                  {rmp.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddRMP
