import React, { useReducer, useEffect, useMemo, useCallback } from 'react'
import {
  Form,
  AutoComplete,
  message,
  Spin,
  Input,
  Row,
  Col,
  Divider,
  Typography,
  Select
} from 'antd'

import { useDadataSearch } from '../../../../../api/dadata'
import { debounce, simpleReducer } from '../../../../../helpers'
import { colSettings } from '../../../../../constants'
import './DadataAddressItem.less'
import { tz, activeTzName } from '../../../../../helpers/date-fns'

const valueForHandleAddress = 'Указать данные вручную'
const { Text } = Typography
const { Option } = Select

const DadataAddressItem = ({
  onChange,
  address = '',
  label = 'Адрес',
  onResetAddress,
  disabled = false,
  isHandleAddress = false,
  form,
  isEditMode = false
}) => {
  const [state, setState] = useReducer(simpleReducer, {
    address,
    suggestions: [],
    isShowHandleForm: false,
    timeZone: activeTzName
  })

  const handleChange = useCallback(
    value => {
      const data = state.suggestions?.find(s => s.value === value)?.data
      if (value === valueForHandleAddress) {
        if (form) {
          form.setFieldsValue({
            address: state.address,
            timeZone: form.getFieldValue('timeZone') || activeTzName
          })
        }
        setState({ isShowHandleForm: true })
        return
      }
      setState({ address: value, isShowHandleForm: false })
      onChange?.(data)
    },
    [onChange, state.suggestions, state.address, form]
  )

  const {
    status: statusDadataSearch,
    data: dadataSearch,
    isLoading
  } = useDadataSearch(state.address, { enabled: !!state.address })

  useEffect(() => {
    if (statusDadataSearch === 'success') {
      setState({
        suggestions: dadataSearch.data?.suggestions.filter(
          s => s?.data?.geo_lat && s?.data?.geo_lon
        )
      })
      if (isEditMode && dadataSearch.data?.suggestions?.length) {
        onChange(dadataSearch.data?.suggestions?.[0]?.data)
      }
    } else if (statusDadataSearch === 'error') {
      message.error('Ошибка получения подсказок Dadata')
    }
  }, [dadataSearch, statusDadataSearch, isEditMode, onChange])

  const getSuggestions = useMemo(
    () =>
      debounce(query => {
        onResetAddress?.()
        setState({ address: query })
      }, 800),
    [onResetAddress]
  )

  const options = useMemo(
    () => [
      ...(isHandleAddress && !state.isShowHandleForm
        ? [
            {
              value: valueForHandleAddress,
              className: 'red' //нужно для применения стилей
            }
          ]
        : []),
      ...state.suggestions
    ],
    [isHandleAddress, state.suggestions, state.isShowHandleForm]
  )

  return (
    <div className="DadataAddressItem">
      <Form.Item
        label={label}
        labelCol={colSettings.full}
        wrapperCol={colSettings.full}
        name="address"
        value={state.address}
        required
        rules={[
          {
            required: true,
            message: 'Укажите адрес'
          }
        ]}
      >
        <AutoComplete
          options={options}
          onSelect={handleChange}
          onSearch={getSuggestions}
          placeholder="Москва, ул. Ленина, д. 1"
          popupMatchSelectWidth={false}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          disabled={disabled}
        />
      </Form.Item>
      {state.isShowHandleForm && (
        <Row>
          <Col>
            <Divider type="vertical" />
          </Col>
          <Col span={23}>
            <Text type="danger">Ручной ввод данных</Text>
            <Form.Item
              // label="Широта"
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name="latitude"
              className="mt-3"
              value={state.latitude}
              required
              rules={[
                {
                  required: true,
                  message: 'Укажите широту'
                }
              ]}
            >
              <Input
                onChange={({ target }) => setState({ latitude: target.value })}
                placeholder="широта, например 37.620393"
              />
            </Form.Item>
            <Form.Item
              // label="Долгота"
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name="longitude"
              value={state.longitude}
              required
              rules={[
                {
                  required: true,
                  message: 'Укажите долготу'
                }
              ]}
            >
              <Input
                onChange={({ target }) => setState({ longitude: target.value })}
                placeholder="долгота, например 55.75396"
              />
            </Form.Item>
            <Form.Item
              // label="Часовой пояс"
              labelCol={colSettings.full}
              wrapperCol={colSettings.full}
              name="timeZone"
              value={state.timeZone}
              required
              rules={[
                {
                  required: true,
                  message: 'Выберите часовой пояс'
                }
              ]}
            >
              <Select
                defaultValue={activeTzName}
                onChange={(value, item) => setState({ timeZone: item.name })}
                placeholder="часовой пояс"
              >
                {tz.map(zone => (
                  <Option key={zone.name} value={zone.name}>
                    ({zone.utc} UTC) {zone.displayName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default DadataAddressItem
