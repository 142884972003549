import React from 'react'
import { Checkbox, Form } from 'antd'
import { appConfig } from '../../../../../constants/appConfig'

function ModulesItem() {
  return (
    <Form.Item
      label="Тип"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      labelAlign="left"
      required
      name="modules"
      rules={[
        {
          required: true,
          message: 'Выберите тип'
        }
      ]}
    >
      <Checkbox.Group>
        {Object.values(appConfig.modules).map(module => (
          <Checkbox key={module} value={module}>
            {appConfig.modulesLabels[module]}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Form.Item>
  )
}

export default ModulesItem
