import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Col } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { withFeatureToggle } from '../../../../../components/Common/FeatureToggle'
import { appConfig } from '../../../../../constants/appConfig'
import { noOrgStructureName } from '../../../../../constants/orgStructure'

function CurrentOrgUnits({ onOpen, selectedOrgUnits, disabled }) {
  const selectedUnit = useMemo(
    () => selectedOrgUnits?.[selectedOrgUnits.length - 1],
    [selectedOrgUnits]
  )
  return (
    <Row className="currentOrgUnits">
      <Col span={24}>
        <Button onClick={onOpen} disabled={disabled}>
          {selectedUnit ? selectedUnit.name || noOrgStructureName : 'Выбрать оргструктуру'}
          <RightOutlined />
        </Button>
      </Col>
    </Row>
  )
}

CurrentOrgUnits.defaultProps = {
  onOpen: () => {}
}

CurrentOrgUnits.propTypes = {
  onOpen: PropTypes.func,
  selectedOrgUnits: PropTypes.array
}

export default withFeatureToggle(CurrentOrgUnits, appConfig.features.orgLayers)
