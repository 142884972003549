import React, { useContext } from 'react'
import { Form, Select } from 'antd'
import { ConfigContext } from '../../../../../contexts/configContext'
import { colSettings } from '../../../../../constants'

const { Option } = Select

const CitizenshipItem = () => {
  const { references } = useContext(ConfigContext)
  return (
    <Form.Item
      label="Гражданство"
      required
      labelCol={colSettings.full}
      wrapperCol={colSettings.full}
      name="citizenship"
      rules={[
        {
          required: true,
          message: 'Укажите гражданство'
        }
      ]}
    >
      <Select size="middle" placeholder="Выберите гражданство">
        {references.data.citizenships?.map(c => (
          <Option key={c} value={c}>
            {c}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CitizenshipItem
