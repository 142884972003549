import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const CandidatePreferences = ({
  title = 'Заполнено рекрутером:',
  candidate,
  wrapperClass = ''
}) => {
  return (
    <div className={wrapperClass}>
      <Text type="secondary">{title}</Text>
      <div className="item">Желаемая вакансия: {candidate.preferredPosition}</div>
      <div className="item">Желаемая ставка: {candidate?.preferredRate}</div>
      <div className="item">Желаемое место работы: {candidate?.preferredDepartment}</div>
      <div className="item">Время работы: {candidate?.preferredTime?.join(', ') || ''}</div>
      <div className="item">Комментарий для директора: {candidate?.comment}</div>
    </div>
  )
}

export default CandidatePreferences
