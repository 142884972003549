import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

const FioItem = ({ readOnly = false }) => {
  return (
    <Row>
      <Col span={5}>
        <Form.Item label="ФИО" required={true} />
      </Col>
      <Col span={15}>
        <Form.Item
          name="name"
          style={{ marginRight: 10 }}
          rules={[
            {
              required: true,
              message: 'Укажите фамилию имя отчество'
            }
          ]}
        >
          <Input disabled={readOnly} size="middle" placeholder='"Фамилия Имя Отчество' />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default FioItem

FioItem.propTypes = {
  readOnly: PropTypes.bool
}
