import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography
} from 'antd'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { InfoCircleTwoTone } from '@ant-design/icons'

import { colSettings, commonDrawerWidth, modules } from '../../../../constants'
import { WorkflowContext } from '../../../../contexts/workflowContext'
import SlaTime from '../../../../components/Common/SlaTime'
import { appConfig } from '../../../../constants/appConfig'
import { useMutateUpdateWorkflow } from '../../../../api/workflows'
import { RoleItem } from '../FormItems/UserItems'
import { customPropertiesSettingsAtom } from '../../../../recoil/atoms'
import { ConfigContext } from '../../../../contexts/configContext'
import { areArraysIntersect, sortBy, groupBy } from '../../../../helpers'
import EditActionButtons from '../EditActionButtons/EditActionButtons'
import PresetColorPicker from '../../../../components/Common/PresetColorPicker'
import { EditingTitle } from '../index'

const { Option } = Select
const { confirm } = Modal
const { Text } = Typography
const notAvailableRoles = [appConfig.systemRole]
const usingRoles = [
  appConfig.roles.admin,
  appConfig.roles.recruiter,
  appConfig.roles.hiringManager,
  appConfig.roles.manager
]
const usingBKRoles = [
  appConfig.roles.admin,
  appConfig.roles.recruiter,
  appConfig.roles.TU,
  appConfig.roles.partner,
  appConfig.roles.operationDirector
]
function WorkflowStatesDrawer({ onClose, drawerClass }) {
  const { workflow } = useContext(WorkflowContext)
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const { stateName } = useParams()
  const [form] = Form.useForm()
  const [stateActionsRoles, setStateActionsRoles] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [listHandlersChanged, setListHandlersChanged] = useState([])
  const [editableState, setEditableState] = useState([])
  const [slaDuration, setSlaDuration] = useState({
    days: 0,
    hours: 0,
    minutes: 0
  })

  const { mutate: mutateUpdateWorkflow, isLoading: isLoadingUpdateWorkflow } =
    useMutateUpdateWorkflow()

  const selectedState = useMemo(() => workflow?.states?.[stateName], [stateName, workflow])

  useEffect(() => {
    const data = {}
    selectedState?.possibleActions?.map(action => {
      data[typeof action?.name === 'string' ? action?.name : action] =
        action?.roles?.filter(r => !notAvailableRoles.includes(r)) ||
        workflow?.handlers?.[action?.name || action]?.roles?.filter(
          r => !notAvailableRoles.includes(r)
        )
    })
    setStateActionsRoles(data)
    form.setFieldsValue(data)
  }, [selectedState, workflow, form])

  useEffect(() => {
    form.setFieldsValue({
      ...selectedState,
      reuseWhenExpired: selectedState?.reuseWhenExpired || false,
      showInDashboards: selectedState?.showInDashboards || false,
      slaDate: selectedState?.sla?.date || appConfig.workflows.sla[0].key
    })
    setSlaDuration({
      days: selectedState?.sla?.duration?.days || 0,
      hours: selectedState?.sla?.duration?.hours || 0,
      minutes: selectedState?.sla?.duration?.minutes || 0
    })
  }, [form, selectedState])

  const handleChangeSlaTime = useCallback(
    date => {
      setSlaDuration({
        days: date?.days ? Number(date?.days) : null,
        hours: Number(date?.hours),
        minutes: Number(date?.minutes)
      })
      if (!Number(date?.days) && !Number(date?.hours) && !Number(date?.minutes)) {
        form.setFieldsValue({
          reuseWhenExpired: false
        })
      }
    },
    [form]
  )

  const handleFinish = useCallback(
    values => {
      // в "rest" сохранены все измененные пользователем "possibleActions"
      const { slaDate, showInDashboards, showFor, reuseWhenExpired, displayName, ...rest } = values
      const data = {
        showInDashboards,
        showFor,
        reuseWhenExpired,
        displayName,
        sla: {
          duration: {
            days: Number(slaDuration?.days),
            hours: Number(slaDuration?.hours),
            minutes: Number(slaDuration?.minutes)
          },
          date: slaDate,
          operation: 'add'
        }
      }
      const workflowClone = JSON.parse(JSON.stringify(workflow))

      //если роли для "possibleActions" были изменены пользователем то сохраняем обьект
      let possibleActions = []
      workflowClone.states[stateName].possibleActions.forEach(action => {
        let key = typeof action?.name === 'string' ? action?.name : action
        let item = editableState.find(e => e.name === key) || action

        if (rest[key]) {
          if (listHandlersChanged.includes(key)) {
            item = {
              ...item,
              roles: [
                ...rest[key],
                //возвращаем роль "system" если она была в "handlers"
                ...(areArraysIntersect(workflow?.handlers?.[key]?.roles, notAvailableRoles)
                  ? notAvailableRoles
                  : [])
              ]
            }
          } else {
            item = {
              ...workflowClone.handlers[key],
              // чтобы не перезатирать изменения на дефолтные данные
              ...(item || {}),
              name: key,
              roles: [
                ...rest[key],
                //возвращаем роль "system" если она была в "handlers"
                ...(areArraysIntersect(workflow?.handlers?.[key]?.roles, notAvailableRoles)
                  ? notAvailableRoles
                  : [])
              ]
            }
          }
        }
        delete item.executeImmediately
        delete item.currentStep
        possibleActions.push(
          item
          // rest[key] || listHandlersChanged.includes(key) ? { name: key, roles: rest[key] } : action
        )
      })

      workflowClone.states[stateName] = {
        ...workflowClone.states[stateName],
        ...data,
        possibleActions
      }
      if (!slaDuration?.days && !slaDuration?.hours && !slaDuration?.minutes) {
        workflowClone.states[stateName].sla = null
      }
      mutateUpdateWorkflow(workflowClone, {
        onSuccess: () => {
          message.success('Процесс успешно обновлен!')
          setListHandlersChanged([])
          onClose?.()
        },
        onError: () => message.error('Ошибка обновления данных процесса')
      })
    },
    [
      slaDuration,
      mutateUpdateWorkflow,
      stateName,
      workflow,
      onClose,
      editableState,
      listHandlersChanged
    ]
  )

  const roles = useMemo(() => {
    const commonRoles = [appConfig.roles.hiringManager, appConfig.roles.recruiter]
    const bkRoles = [appConfig.roles.partner, appConfig.roles.TU, appConfig.roles.operationDirector]
    return moduleName !== modules.bk ? commonRoles : [...commonRoles, ...bkRoles]
  }, [moduleName])

  const filteredRoles = useMemo(() => {
    switch (stateName) {
      case undefined:
        return []
      case appConfig.statuses.values.rookie: {
        if (
          workflow?.handlers?.[workflow?.defaultAction]?.nextState ===
          appConfig.statuses.values.rookie
        ) {
          return roles.filter(r => r !== appConfig.roles.recruiter)
        } else return roles
      }
      case appConfig.statuses.values.preInterview: {
        if (
          workflow?.handlers?.[workflow?.defaultExternal]?.nextState ===
          appConfig.statuses.values.preInterview
        ) {
          return roles.filter(r => r !== appConfig.roles.hiringManager)
        } else return roles
      }
      default:
        return roles
    }
  }, [roles, stateName, workflow])

  const handleChangeStateProp = useCallback(
    (key, value, name) => {
      const state = editableState?.find(s => s.name === key)

      if (name === 'displayName') {
        state[name] = value
      } else {
        state.appearance = state.appearance || {}
        state.appearance[name] = value
      }
      setEditableState(editableState)
      setListHandlersChanged([...new Set([...listHandlersChanged, key])])
    },
    [editableState, listHandlersChanged]
  )
  const onChangeRole = useCallback(
    (data, key) => setStateActionsRoles({ ...stateActionsRoles, [key]: data }),
    [stateActionsRoles]
  )

  const filteredPossibleActions = useMemo(
    () =>
      selectedState?.possibleActions.filter(
        action =>
          ![workflow?.defaultExternal, workflow?.defaultAction].includes(action?.name || action) &&
          !action?.autoExec &&
          (workflow?.handlers?.[action?.name || action]?.features
            ? areArraysIntersect(workflow.handlers[action?.name || action]?.features, features)
            : true)
      ),
    [
      selectedState?.possibleActions,
      workflow?.defaultExternal,
      workflow?.defaultAction,
      workflow?.handlers,
      features
    ]
  )

  const handleOpenModal = useCallback(() => setIsOpenModal(true), [])

  const setInitState = useCallback(() => {
    const data = filteredPossibleActions?.map(pa =>
      pa?.name ? { ...workflow?.handlers[pa.name], ...pa } : { ...workflow?.handlers[pa], name: pa }
    )
    const groupedList = Object.entries(groupBy(data, 'appearance.y', 0)).map(([, value]) =>
      value.sort(sortBy('appearance.x'))?.map((pa, index) => ({
        ...pa,
        appearance: { ...(pa?.appearance || {}), x: index + 1 }
      }))
    )
    const list = Object.values(groupedList).reduce((prev, cur) => {
      return prev.concat(cur)
    }, [])

    setEditableState(structuredClone(list?.sort(sortBy('appearance.y'))))
  }, [workflow?.handlers, filteredPossibleActions])

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false)
    setInitState()
  }, [setInitState])

  const handleChangeCallSchedule = useCallback(
    (value, key) => {
      const state = editableState?.find(s => s.name === key)
      state.appearance = state.appearance || {}

      state.steps = value.checked
        ? [{ name: appConfig.workflows.steps.interviews.schedulerCustom }]
        : null
      state.appearance.appointmentColor = value.checked ? '#000' : null
      setEditableState(editableState)
      setListHandlersChanged([...new Set([...listHandlersChanged, key])])
    },
    [editableState, listHandlersChanged]
  )

  useEffect(() => {
    setInitState()
  }, [setInitState])

  const actualRoles = useCallback(
    handlerAction => {
      const roles = workflow?.name === appConfig.modules.amp ? usingBKRoles : usingRoles

      return handlerAction.name === appConfig.workflows.candidate.actions.reuse
        ? roles.filter(r => [appConfig.roles.recruiter, appConfig.roles.hiringManager].includes(r))
        : roles
    },
    [workflow?.name]
  )

  const items = useMemo(
    () =>
      editableState?.map(handlerAction => {
        let key = handlerAction?.name
        return {
          key,
          label: (
            <Row justify="space-between" align="middle">
              <Col span={20}>
                <Button
                  type={
                    handlerAction?.appearance?.fill &&
                    handlerAction?.appearance?.color !== 'transparent'
                      ? 'primary'
                      : 'default'
                  }
                  className={handlerAction?.appearance?.color}
                  style={{
                    border: `1px solid ${handlerAction?.appearance?.color || 'black'}`,
                    minWidth: handlerAction?.appearance?.width || 'auto',
                    color:
                      handlerAction?.appearance?.fill &&
                      handlerAction?.appearance?.color !== 'transparent'
                        ? 'white'
                        : (handlerAction?.appearance?.color !== 'transparent' &&
                            handlerAction?.appearance?.color) ||
                          'black',
                    background:
                      handlerAction?.appearance?.fill &&
                      handlerAction?.appearance?.color !== 'transparent'
                        ? handlerAction?.appearance?.color
                        : 'white'
                  }}
                >
                  {handlerAction?.displayName || '###'}
                </Button>
              </Col>
              {/*<Col>*/}
              {/*<Row justify="space-between">*/}
              {/*  <Col>*/}
              {/*    {stateActionsRoles?.[key]?.length ? (*/}
              {/*      stateActionsRoles?.[key]?.map(role => appConfig.rolesLabels[role]).join(', ')*/}
              {/*    ) : (*/}
              {/*      <Text type="secondary">Действие никому недоступно</Text>*/}
              {/*    )}*/}
              {/*  </Col>*/}
              {listHandlersChanged?.includes(key) && (
                <Col>
                  <Tooltip title="Есть несохраненные изменения">
                    <InfoCircleTwoTone
                      twoToneColor="#faad14"
                      className="handler-was-changed-icon"
                    />
                  </Tooltip>
                </Col>
              )}
              {/*</Row>*/}
              {/*</Col>*/}
            </Row>
          ),
          children: (
            <>
              <Row align="baseline">
                <Col span={7}>Название:</Col>
                <Col span={17}>
                  <Form.Item wrapperCol={colSettings.full}>
                    <Input
                      onChange={({ target }) =>
                        handleChangeStateProp(key, target?.value, 'displayName')
                      }
                      value={handlerAction?.displayName}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>Виден для:</Col>
                <Col span={17} offset={1}>
                  <RoleItem
                    roles={actualRoles(handlerAction)}
                    required={false}
                    label={null}
                    labelCol={colSettings.full}
                    size="large"
                    name={key}
                    onChange={onChangeRole}
                  />
                </Col>
                {!workflow?.handlers?.[key]?.displayName && <Text type="secondary">{key}</Text>}
              </Row>

              {/*<Row align="middle" className="mb-3">*/}
              {/*  <Col span={7}>Выберете цвет:</Col>*/}
              {/*  <Col span={5}>*/}
              {/*    <PresetColorPicker*/}
              {/*      handler={key}*/}
              {/*      onChange={handleChangeStateProp}*/}
              {/*      defaultValue={workflow?.handlers?.[key]?.appearance?.color}*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*  <Col span={6}>Заливка:</Col>*/}
              {/*  /!*<Col span={6}>*!/*/}
              {/*  <Form.Item*/}
              {/*    className="no-margin-bottom"*/}
              {/*    wrapperCol={colSettings.six}*/}
              {/*    valuePropName="checked"*/}
              {/*    rules={[*/}
              {/*      {*/}
              {/*        transform: value => value || undefined,*/}
              {/*        type: 'boolean'*/}
              {/*      }*/}
              {/*    ]}*/}
              {/*  >*/}
              {/*    <Switch*/}
              {/*      onChange={value => handleChangeStateProp(key, value, 'fill')}*/}
              {/*      defaultChecked={workflow?.handlers?.[key]?.appearance?.fill}*/}
              {/*    />*/}
              {/*  </Form.Item>*/}
              {/*  /!*</Col>*!/*/}
              {/*</Row>*/}

              <Row align="middle">
                <Col span={7}>Позиция:</Col>
                <Col span={5}>
                  <Button onClick={handleOpenModal}>Изменить</Button>
                </Col>
              </Row>

              <Row align="middle" className="mt-3">
                <Col span={12}>
                  <Checkbox
                    disabled={workflow?.handlers?.[key]?.steps}
                    checked={handlerAction.steps
                      ?.map(e => e.name)
                      ?.includes(appConfig.workflows.steps.interviews.schedulerCustom)}
                    onChange={({ target }) => handleChangeCallSchedule(target, key)}
                  >
                    Вызов календаря
                  </Checkbox>
                </Col>
                <Col
                  span={7}
                  className={
                    (workflow?.handlers?.[key]?.steps || !handlerAction.steps) && 'disabled'
                  }
                >
                  Выберете цвет:
                </Col>
                <Col span={5}>
                  <PresetColorPicker
                    attributeName="appointmentColor"
                    handler={key}
                    onChange={handleChangeStateProp}
                    defaultValue={handlerAction?.appearance?.appointmentColor}
                    disabled={workflow?.handlers?.[key]?.steps || !handlerAction.steps}
                  />
                </Col>
              </Row>
            </>
          )
        }
      }),
    [
      editableState,
      handleChangeStateProp,
      listHandlersChanged,
      workflow?.handlers,
      handleChangeCallSchedule,
      onChangeRole,
      actualRoles,
      handleOpenModal
    ]
  )
  const handleChangeList = useCallback(
    (list, handler) => {
      setListHandlersChanged([...new Set([...listHandlersChanged, handler])])
      setEditableState(list)
    },
    [listHandlersChanged]
  )

  const handleCloseDrawer = useCallback(() => {
    if (listHandlersChanged?.length) {
      confirm({
        title: 'Сохранение изменений',
        content:
          'У вас есть несохраненные изменения. Хотите сохранить изменения перед закрытием окна?',
        okText: 'Вернуться',
        cancelText: 'Отмена',
        onOk: () => {
          setIsOpenModal(false)
        },
        onCancel: () => {
          setIsOpenModal(false)
          setListHandlersChanged([])
          onClose?.()
        }
      })
    } else {
      onClose?.()
    }
  }, [listHandlersChanged, onClose])

  return (
    <Drawer
      width={commonDrawerWidth}
      placement="right"
      closable={false}
      onClose={handleCloseDrawer}
      open={!!stateName}
      rootClassName={drawerClass}
      destroyOnClose
    >
      <div className="drawer__wrap">
        <Row justify="space-between">
          <Col span={24}>
            <div className="no-gutters block-title">
              <EditingTitle
                displayName={selectedState?.displayName || selectedState?.name || 'Статус'}
                onEdit={value => form.setFieldsValue({ displayName: value })}
                isResetEditing={isLoadingUpdateWorkflow}
              />
            </div>
          </Col>
          <Divider className="small" />
        </Row>
        <Form form={form} scrollToFirstError onFinish={handleFinish} className="drawer-content">
          <Form.Item
            hidden
            label="Название"
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="displayName"
            className="mt-3"
            rules={[
              {
                required: true,
                message: 'Введите название'
              }
            ]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>

          <Form.Item
            wrapperCol={colSettings.full}
            name="showInDashboards"
            valuePropName="checked"
            rules={[
              {
                transform: value => value || undefined,
                type: 'boolean'
              }
            ]}
          >
            <Checkbox>Добавить в аналитику</Checkbox>
          </Form.Item>

          <Form.Item
            label="Отображать для ролей"
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="showFor"
            className="workflow-roles"
          >
            <Checkbox.Group>
              <Row>
                {filteredRoles.map(role => (
                  <Col span={24} key={role}>
                    <Checkbox value={role}>{appConfig.rolesLabels[role]}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Divider className="no-gutters" />

          <Form.Item
            label={<div className="sub-title">SLA</div>}
            labelCol={colSettings.full}
            wrapperCol={colSettings.full}
            name="slaDate"
            // className="mb-3"
          >
            <Select size="middle" placeholder="Выберите SLA">
              {appConfig.workflows.sla.map(c => (
                <Option key={c.key} value={c.key}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <SlaTime title="" onChange={handleChangeSlaTime} withoutButton data={slaDuration} />

          <Form.Item
            wrapperCol={colSettings.full}
            name="reuseWhenExpired"
            valuePropName="checked"
            rules={[
              {
                transform: value => value || undefined,
                type: 'boolean'
              }
            ]}
          >
            <Checkbox disabled={!slaDuration.days && !slaDuration.hours && !slaDuration.minutes}>
              разрешать переиспользование кандидата после истечения SLA
            </Checkbox>
          </Form.Item>

          <Divider className="no-gutters" />

          <div className="sub-title">
            <Row justify="space-between" align="middle">
              <Col>Действия</Col>
              <Col>
                {filteredPossibleActions?.length > 0 && (
                  <Button onClick={handleOpenModal}>Расположение кнопок-действий</Button>
                )}
              </Col>
            </Row>
          </div>

          {filteredPossibleActions?.length > 0 ? (
            <Collapse className="mb-3 mt-3" defaultActiveKey={null} items={items} />
          ) : (
            <Text type="secondary">Нет доступных действий</Text>
          )}

          <div className="drawer-footer ">
            <Divider className="small" />
            <Row className="buttons">
              <Col>
                <Button
                  type="link"
                  className="mr-3"
                  onClick={handleCloseDrawer}
                  disabled={isLoadingUpdateWorkflow}
                >
                  Отмена
                </Button>
                <Button type="primary" htmlType="submit" loading={isLoadingUpdateWorkflow}>
                  Сохранить
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <Modal
        width={1000}
        open={isOpenModal}
        title={null} //"Редактировать расположение кнопок-действий"
        okText="Ок"
        cancelText="Отменить"
        // okButtonProps={{ className: 'green' }}
        onCancel={handleCloseModal}
        onOk={() => setIsOpenModal(false)}
        wrapClassName="workflow-handlers-modal"
        destroyOnClose
      >
        <EditActionButtons
          list={editableState}
          onChangeList={handleChangeList}
          state={selectedState}
        />
      </Modal>
    </Drawer>
  )
}
export default WorkflowStatesDrawer
